import react, { useEffect, useState } from 'react';
import { useRouter } from 'next/router';
import { useDispatch, useSelector } from 'react-redux';

import { setUser } from '@/services/redux/userSlicer';
import { CallAPI } from '@/services/utils'
import Loader from '@/components/Loader';

const AuthWrapper = ({ children, forRole }) => {
    const [loading, setLoading] = useState(true);
    const dispatch = useDispatch();

    const { user } = useSelector(state => state.userDetails);
    const { message, role, permission = {} } = user || {}

    const router = useRouter();

    const isLoggedIn = () => {
        if (message === 'success' && user != null) {
            return true
        } else {
            return false
        }
    }

    const isAdmin = role === 'admin';
    const appPermission = permission['safeHavenScorecard_view'];

    const adminRoutes = ['/admin']

    const isAdminRoute = adminRoutes.some(route => router.asPath.includes(route));

    const verifyUser = async () => {
        try {
            const verify = await CallAPI(
                '/user/verify-user',
                'GET',
                {},
                // data
            )

            if (verify.message === 'unauthorized') {
                router.push(process.env.NEXT_PUBLIC_NODE_ENV === 'development' ? `${process.env.NEXT_PUBLIC_LOCAL_MAIN_DOMAIN}/?refer=http://localhost:3001` : `https://bernoullirisk.com/?refer=https://scorecard.bernoullirisk.com/`);
            } else {
                dispatch(setUser(verify));
            }
        } catch (error) {
            // console.log('Error while verifying user', error);
        }
    }

    useEffect(() => {
        if(user == null){
            setLoading(true);
            verifyUser().then(() => setLoading(false));
        }
    }, [])

    useEffect(() => {
        if (!loading) {
            if (!isLoggedIn()) {
                router.replace(process.env.NEXT_PUBLIC_NODE_ENV === 'development' ? `${process.env.NEXT_PUBLIC_LOCAL_MAIN_DOMAIN}/?refer=http://localhost:3001` : `https://bernoullirisk.com/?refer=https://scorecard.bernoullirisk.com/`);
                return
            }

            if (isLoggedIn() && !isAdmin && isAdminRoute) {
                router.replace('/unauthorized');
                return
            }

        }
    }, [user, loading]);

    useEffect(() => {
        if (isLoggedIn() && !appPermission) {
            router.replace('/unauthorized');
            return
        }
    }, [user, loading]);

    if (!isLoggedIn()) {
        return <></>
    }

    if (loading) {
        return (
            <>
                <Loader loading={loading} />
            </>
        )
    }

    return (<>{children}</>)
};

export default AuthWrapper;