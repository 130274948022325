import React from 'react'

const CustomSlider = ({ min, max, sliderValue, displayValue, step, onChange, onMouseUp, onClick, sliderCustomMapping = [], sliderCustomStepping = [], ...props }) => {
    return (
        <>
            <div className='flex w-72'>
                <input
                    className="w-full h-[6px] rounded-full bg-gray-100 accent-gray-400 appearance-none focus:outline-none"
                    type='range'
                    value={sliderValue}
                    min={min}
                    max={max}
                    step={step}
                    onClick={onClick}
                    onChange={onChange}
                    onMouseUp={onMouseUp}
                />

                {/* <div className='rounded-lg bg-gray-400 text-center ml-4 w-10 mt-1 font-medium dark:text-black'>{displayValue}</div> */}
            </div>
        </>
    )
}

export default CustomSlider