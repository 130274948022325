import React from 'react';

import { generateUniqueId } from '@/utils/FileInputUtils';

const CustomButton = ({ onClick, className, btnName, customClassProps, btnTextClass, ...props }) => {

    return (
        <>
            {/* <button
                onClick={onClick}
                className={customClassProps ? `${className}` : `bg-blue-500 text-white font-semibold py-2 px-4 rounded-md ${className}`}
                {...props}
            >
                {btnName}

            </button> */}

            <button
                id={generateUniqueId()}
                className={customClassProps ? `${className}` : `m-4 p-1 rounded-lg from-rose-200 via-fuchsia-100 to-indigo-100 bg-gradient-to-r ${className}`}
                {...props}
            >
                {customClassProps ? <span className={btnTextClass ? btnTextClass : 'text-black'}>{btnName}</span> : <span className="block text-black px-4 py-2 font-semibold rounded-lg bg-white hover:bg-transparent hover:text-white transition">{btnName}</span>
                }
            </button>
        </>
    )
}

const DefaultButton = ({ onClick, name, ...props }) => {
    return (
        <button type="button" className="text-gray-900 bg-gray-400 hover:bg-gray-100 border border-gray-300 focus:ring-4 focus:ring-gray-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center inline-flex items-center dark:focus:ring-gray-700 dark:bg-gray-400 dark:border-gray-900 dark:text-gray-900 dark:hover:bg-gray-200 mr-2 mb-2"
            onClick={onClick}
            {...props}
        >
            {name}
        </button>
    )
}

//How to use Slider 

// const [checked, setChecked] = useState(true);

//     const setCheckedState = (e) => {
//         setChecked(e.target.checked);
//     }

// <Toggle label={"test"} disabledChecked={checked} onChange={setCheckedState} checked={checked} />

const Toggle = ({ label, defaultChecked, onChange, className, labelClassName, ...props }) => {
    return (
        <>
            <label className="relative inline-flex items-center mr-5 cursor-pointer" style={{ position: "absolute" }}>
                <input
                    type="checkbox"
                    className="sr-only peer"
                    defaultChecked={defaultChecked}
                    id={generateUniqueId()}
                    onChange={onChange}
                    {...props}
                />
                <div className={`w-11 h-6 bg-gray-200 rounded-full peer dark:bg-gray-500 peer-focus:ring-4 peer-focus:ring-gray-200 dark:peer-focus:ring-gray-200 peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-0.5 after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-gray-500 ${className}`}></div>
                <span className={`ml-3 text-sm font-medium text-gray-900 dark:text-gray-300 ${labelClassName}`}>
                    {label}
                </span>
            </label>
        </>
    )
}

const Slider = ({ label, min, max, value, disabled, onChange, disabledBin, step, sliderType, ...props }) => {
    return (
        <>
            <div className='w-full'>
                {label ? <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">{label}</label> : <></>}
                <div className="flex items-center">
                    {/* <span className='rounded-lg dark:bg-gray-400 w-12 text-center font-semibold dark:text-black'>{min}</span> */}
                    <input
                        type="range"
                        min={min}
                        max={max}
                        step={step}
                        value={value}
                        onChange={onChange}
                        disabled={disabled}
                        className="w-full h-[6px] rounded-full bg-[#C1CDDE] accent-[#64748B] appearance-none focus:outline-none"
                        {...props}
                    />
                    {/* <span className='rounded-lg dark:bg-gray-400 w-12 text-center font-semibold dark:text-black'>{max}</span> */}
                </div>
                {disabledBin && <div className='mt-5 text-center mx-auto'><input className='rounded-lg bg-gray-400 w-12 text-center mx-auto font-semibold dark:text-black' value={value} onChange={onChange} /></div>}
                {sliderType === 'year' && <div className='mt-5 text-center mx-auto'><input className='rounded-lg bg-gray-400 w-13 text-center mx-auto font-semibold dark:text-black' value={value == 25 ? 'Monthly' : value == 50 ? 'Qtr' : value == 75 ? 'Semi' : 'Annual'} onChange={onChange} /></div>}
            </div>
        </>
    )
}

const ShAllocation = ({ label, min, max, value, disabled, onChange, ...props }) => {
    return (
        <>
            <div className='w-48'>
                {label ? <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">{label}</label> : <></>}
                <div className="flex items-center space-x-4">
                    {/* <span className='rounded-lg dark:bg-gray-400 w-12 text-center font-semibold dark:text-black'>{min}</span> */}
                    <input
                        type="range"
                        min={min}
                        max={max}
                        value={value}
                        onChange={onChange}
                        className="w-64 h-2 rounded-full bg-gray-200 accent-gray-400 appearance-none focus:outline-none"
                        {...props}
                    />
                    {/* <span className='rounded-lg dark:bg-gray-400 w-12 text-center font-semibold dark:text-black'>{max}</span> */}
                </div>
                <div className='mt-5 text-center mx-auto'><input className='rounded-lg bg-gray-400 w-12 text-center mx-auto font-semibold dark:text-black' value={value} onChange={onChange} /></div>
            </div>
        </>
    )
}

const ListDropdown = ({ label, id, options, value, onChange, disabled, ...props }) => {
    return (
        <div>
            <label htmlFor={id} className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
                {label}
            </label>
            <select
                htmlFor={generateUniqueId()}
                value={value}
                onChange={onChange}
                disabled={disabled}
                className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                {...props}
            >
                {options.map((option, index) => (
                    <option key={index} value={option.value}>
                        {option.label}
                    </option>
                ))}
            </select>
        </div>
    );
};

const CatListDropdown = ({ label, id, options, value, onChange, disabled, ...props }) => {
    let dic = []
    const handler = (e) => {
        // console.log("Value : ", e.target.value, " Name : ", dic[e.target.value], " >> ", dic);
        let selectedOptionText = e.target.options[e.target.selectedIndex].text;
        onChange(e.target.value, selectedOptionText);
    }
    return (
        <div className="flex items-center">
            <label htmlFor={id} className="text-sm font-semibold whitespace-nowrap">{label}</label>
            <select className="border border-gray-300 rounded-md px-2 py-1 text-gray-700 text-sm"
                htmlFor={generateUniqueId()}
                value={value}
                onChange={handler}
                disabled={disabled}
                {...props}
            >
                {options.map((group, index) => (
                    <optgroup key={index} label={group.label}>
                        {group.options.map((option, index) => {
                            dic[option.value] = option.text;
                            return (
                                <option key={index} value={option.value}>
                                    {option.text}
                                </option>
                            )
                        })}
                    </optgroup>
                ))}

            </select>
        </div>
    );
};

const OtherAsset = ({ label, id, options, value, onChange, disabled, ...props }) => {
    return (
        <div className="flex flex-col w-64">
            <label htmlFor={id} className="text-sm font-old">{label}</label>
            <select className="border border-gray-300 rounded-md p-2 mt-2 text-black"
                htmlFor={generateUniqueId()}
                value={value}
                onChange={onChange}
                disabled={disabled}
                {...props}
            >
                {options.map((group, index) => (
                    <optgroup key={index} label={group.label}>
                        {group.options.map((option, index) => (
                            <option key={index} value={option.value}>
                                {option.text}
                            </option>
                        ))}
                    </optgroup>
                ))}

            </select>
        </div>
    );
};

const CustomToggle = ({ labelLeft, labelRight, defaultChecked, onChange, className, labelClassName, ...props }) => {
    const toggleId = generateUniqueId()

    return (
        <>
            {/* <label for="toggle" className="text-sm text-gray-700 mr-5 cursor-pointer">{labelLeft}</label> */}
            <label htmlFor={toggleId} className="text-sm text-gray-700 mr-5 cursor-pointer">{labelLeft}</label>
            <div htmlFor={toggleId} className="relative inline-block w-12 mr-2 align-middle select-none transition duration-200 ease-in">
                <input
                    type="checkbox"
                    // name="toggle"
                    // id="toggle"
                    className="toggle-checkbox absolute block w-6 h-6 rounded-full bg-white appearance-none cursor-pointer peer-checked:bg-gray-500"
                    checked={true}
                    id={toggleId}
                    onChange={onChange}
                    // onClick={onChange}
                    {...props}
                />
                {/* <label for="toggle" className="toggle-label block overflow-hidden h-6 rounded-full bg-gray-300 cursor-pointer"></label> */}
                <label htmlFor={toggleId} className="toggle-label block overflow-hidden h-6 rounded-full bg-slate-400 cursor-pointer"></label>
            </div>
            <label for="toggle" className="text-sm text-gray-700">{labelRight}</label>
            {/* <label>{defaultChecked ? "Y" : "N"}</label> */}
        </>
    )


}

module.exports = {
    CustomButton,
    Toggle,
    Slider,
    ListDropdown,
    DefaultButton,
    CatListDropdown,
    OtherAsset,
    ShAllocation,
    CustomToggle
}