import { useEffect, useState } from 'react';
import axios from 'axios';
import MultiRangeSlider from '@/components/MultiRangeSlider';
import MUISlider from '@mui/material/Slider';
import { api_url } from '@/utils/apiConfig';
import BarCandles from '@/components/BarCandles';
import BarChart2 from '@/components/BarChart2';
import BarChart3 from '@/components/BarChart3';
import { generateValuesSPX, generatePlusValuesNonCartoonsWealth, getReturnFromWealthByYear, generatePlusValuesNonCartoonsReturns } from '@/utils/helper';
import { CatListDropdown, CustomToggle } from '@/components/FileInput';
import { OtherDropdown, percentileOptions0To100, percentileOptions5To95, generateMix, generateMixReturnsWealth, binMapping, calAmGm, generateCartoonsReturns, isEmpty } from '@/utils/helper';
import { generateUniqueId } from '@/utils/FileInputUtils';


import { Slider } from '@/components/FileInput';
import BarChart4 from '@/components/BarChart4';
import Loader from '@/components/Loader';
import Chart5 from '@/modules/Chart5';
import CEAplot from '@/modules/CEAplot';
import CustomSlider from '@/components/CustomSlider';
import SingleToggle from '@/components/SingleToggle';
import ToggleSwitch from '@/components/ToggleSwitch';

const MultiAsset = ({ multiAssetToggle, handleMultiAssetToggle }) => {

    // fetch annual data
    let insuranceTypes = ['INSUR[10-to-1]', 'INSUR[12-to-1]', 'INSUR[15-to-1]'];
    const [timeFrame, setTimeFrame] = useState('a');
    const [fromYear, setFromYear] = useState(1901);
    const [toYear, setToYear] = useState(2020);
    const [yearsList, setYearsList] = useState([[2000, 2001], [2002, 2008]]);
    const [firstAsset, setFirstAsset] = useState('spx');
    const [firstAssetName, setFirstAssetName] = useState('SPX');
    const [firstAssetValues, setFirstAssetValues] = useState([]);
    const [firstAssetReturns, setFirstAssetReturns] = useState([]);
    const [selectedValueFlip, setSelectedValueFlip] = useState(25);
    const [selectedValuePath, setSelectedValuePath] = useState(1000);
    const [normal, setNormal] = useState(true);
    const [nocrash, setNocrash] = useState(false);
    const [reshuffle, setReshuffle] = useState(false);
    const [minArray, setMinArray] = useState([]);
    const [maxArray, setMaxArray] = useState([]);
    const [averageArray, setAverageArray] = useState([]);
    const [minArrayNocrash, setMinArrayNocrashNocrash] = useState([]);
    const [maxArrayNocrash, setMaxArrayNocrash] = useState([]);
    const [averageArrayNocrash, setAverageArrayNocrash] = useState([]);

    //=========================== Remove this for after Prod Signoff ========================================================== 
    // const [minAssetArray, setMinAssetArray] = useState([]);
    // const [maxAssetArray, setMaxAssetArray] = useState([]);
    // const [averageAssetArray, setAverageAssetArray] = useState([]);
    //=========================== Remove this for after Prod Signoff ========================================================== 

    const [minAssetArray, setMinAssetArray] = useState({ 1: [] });
    const [maxAssetArray, setMaxAssetArray] = useState({ 1: [] });
    const [averageAssetArray, setAverageAssetArray] = useState({ 1: [] });

    // const [minAssetArrayNocrash, setMinAssetArrayNocrash] = useState([]);
    // const [maxAssetArrayNocrash, setMaxAssetArrayNocrash] = useState([]);
    // const [averageAssetArrayNocrash, setAverageAssetArrayNocrash] = useState([]);

    //=========================== Remove this for after Prod Signoff ========================================================== 
    const [minAssetArrayNocrash, setMinAssetArrayNocrash] = useState({});
    const [maxAssetArrayNocrash, setMaxAssetArrayNocrash] = useState({});
    const [averageAssetArrayNocrash, setAverageAssetArrayNocrash] = useState({});
    //=========================== Remove this for after Prod Signoff ========================================================== 


    //=========================== Remove this for after Prod Signoff ========================================================== 
    // const [minAssetReshuffleArray, setMinAssetReshuffleArray] = useState([]);
    // const [maxAssetReshuffleArray, setMaxAssetReshuffleArray] = useState([]);
    // const [averageAssetReshuffleArray, setAverageAssetReshuffleArray] = useState([]);
    //=========================== Remove this for after Prod Signoff ========================================================== 

    const [minAssetReshuffleArray, setMinAssetReshuffleArray] = useState({});
    const [maxAssetReshuffleArray, setMaxAssetReshuffleArray] = useState({});
    const [averageAssetReshuffleArray, setAverageAssetReshuffleArray] = useState({});

    const [am, setAm] = useState(0);
    const [amNocrash, setAmNocrash] = useState(0);

    //=========================== Remove this for after Prod Signoff ========================================================== 
    // const [amMean, setAmMean] = useState(0);
    //=========================== Remove this for after Prod Signoff ========================================================== 

    const [amMean, setAmMean] = useState({});

    const [amMeanNocrash, setAmMeanNocrash] = useState(0);
    const [amMeanReshuffle, setAmMeanReshuffle] = useState(0);
    const [gm, setGm] = useState(0);
    const [gmNocrash, setGmNocrash] = useState(0);
    const [yearMatrixData, setYearMatrixData] = useState([]);
    const [yearMatrixDataNocrash, setYearMatrixDataNocrash] = useState([]);

    // Other Asset
    const [categoryother, setCategoryother] = useState(OtherDropdown);
    // const [secondAsset, setSecondAsset] = useState('gold');
    const [secondAsset, setSecondAsset] = useState({ 1: 'gold' });
    const [secondAssetName, setSecondAssetName] = useState({ 1: 'Gold' });
    // const [secondAssetValues, setSecondAssetValues] = useState([]);
    const [secondAssetValues, setSecondAssetValues] = useState({});
    const [secondAssetValuesRet, setSecondAssetValuesRet] = useState({});
    const [secondAssetReturns, setSecondAssetReturns] = useState([]);

    //cpi 
    const [cpiValues, setCpiValues] = useState([]);
    const [cpiReturns, setCpiReturns] = useState([]);

    //custom slider 
    const [insuranceSliderValue, setInsuranceSliderValue] = useState({ 1: 11.2, 2: 11.2, 3: 11.2, 4: 11.2, 5: 11.2 })

    const handleSecondAsset = async (event, id) => {
        let selectedOptionText = event.target.options[event.target.selectedIndex].text;
        let value = event.target.value;

        setSecondAsset(prevValue => ({ ...prevValue, [id]: value }));
        setSecondAssetName(prevValue => ({ ...prevValue, [id]: selectedOptionText }));

        // computeCartoons(value);

        switch (value) {
            case 'Store-of-Value [CPI+2%]':
                setSecondAssetValues((prevValue) => ({ ...prevValue, [id]: [...generateCartoonsReturns(fromYear, toYear, 'Store-of-Value CPI', [2, 2, 2, 2], cpiValues, firstAssetValues, bins)] }));
                break;
            case 'Store-of-Value [7%]':
                setSecondAssetValues((prevValue) => ({ ...prevValue, [id]: [...generateCartoonsReturns(fromYear, toYear, 'Store-of-Value', [7, 7, 7, 7], cpiValues, firstAssetValues, bins)] }));
                break;
            case 'Alpha [20,7.5,5,5]':
                setSecondAssetValues((prevValue) => ({ ...prevValue, [id]: [...generateCartoonsReturns(fromYear, toYear, 'Alpha', [20, 7.5, 5, 5], cpiValues, firstAssetValues, bins)] }));
                break;
            case 'Alpha [15,10,2.5,2.5]':
                setSecondAssetValues((prevValue) => ({ ...prevValue, [id]: [...generateCartoonsReturns(fromYear, toYear, 'Alpha', [15, 10, 2.5, 2.5], cpiValues, firstAssetValues, bins)] }));
                break;
            case 'INSUR[10-to-1]':
                setSecondAssetValues((prevValue) => ({ ...prevValue, [id]: [...generateCartoonsReturns(fromYear, toYear, 'Insurance', [1000, -100, -100, -100], cpiValues, firstAssetValues, bins)] }));
                break;
            case 'INSUR[12-to-1]':
                setSecondAssetValues((prevValue) => ({ ...prevValue, [id]: [...generateCartoonsReturns(fromYear, toYear, 'Insurance', [1200, -100, -100, -100], cpiValues, firstAssetValues, bins)] }));
                break;
            case 'INSUR[15-to-1]':
                setSecondAssetValues((prevValue) => ({ ...prevValue, [id]: [...generateCartoonsReturns(fromYear, toYear, 'Insurance', [1500, -100, -100, -100], cpiValues, firstAssetValues, bins)] }));
                break;

            default:
                // let buffer = await callAPI({
                //     fromYear: 1901, toYear: 2023,
                //     type: value, frequency: timeFrame
                // }, setSecondAssetValues, setSecondAssetReturns, id )
                const options = {
                    fromYear: 1901, toYear: 2023,
                    type: value, frequency: timeFrame
                }
                const assetRetrunValues = await callApiBasic(options)
                // setSecondAssetValuesRet((prevValue) => ({ ...prevValue, [id]: assetRetrunValues }))
                setSecondAssetValues((prevValue) => ({ ...prevValue, [id]: assetRetrunValues }))
                break;
        }

        // let buffer = await callAPI({
        //   fromYear: 1901, toYear: 2023,
        //   type: value, frequency: timeFrame
        // }, setSecondAssetValues, setSecondAssetReturns)
    }

    useEffect(() => {
        // console.log("🚀 ~ useEffect ~ secondAssetValues:", secondAssetValues)
        // console.log("🚀 ~ useEffect ~ secondAssetValuesRet:", secondAssetValuesRet)
    }, [secondAssetValuesRet, secondAssetValues])

    const [dataBar, setDataBar] = useState({
        labels: ['data1', 'data2'],
        datasets: [{
            label: ['data1', 'data2'],
            data: [0, 0],
            backgroundColor: 'rgba(255, 99, 132, 0.5)'
        }]
    });

    const [regimeCount, setRegimeCount] = useState(4);
    const [regimeSelectors, setRegimeSelectors] = useState([1, 11, 33, 62, 98, 120]);
    const [regimeBar, setRegimeBar] = useState({
        labels: ['Loading', 'Loading', 'Loading'],
        datasets: []
    });

    const [regimeBarForNocrash, setRegimeBarForNocrash] = useState({
        labels: ['Loading', 'Loading', 'Loading'],
        datasets: []
    });


    //Allocation Mix 
    const initialAlloc = {
        'firstAssetAlloc': 72,
        'secondAssetAlloc': 28
    }

    //=========================== Remove this for after Prod Signoff ========================================================== 
    // const [alloc, setAlloc] = useState({ ...initialAlloc });
    //=========================== Remove this for after Prod Signoff ========================================================== 

    const [alloc, setAlloc] = useState({ 1: { ...initialAlloc }, 2: { ...initialAlloc }, 3: { ...initialAlloc }, 4: { ...initialAlloc }, 5: { ...initialAlloc } });


    const [mix, setMix] = useState([]);

    //=========================== Remove this for after Prod Signoff ========================================================== 
    // const [mixMinAssetReshuffleArray, setMixMinAssetReshuffleArray] = useState([]);
    // const [mixMaxAssetReshuffleArray, setMixMaxAssetReshuffleArray] = useState([]);
    // const [mixAvgAssetReshuffleArray, setMixAvgAssetReshuffleArray] = useState([]);
    //=========================== Remove this for after Prod Signoff ========================================================== 


    const [mixMinAssetReshuffleArray, setMixMinAssetReshuffleArray] = useState({});
    const [mixMaxAssetReshuffleArray, setMixMaxAssetReshuffleArray] = useState({});
    const [mixAvgAssetReshuffleArray, setMixAvgAssetReshuffleArray] = useState({});

    //=========================== Remove this for after Prod Signoff ========================================================== 
    // const [mixMinAssetArray, setMixMinAssetArray] = useState([]);
    // const [mixMaxAssetArray, setMixMaxAssetArray] = useState([]);
    // const [mixAvgAssetArray, setMixAvgAssetArray] = useState([]);
    // const [mixAM, setMixAM] = useState([]);
    // const [mixGM, setMixGM] = useState([]);
    //=========================== Remove this for after Prod Signoff ========================================================== 

    const [mixMinAssetArray, setMixMinAssetArray] = useState({});
    const [mixMaxAssetArray, setMixMaxAssetArray] = useState({});
    const [mixAvgAssetArray, setMixAvgAssetArray] = useState({});
    const [mixAM, setMixAM] = useState({});
    const [mixGM, setMixGM] = useState({});

    //=========================== Remove this for after Prod Signoff ========================================================== 
    // const [mixAMReshuffle, setMixAMReshuffle] = useState([]);
    // const [mixGMReshuffle, setMixGMReshuffle] = useState([]);
    //=========================== Remove this for after Prod Signoff ========================================================== 

    const [mixAMReshuffle, setMixAMReshuffle] = useState({});
    const [mixGMReshuffle, setMixGMReshuffle] = useState({});

    //for normal or default 
    const [baseAssetMatrixRetruns, setBaseAssetMatrixReturns] = useState([]);

    //=========================== Remove this for after Prod Signoff ========================================================== 
    // const [otherAssetMatrixRetruns, setOtherAssetMatrixReturns] = useState([]);
    //=========================== Remove this for after Prod Signoff ========================================================== 

    const [otherAssetMatrixRetruns, setOtherAssetMatrixReturns] = useState({});

    //for reshuffle
    //=========================== Remove this for after Prod Signoff ========================================================== 
    // const [otherAssetMatrixReshuffleReturns, setOtherAssetMatrixReshuffleReturns] = useState([]);
    //=========================== Remove this for after Prod Signoff ========================================================== 

    const [otherAssetMatrixReshuffleReturns, setOtherAssetMatrixReshuffleReturns] = useState({});


    //for nocrash 
    const [baseAssetMatrixReturnsNoCrash, setBaseAssetMatrixReturnsNoCrash] = useState([]);
    // const [otherAssetMatrixReturnsNoCrash, setOtherAssetMatrixReturnsNoCrash] = useState([]);
    const [otherAssetMatrixReturnsNoCrash, setOtherAssetMatrixReturnsNoCrash] = useState({});

    //=========================== Remove this for after Prod Signoff ========================================================== 
    // const [minMixNoCrash, setMinMixNoCrash] = useState([]);
    // const [maxMixNoCrash, setMaxMixNoCrash] = useState([]);
    // const [avgMixNoCrash, setAvgMixNoCrash] = useState([]);
    // const [amMixNoCrash, setAmMixNoCrash] = useState([]);
    // const [gmMixNoCrash, setGmMixNoCrash] = useState([]);
    //=========================== Remove this for after Prod Signoff ========================================================== 

    const [minMixNoCrash, setMinMixNoCrash] = useState({});
    const [maxMixNoCrash, setMaxMixNoCrash] = useState({});
    const [avgMixNoCrash, setAvgMixNoCrash] = useState({});
    const [amMixNoCrash, setAmMixNoCrash] = useState({});
    const [gmMixNoCrash, setGmMixNoCrash] = useState({});

    // Allocation mix 

    //loader 
    const [loading, setLoading] = useState(true);

    //Chart 5 

    //=========================== Remove this for after Prod Signoff ========================================================== 
    // const [percentile1Dropdown, setPercentile1Dropdown] = useState(50);
    // const [percentile2Dropdown, setPercentile2Dropdown] = useState(5);
    //=========================== Remove this for after Prod Signoff ========================================================== 
    const [percentile1Dropdown, setPercentile1Dropdown] = useState({ 1: 50, 2: 50, 3: 50, 4: 50, 5: 50 });
    const [percentile2Dropdown, setPercentile2Dropdown] = useState({ 1: 5, 2: 5, 3: 5, 4: 5, 5: 5 });
    const [lock, setLock] = useState(true);
    const [pin, setPin] = useState(true);

    //for cartoons generation 
    const [bins, setBins] = useState([]);


    //Multi Asset 
    const initialAssets = [1];
    const [assets, setAssets] = useState(initialAssets);

    const isInsurance = (assetName) => { return insuranceTypes.some(type => assetName.includes(type)); }

    useEffect(() => {
        callAPI({
            fromYear: 1901,
            toYear: 2023,
            type: firstAsset,
            frequency: timeFrame
        }, setFirstAssetValues, setFirstAssetReturns);

        // callAPI({
        //     fromYear: 1901, toYear: 2023,
        //     type: secondAsset[1], frequency: timeFrame
        // }, setSecondAssetValues, setSecondAssetReturns);

        const options = {
            fromYear: 1901, toYear: 2023,
            type: secondAsset[1], frequency: timeFrame
        }
        intitialSecondAssetValues(options, 1)

        callAPI({
            fromYear: 1901, toYear: 2023,
            type: 'cpi', frequency: 'a'
        }, setCpiValues, setCpiReturns);
    }, []);

    useEffect(() => {
    }, [cpiValues, cpiReturns])


    useEffect(() => {
        let buffer = filterData(firstAssetName, firstAssetValues, fromYear, toYear, true, setFirstAssetReturns, true);
        calculateRegimeSelectors(buffer);
        if (fromYear == 1901 && toYear == 2020 && regimeCount == 4) {
            setRegimeSelectors([1, 11, 33, 62, 98, 120]);
        }
        setDataBar(buffer);
    }, [fromYear, toYear, firstAssetValues, regimeCount]);

    useEffect(() => {
        updateRegime(null, null, true);
    }, [dataBar])

    useEffect(() => {
        if ((normal == true || reshuffle == true || nocrash == true) && loading == false) {
            let newYearsList = yearsList;
            generateDiceMatrixForOneTwentyFaceDice(newYearsList);
        }
    }, [fromYear, toYear, regimeCount, regimeSelectors, yearsList])

    useEffect(() => {
        if ((normal == true || reshuffle == true || nocrash == true) && loading == false) {
            let newYearsList = yearsList;
            generateDiceMatrixForOneTwentyFaceDiceForNoCrash(newYearsList);
        }
    }, [fromYear, toYear, regimeCount, regimeSelectors, yearsList])

    useEffect(() => {
        assets.map((item, idx) => {
            if (normal == true || reshuffle == true || nocrash == true && loading != true) {
                calSecondAsset(yearMatrixData, yearsList, item);
            }
        })
    }, [fromYear, toYear, regimeCount, regimeSelectors, yearsList, yearMatrixData, secondAssetValues, loading, assets])

    useEffect(() => {
        assets.map((item, idx) => {
            if (normal == true || reshuffle == true || nocrash == true && loading != true) {
                calSecondAssetForReshuffle(yearMatrixData, yearsList, item);
            }
        })

    }, [fromYear, toYear, regimeCount, regimeSelectors, yearsList, yearMatrixData, secondAssetValues])

    useEffect(() => {
        assets.map((item, idx) => {
            if (normal == true || reshuffle == true || nocrash == true) {
                calSecondAssetForNocrash(yearMatrixDataNocrash, yearsList, item);
            }
        })
    }, [fromYear, toYear, regimeCount, regimeSelectors, yearsList, yearMatrixDataNocrash, secondAssetValues, assets])

    const callAPI = async (options, responseSetter, returnsSetter, id) => {
        try {
            setLoading(prevValue => true);
            const data = await axios.post(`${api_url}/fetch-data`, options);
            let raw_data = data && data.data.data || [];
            responseSetter((prev) => { return raw_data });
            let returns = generateValuesSPX(fromYear, toYear, raw_data);
            returnsSetter((prev) => { return returns });
            setLoading(prevValue => false);
            return true;
        } catch (error) {
            setLoading(prevValue => false);
        }
    };

    // const computeCartoons = async (value) => {
    //     switch (value) {
    //         case 'Store-of-Value [CPI+2%]':
    //             setSecondAssetValues(prevValue => [...generateCartoonsReturns(fromYear, toYear, 'Store-of-Value CPI', [2, 2, 2, 2], cpiValues, firstAssetValues, bins)]);
    //             break;
    //         case 'Store-of-Value [7%]':
    //             setSecondAssetValues(prevValue => [...generateCartoonsReturns(fromYear, toYear, 'Store-of-Value', [7, 7, 7, 7], cpiValues, firstAssetValues, bins)]);
    //             break;
    //         case 'Alpha [20,7.5,5,5]':
    //             setSecondAssetValues(prevValue => [...generateCartoonsReturns(fromYear, toYear, 'Alpha', [20, 7.5, 5, 5], cpiValues, firstAssetValues, bins)]);
    //             break;
    //         case 'Alpha [15,10,2.5,2.5]':
    //             setSecondAssetValues(prevValue => [...generateCartoonsReturns(fromYear, toYear, 'Alpha', [15, 10, 2.5, 2.5], cpiValues, firstAssetValues, bins)]);
    //             break;
    //         case 'INSUR[10-to-1]':
    //             setSecondAssetValues(prevValue => [...generateCartoonsReturns(fromYear, toYear, 'Insurance', [1000, -100, -100, -100], cpiValues, firstAssetValues, bins)]);
    //             break;
    //         case 'INSUR[12-to-1]':
    //             setSecondAssetValues(prevValue => [...generateCartoonsReturns(fromYear, toYear, 'Insurance', [1200, -100, -100, -100], cpiValues, firstAssetValues, bins)]);
    //             break;
    //         case 'INSUR[15-to-1]':
    //             setSecondAssetValues(prevValue => [...generateCartoonsReturns(fromYear, toYear, 'Insurance', [1500, -100, -100, -100], cpiValues, firstAssetValues, bins)]);
    //             break;

    //         default:
    //             break;
    //     }
    // }

    // useEffect(() => {
    //     computeCartoons(secondAssetName)
    // }, [fromYear, toYear, regimeCount, regimeSelectors, bins, secondAssetName])

    const calculateRegimeSelectors = (response) => {
        let dataLength = response.datasets[0].data.length;
        let stepSize = Math.round(dataLength / (regimeCount + 1));
        let buffer = [];
        buffer.push(1);
        for (let i = 1; i <= regimeCount; i++) {
            buffer.push((stepSize * i));
        }
        buffer.push(dataLength);
        setRegimeSelectors(buffer);
    }

    const filterData = (label, raw_data, from, to, calculateReturns = false, returnsSetterFxn = null, sort = true, multiplier = 100) => {
        let response = {
            labels: [],
            datasets: [{
                label: label,
                data: [],
                backgroundColor: 'rgba(255, 99, 132, 0.5)'
            }]
        };

        raw_data.sort((a, b) => a[1] - b[1]);

        // TODO: setup the if conditions to minimize iteration of the loop
        raw_data.forEach(element => {
            if (element[0] >= from && element[0] <= to) {
                response.labels.push(element[0]);
                response.datasets[0].data.push(parseFloat(element[1] * multiplier));
            }
        });

        // SORT ASCENDING ORDER.
        if (sort) {
            response.datasets[0].data.sort((a, b) => a - b);
        }

        if (calculateReturns) {
            let returns = generateValuesSPX(from, to, raw_data);
            returnsSetterFxn(returns);
        }

        return response;
    };

    const handleYearChange = ({ min, max }) => {
        setFromYear(parseInt(min));
        setToYear(parseInt(max));
    };

    const updateRegime = (value, index, justPlot = false, multiplier = 100) => {
        let cartoonBins = []
        let rs = regimeSelectors
        if (!justPlot) {
            let pos = index.split('_')[2];
            let buffer = regimeSelectors;
            buffer[pos] = value;
            setRegimeSelectors(buffer);
            rs = buffer
        }
        let data = dataBar?.datasets[0]?.data; // values
        let dataLabels = dataBar?.labels; // years
        let yearRange = [];
        // regime chart
        let newResponse = {
            labels: [],
            datasets: [{
                label: "Num of years",
                data: [],
                backgroundColor: 'rgba(53, 162, 235, 0.5)'
            }]
        };
        for (let i = 1; i < rs.length; i++) {
            const start = rs[i - 1] - 1;
            const end = rs[i] - 1;
            let holderArray = dataLabels.slice(start, end);
            if (i == rs.length - 1) {
                holderArray = dataLabels.slice(start, end + 1);
            }
            yearRange.push(holderArray);
            cartoonBins.push([parseFloat(data[start]), parseFloat(data[end])]);
            newResponse.labels.push(parseFloat(data[start]?.toFixed(2)) + " to " + parseFloat(data[end]?.toFixed(2)));
            newResponse.datasets[0].data.push({
                y: holderArray.length
            });
        }
        setYearsList(prevValue => yearRange);
        setRegimeBar(newResponse);
        let newResNoCrash = JSON.parse(JSON.stringify(newResponse));
        newResNoCrash.datasets[0].data[0]['y'] = 0;
        setRegimeBarForNocrash(newResNoCrash);
        setBins(prevValue => cartoonBins);
    };

    const generateDiceMatrixForOneTwentyFaceDice = (yearsList) => {
        let initialSPXReturn = [...firstAssetValues].sort((a, b) => a[0] - b[0]);

        let minFace;
        let maxFace;
        for (let i = 0; i < initialSPXReturn.length; i++) {
            if (initialSPXReturn[i][0] === fromYear) {
                minFace = i; // Return the index when the year is found
            }
            if (initialSPXReturn[i][0] === toYear) {
                maxFace = i; // Return the index when the year is found
            }
        }
        let profitLossVal = new Map();
        for (let j = minFace; j <= maxFace; j++) {
            profitLossVal.set(j + 1, initialSPXReturn[j][1]);
        }
        minFace = 1 + minFace;
        maxFace = 1 + maxFace;

        const matrix = [];

        for (let i = 0; i < selectedValuePath; i++) {
            const row = [];
            for (let j = 0; j < selectedValueFlip; j++) {
                const randomFace = Math.floor(Math.random() * (maxFace - minFace + 1)) + minFace; // Random face number <= type
                row.push(randomFace);
            }
            matrix.push(row);
        }
        let yearMatrix = calYearMatrix(initialSPXReturn, matrix);
        const matrixData = calculateWealthMatrixForOneTwenty(matrix, profitLossVal);
        const wealthMatrixData = matrixData[0];
        const profitLossData = matrixData[1];
        setBaseAssetMatrixReturns(prevValue => profitLossData);
        let percentileValuesForLastCol = calculatePercentilePaths(wealthMatrixData, selectedValueFlip, selectedValuePath);
        updateTable(percentileValuesForLastCol, selectedValueFlip, wealthMatrixData, profitLossData);
        // Initialize the result array with empty arrays for each regime
        const resultArray = new Array(yearsList.length).fill([]).map(() => []);
        // Iterate through yearsMatrix and categorize years into the resultArray
        for (let i = 0; i < yearMatrix.length; i++) {
            for (let j = 0; j < yearMatrix[i].length; j++) {
                yearsList.forEach((regime, index) => {
                    if (regime.includes(yearMatrix[i][j])) {
                        resultArray[index].push(yearMatrix[i][j]);
                    }
                });
            }
        }
        let avgRegime = [];
        let maxValRegime = [];
        let minValRegime = [];
        let resultArraySpxReturns = calReturn(resultArray, initialSPXReturn);
        for (let i = 0; i < resultArraySpxReturns.length; i++) {
            let getAvg = avgCalculate(resultArraySpxReturns[i]);
            avgRegime.push(getAvg);
            let getMax = maxCalc(resultArraySpxReturns[i]);
            maxValRegime.push(getMax);
            let getMin = minCalc(resultArraySpxReturns[i]);
            minValRegime.push(getMin);
        }

        setMinArray(minValRegime);
        setMaxArray(maxValRegime);
        setAverageArray(avgRegime);
        //calSecondAsset(yearMatrix, yearsList);
        setYearMatrixData(prevValue => yearMatrix);
        return [yearMatrix, yearsList];
    };


    const generateDiceMatrixForOneTwentyFaceDiceForNoCrash = (yearsList) => {
        //if (yearsList[0].length != 0) {
        let initialSPXReturn = [...firstAssetValues].sort((a, b) => a[0] - b[0]);

        let minFace;
        let maxFace;
        let facesExceptFirstRegime = [];
        for (let i = 0; i < initialSPXReturn.length; i++) {
            if (initialSPXReturn[i][0] === fromYear) {
                minFace = i; // Return the index when the year is found
            }
            if (initialSPXReturn[i][0] === toYear) {
                maxFace = i; // Return the index when the year is found
            }
        }
        let profitLossVal = new Map();
        for (let j = minFace; j <= maxFace; j++) {
            profitLossVal.set(j + 1, initialSPXReturn[j][1]);
            if (!(yearsList[0].includes(initialSPXReturn[j][0]))) {
                facesExceptFirstRegime.push(j + 1);
            }
        }
        const matrix = Array.from({ length: selectedValuePath }, () =>
            Array.from({ length: selectedValueFlip }, () =>
                facesExceptFirstRegime[Math.floor(Math.random() * facesExceptFirstRegime.length)]
            )
        );
        let yearMatrix = calYearMatrix(initialSPXReturn, matrix);
        const matrixData = calculateWealthMatrixForOneTwenty(matrix, profitLossVal);
        const wealthMatrixData = matrixData[0];
        const profitLossData = matrixData[1];
        setBaseAssetMatrixReturnsNoCrash(prevValue => profitLossData);
        let percentileValuesForLastCol = calculatePercentilePaths(wealthMatrixData, selectedValueFlip, selectedValuePath);
        updateTableForNocrash(percentileValuesForLastCol, selectedValueFlip, wealthMatrixData, profitLossData);
        // Initialize the result array with empty arrays for each regime
        const resultArray = new Array(yearsList.length).fill([]).map(() => []);
        // Iterate through yearsMatrix and categorize years into the resultArray
        for (let i = 0; i < yearMatrix.length; i++) {
            for (let j = 0; j < yearMatrix[i].length; j++) {
                yearsList.forEach((regime, index) => {
                    if (regime.includes(yearMatrix[i][j])) {
                        resultArray[index].push(yearMatrix[i][j]);
                    }
                });
            }
        }
        let avgRegime = [0];
        let maxValRegime = [0];
        let minValRegime = [0];
        let resultArraySpxReturns = calReturn(resultArray, initialSPXReturn);
        for (let i = 1; i < resultArraySpxReturns.length; i++) {
            let getAvg = avgCalculate(resultArraySpxReturns[i]);
            avgRegime.push(getAvg);
            let getMax = maxCalc(resultArraySpxReturns[i]);
            maxValRegime.push(getMax);
            let getMin = minCalc(resultArraySpxReturns[i]);
            minValRegime.push(getMin);
        }
        setMinArrayNocrashNocrash(minValRegime);
        setMaxArrayNocrash(maxValRegime);
        setAverageArrayNocrash(avgRegime);
        //calSecondAsset(yearMatrix, yearsList);
        setYearMatrixDataNocrash(prevValue => yearMatrix);
        return [yearMatrix, yearsList];
        //}
    };

    const calSecondAsset = (yearMatrix, yearsList, id) => {
        if (yearMatrix.length == 0 && yearMatrixData.length != 0) {
            yearMatrix = yearMatrixData;
        }
        let binMatrix = createBinMatrix(yearMatrix, yearsList);
        let assetBin = filterRegimeYears(yearsList, secondAssetValues[id]);
        let newAssetYearMatrix = fillNewMatrix(binMatrix, assetBin);
        const resultAssetArray = new Array(assetBin.length).fill([]).map(() => []);
        // Iterate through yearsMatrix and categorize years into the resultArray
        for (let i = 0; i < newAssetYearMatrix.length; i++) {
            for (let j = 0; j < newAssetYearMatrix[i].length; j++) {
                assetBin.forEach((regime, index) => {
                    if (regime.includes(newAssetYearMatrix[i][j])) {
                        resultAssetArray[index].push(newAssetYearMatrix[i][j]);
                    }
                });
            }
        }

        let avgAssetRegime = [];
        let maxAssetValRegime = [];
        let minAssetValRegime = [];
        let resultArrayReturns = calReturn(resultAssetArray, secondAssetValues[id]);

        for (let i = 0; i < resultArrayReturns.length; i++) {
            let getAvg = avgCalculate(resultArrayReturns[i]);
            avgAssetRegime.push(getAvg);
            let getMax = maxCalc(resultArrayReturns[i]);
            maxAssetValRegime.push(getMax);
            let getMin = minCalc(resultArrayReturns[i]);
            minAssetValRegime.push(getMin);
        }

        //=========================== Remove this for after Prod Signoff ========================================================== 
        // setMinAssetArray(minAssetValRegime);
        // setMaxAssetArray(maxAssetValRegime);
        // setAverageAssetArray(avgAssetRegime);
        //=========================== Remove this for after Prod Signoff ========================================================== 


        setMinAssetArray((prevValue) => ({ ...prevValue, [id]: minAssetValRegime }));
        setMaxAssetArray((prevValue) => ({ ...prevValue, [id]: maxAssetValRegime }));
        setAverageAssetArray((prevValue) => ({ ...prevValue, [id]: avgAssetRegime }));

        // let amAsset = avgCalc(avgAssetRegime);
        // setAmMean(amAsset);

        //for mix calculation 
        let pnlValues = generatePNLValues(secondAssetValues[id]);
        const matrixData = calculateWealthMatrixForOneTwenty(newAssetYearMatrix, pnlValues, 'secondAsset');
        let wealthMatrixData = matrixData[0];
        let profitLossData = matrixData[1];
        setOtherAssetMatrixReturns((prevValue) => ({ ...prevValue, [id]: profitLossData }))
        let percentileValuesForLastCol = calculatePercentilePaths(wealthMatrixData, selectedValueFlip, selectedValuePath);
        if (percentileValuesForLastCol != undefined && percentileValuesForLastCol.length > 1 && wealthMatrixData.length > 1 && profitLossData.length > 1) {
            const AmGm = calAmGm(percentileValuesForLastCol, selectedValueFlip, wealthMatrixData, profitLossData);
            setAmMean((prevValue) => ({ ...prevValue, [id]: AmGm.am }));
        }
    }

    const calSecondAssetForNocrash = (yearMatrix, yearsList, item) => {
        if (yearMatrix.length == 0 && yearMatrixData.length != 0) {
            yearMatrix = yearMatrixData;
        }
        let binMatrix = createBinMatrix(yearMatrix, yearsList);
        let assetBin = filterRegimeYears(yearsList, secondAssetValues[item]);
        let newAssetYearMatrix = fillNewMatrix(binMatrix, assetBin);
        const resultAssetArray = new Array(assetBin.length).fill([]).map(() => []);
        // Iterate through yearsMatrix and categorize years into the resultArray
        for (let i = 0; i < newAssetYearMatrix.length; i++) {
            for (let j = 0; j < newAssetYearMatrix[i].length; j++) {
                assetBin.forEach((regime, index) => {
                    if (regime.includes(newAssetYearMatrix[i][j])) {
                        resultAssetArray[index].push(newAssetYearMatrix[i][j]);
                    }
                });
            }
        }
        let avgAssetRegime = [0];
        let maxAssetValRegime = [0];
        let minAssetValRegime = [0];
        let resultArrayReturns = calReturn(resultAssetArray, secondAssetValues[item]);

        for (let i = 1; i < resultArrayReturns.length; i++) {
            let getAvg = avgCalculate(resultArrayReturns[i]);
            avgAssetRegime.push(getAvg);
            let getMax = maxCalc(resultArrayReturns[i]);
            maxAssetValRegime.push(getMax);
            let getMin = minCalc(resultArrayReturns[i]);
            minAssetValRegime.push(getMin);
        }

        // setMinAssetArrayNocrash(minAssetValRegime);
        // setMaxAssetArrayNocrash(maxAssetValRegime);
        // setAverageAssetArrayNocrash(avgAssetRegime);

        setMinAssetArrayNocrash(prevValue => ({ ...prevValue, [item]: minAssetValRegime }));
        setMaxAssetArrayNocrash(prevValue => ({ ...prevValue, [item]: maxAssetValRegime }));
        setAverageAssetArrayNocrash(prevValue => ({ ...prevValue, [item]: avgAssetRegime }));

        // let amAsset = avgCalc(avgAssetRegime);
        // setAmMeanNocrash(amAsset);

        //for mix generation
        let pnlValues = generatePNLValues(secondAssetValues[item]);
        const matrixData = calculateWealthMatrixForOneTwenty(newAssetYearMatrix, pnlValues, 'secondAsset');
        let wealthMatrixData = matrixData[0];
        let profitLossData = matrixData[1];
        setOtherAssetMatrixReturnsNoCrash(prevValue => ({ ...prevValue, [item]: profitLossData }));
        let percentileValuesForLastCol = calculatePercentilePaths(wealthMatrixData, selectedValueFlip, selectedValuePath);
        if (percentileValuesForLastCol != undefined && percentileValuesForLastCol.length > 1 && wealthMatrixData.length > 1 && profitLossData.length > 1) {
            const AmGm = calAmGm(percentileValuesForLastCol, selectedValueFlip, wealthMatrixData, profitLossData);
            setAmMeanNocrash(prevValue => ({ ...prevValue, [item]: AmGm.am }));
        }
    }

    const calSecondAssetForReshuffle = (yearMatrix, yearsList, id) => {
        if (yearMatrix.length == 0 && yearMatrixData.length != 0) {
            yearMatrix = yearMatrixData;
        }
        let binMatrix = createBinMatrix(yearMatrix, yearsList);
        let assetBin = filterRegimeYears(yearsList, secondAssetValues[id]);
        let newAssetYearMatrix = fillNewMatrix(binMatrix, assetBin);
        const shuffledMatrix = shuffleMatrixRows(newAssetYearMatrix);
        // const resultAssetArray = new Array(assetBin.length).fill([]).map(() => []);
        // // Iterate through yearsMatrix and categorize years into the resultArray
        // for (let i = 0; i < newAssetYearMatrix.length; i++) {
        //   for (let j = 0; j < newAssetYearMatrix[i].length; j++) {
        //     assetBin.forEach((regime, index) => {
        //       if (regime.includes(newAssetYearMatrix[i][j])) {
        //         resultAssetArray[index].push(newAssetYearMatrix[i][j]);
        //       }
        //     });
        //   }
        // }
        const resultAssetArray = calReshuffledBin(shuffledMatrix, binMatrix, assetBin);
        let avgAssetRegime = [];
        let maxAssetValRegime = [];
        let minAssetValRegime = [];
        let resultArrayReturns = calReturn(resultAssetArray, secondAssetValues[id]);

        for (let i = 0; i < resultArrayReturns.length; i++) {
            let getAvg = avgCalculate(resultArrayReturns[i]);
            avgAssetRegime.push(getAvg);
            let getMax = maxCalc(resultArrayReturns[i]);
            maxAssetValRegime.push(getMax);
            let getMin = minCalc(resultArrayReturns[i]);
            minAssetValRegime.push(getMin);
        }
        setMinAssetReshuffleArray((prevValue) => ({ ...prevValue, [id]: minAssetValRegime }));
        setMaxAssetReshuffleArray((prevValue) => ({ ...prevValue, [id]: maxAssetValRegime }));
        setAverageAssetReshuffleArray((prevValue) => ({ ...prevValue, [id]: avgAssetRegime }));
        // let amAsset = avgCalc(avgAssetRegime);
        // setAmMeanReshuffle(amAsset);

        //for mix calculation 
        let pnlValues = generatePNLValues(secondAssetValues[id]);
        const matrixData = calculateWealthMatrixForOneTwenty(shuffledMatrix, pnlValues, 'secondAssetReshuffle');
        let wealthMatrixData = matrixData[0];
        let profitLossData = matrixData[1];
        setOtherAssetMatrixReshuffleReturns((prevValue) => ({ ...prevValue, [id]: profitLossData }))
        let percentileValuesForLastCol = calculatePercentilePaths(wealthMatrixData, selectedValueFlip, selectedValuePath);
        if (percentileValuesForLastCol != undefined && percentileValuesForLastCol.length > 1 && wealthMatrixData.length > 1 && profitLossData.length > 1) {
            const AmGm = calAmGm(percentileValuesForLastCol, selectedValueFlip, wealthMatrixData, profitLossData);
            setAmMeanReshuffle(AmGm.am);
        }
    }

    function calReshuffledBin(shuffledMatrix, binMatrix, assetBin) {
        const newBin = new Array(assetBin.length).fill([]).map(() => []);
        // Populate newBin matrix based on binMatrix and yearsMatrix
        for (let i = 0; i < shuffledMatrix.length; i++) {
            for (let j = 0; j < shuffledMatrix[i].length; j++) {
                let binValue = binMatrix[i][j];
                let yearValue = shuffledMatrix[i][j];
                newBin[binValue].push(yearValue);
            }
        }
        return newBin;
    }

    function shuffleMatrixRows(matrix) {
        return matrix.map(row => {
            const shuffledRow = [...row];

            for (let i = shuffledRow.length - 1; i > 0; i--) {
                const j = Math.floor(Math.random() * (i + 1));
                [shuffledRow[i], shuffledRow[j]] = [shuffledRow[j], shuffledRow[i]];
            }

            return shuffledRow;
        });
    }

    function fillNewMatrix(binMatrix, assetbinMatrix) {
        return binMatrix.map(row =>
            row.map(binIndex => {
                const availableYears = assetbinMatrix[binIndex];
                return availableYears[Math.floor(Math.random() * availableYears.length)];
            })
        );
    }

    function filterRegimeYears(regimeMatrix, yearAssets = []) {
        return regimeMatrix.map(regimeYears =>
            regimeYears.filter(year => {
                //change 1 to id 
                for (const asset of yearAssets) {
                    // for (const asset of yearAssets[1]) {
                    if (asset[0] === year) {
                        return true;
                    }
                }
                return false;
            })
        );
    }

    function createBinMatrix(yearsMatrix, regimeMatrix) {
        const newMatrix = Array.from({ length: yearsMatrix.length }, () => Array(yearsMatrix[0].length).fill(0));
        for (let i = 0; i < yearsMatrix.length; i++) {
            for (let j = 0; j < yearsMatrix[0].length; j++) {
                const currentYear = yearsMatrix[i][j];

                for (let regimeIndex = 0; regimeIndex < regimeMatrix.length; regimeIndex++) {
                    if (regimeMatrix[regimeIndex].includes(currentYear)) {
                        newMatrix[i][j] = regimeIndex;
                        break;
                    }
                }
            }
        }


        return newMatrix;
    }


    const calReturn = (resultArray, initialSPXReturn) => {

        let spxRet = [];
        for (let i = 0; i < resultArray.length; i++) {
            let newRow = [];
            for (let j = 0; j < resultArray[i].length; j++) {
                if (Array.isArray(initialSPXReturn)) {
                    initialSPXReturn.forEach((val) => {
                        if (val[0] == resultArray[i][j]) {
                            newRow.push(val[1]);
                        }
                    });
                }
            }
            spxRet.push(newRow);
        }
        return spxRet;
    }

    const calYearMatrix = (initialSPXReturn, matrix) => {
        let yearMatrixArr = [];
        for (let i = 0; i < matrix.length; i++) {
            let newRow = [];
            for (let j = 0; j < matrix[i].length; j++) {
                newRow.push(initialSPXReturn[matrix[i][j] - 1][0]);
            }
            yearMatrixArr.push(newRow);
        }
        return yearMatrixArr;
    }

    const calculatePercentilePaths = (wealthMatrix, selectedValueFlip, selectedValuePath) => {
        if (wealthMatrix.length > 1) {
            const lastColumn = wealthMatrix.map(row => row[selectedValueFlip - 1]); // Get the last column (end wealth)

            // Create an array of objects with row numbers and their corresponding end wealth
            const rowsWithWealth = lastColumn.map((wealth, index) => ({ wealth, row: index }));


            // Sort the rows based on the end wealth
            rowsWithWealth.sort((a, b) => a.wealth - b.wealth);
            // Calculate percentile paths based on the sorted end wealth
            const percentileValues = [];
            const percentiles = [10, 20, 30, 40, 50, 60, 70, 80, 90];
            percentileValues.push(rowsWithWealth[0].row);
            for (let i = 0; i < percentiles.length; i++) {
                const index = Math.floor((percentiles[i] / 100) * selectedValuePath);
                const percentilePath = rowsWithWealth[index - 1].row;
                percentileValues.push(percentilePath);
            }
            percentileValues.push(rowsWithWealth[rowsWithWealth.length - 1].row);
            return percentileValues;
        }
    };

    const calculateWealthMatrixForOneTwenty = (matrix, profitLossVal, type) => {
        const wealthMatrix = [];
        const profitLossMatrix = [];

        if (profitLossVal != undefined && profitLossVal.length != 0) {
            for (let row = 0; row < matrix.length; row++) {
                const newRow = [];
                const profitLossRow = [];
                let wealth = 1; // Initial wealth at the start of calculation for each row

                for (let col = 0; col < matrix[row].length; col++) {
                    let faceNo = matrix[row][col];
                    let faceNoProfitLossVal;
                    if (profitLossVal.has(faceNo)) {
                        faceNoProfitLossVal = profitLossVal.get(faceNo);
                    }
                    let profitLossPercentage = faceNoProfitLossVal; // faceNo is 1-based index, array index is 0-based
                    profitLossRow.push(profitLossPercentage);
                    let profitLossPercentageWithoutSign = Math.abs(profitLossPercentage);
                    // Calculate wealth for each column in the row based on the profit/loss percentage
                    let profitLossCalc = wealth;
                    profitLossCalc *= profitLossPercentageWithoutSign;
                    if (profitLossPercentage < 0) {
                        wealth -= profitLossCalc;
                    }
                    else {
                        wealth += profitLossCalc;
                    }
                    newRow.push(wealth);
                }

                wealthMatrix.push(newRow);
                profitLossMatrix.push(profitLossRow);
            }
        }
        return [wealthMatrix, profitLossMatrix];
    };

    const updateTable = (percentileValuesForLastCol, selectedValueFlip, wealthMatrixData, profitLossData) => {
        let endWealths = lastColumnValue(percentileValuesForLastCol, wealthMatrixData);
        let cagrData = cagrCal(percentileValuesForLastCol, wealthMatrixData, selectedValueFlip);
        const matrixWithOnesColumn = wealthMatrixData.map(row => [1, ...row]);
        let ddData = ddDataCal(percentileValuesForLastCol, matrixWithOnesColumn);
        let amData = amDataCal(percentileValuesForLastCol, profitLossData);

        let amDataAvg = avgCalc(amData);
        let gmCal = cagrData[5];
        setAm(amDataAvg);
        setGm(gmCal);
    }

    const updateTableForNocrash = (percentileValuesForLastCol, selectedValueFlip, wealthMatrixData, profitLossData) => {
        let endWealths = lastColumnValue(percentileValuesForLastCol, wealthMatrixData);
        let cagrData = cagrCal(percentileValuesForLastCol, wealthMatrixData, selectedValueFlip);
        const matrixWithOnesColumn = wealthMatrixData.map(row => [1, ...row]);
        let ddData = ddDataCal(percentileValuesForLastCol, matrixWithOnesColumn);
        let amData = amDataCal(percentileValuesForLastCol, profitLossData);

        let amDataAvg = avgCalc(amData);
        let gmCal = cagrData[5];
        setAmNocrash(amDataAvg);
        setGmNocrash(gmCal);
    }

    const lastColumnValue = (percentileValuesForLastCol, wealthMatrixData) => {
        const endColumnData = [];
        percentileValuesForLastCol.forEach(rowNo => {
            if (wealthMatrixData[rowNo]) {
                const rowData = wealthMatrixData[rowNo];
                const endColumnValue = rowData[rowData.length - 1]; // Fetching the last element of each row
                endColumnData.push(endColumnValue.toFixed(2));
            }
        });
        return endColumnData;
    }

    const amDataCal = (percentileValuesForLastCol, profitLossData) => {
        const amDataArr = [];
        percentileValuesForLastCol.forEach(rowNo => {
            if (profitLossData[rowNo]) {
                const rowData = profitLossData[rowNo];
                const amRowData = avgCal(rowData); // Fetching the last element of each row
                amDataArr.push(amRowData);
            }
        });
        return amDataArr;
    }

    const avgCal = (rowData) => {
        let sum = 0;
        for (let i = 0; i < rowData.length; i++) {
            sum += rowData[i];
        }
        const avg = sum / rowData.length;
        return (avg * 100).toFixed(1);
    }

    const maxCalc = (rowData) => {
        let max = -Infinity;
        for (let i = 0; i < rowData.length; i++) {
            max = Math.max(rowData[i], max);
        }
        return (max * 100).toFixed(2);
    }

    const minCalc = (rowData) => {
        let min = Infinity;
        for (let i = 0; i < rowData.length; i++) {
            min = Math.min(rowData[i], min);
        }
        return (min * 100).toFixed(2);
    }

    const avgCalc = (rowData) => {
        let sum = 0;
        for (let i = 0; i < rowData.length; i++) {
            sum += Number(rowData[i]);
        }
        const avg = sum / rowData.length;
        return (avg).toFixed(2);
    }

    const avgCalculate = (rowData) => {
        let sum = 0;
        for (let i = 0; i < rowData.length; i++) {
            sum += (rowData[i]);
        }
        const avg = sum / rowData.length;
        return (avg * 100).toFixed(2);
    }

    const ddDataCal = (percentileValuesForLastCol, wealthMatrixData) => {
        const endColumnDd = [];
        percentileValuesForLastCol.forEach(rowNo => {
            if (wealthMatrixData[rowNo]) {
                const rowData = wealthMatrixData[rowNo];
                const endColumnValue = maxDrawDown(rowData); // Fetching the last element of each row
                endColumnDd.push(endColumnValue);
            }
        });
        return endColumnDd;
    }

    const maxDrawDown = (wealthArr) => {
        const dd = []
        for (let i = 1; i <= wealthArr.length; i++) {
            dd.push(wealthArr[i - 1] / Math.max(...wealthArr.slice(0, i)) - 1)
        }
        return (Math.min(...dd) * 100).toFixed(1)
    }

    const cagrCal = (percentileValuesForLastCol, wealthMatrixData, selectedValueFlip) => {
        const resultArray = [];
        if (percentileValuesForLastCol !== undefined) {
            percentileValuesForLastCol.forEach(rowNo => {
                if (wealthMatrixData[rowNo]) {
                    const rowVal = wealthMatrixData[rowNo];
                    const endColValue = rowVal[rowVal.length - 1]; // Fetching the last element of each row
                    const modifiedValue = Math.pow(endColValue, 1 / selectedValueFlip) - 1;
                    const newModifiedNo = (modifiedValue * 100).toFixed(1);
                    resultArray.push(newModifiedNo);
                }
            });
        }
        return resultArray;
    }

    const handleDefault = () => {
        setNormal(true);
        setReshuffle(false);
        setNocrash(false);
        // let yearData = generateDiceMatrixForOneTwentyFaceDice(yearsList);
        // let yearMatrix = yearData[0];
        // let yearList = yearData[1];
        // calSecondAsset(yearMatrix, yearList);
    }

    const handleReshuffle = () => {
        setReshuffle(true);
        setNormal(false);
        setNocrash(false);
        // let yearData = generateDiceMatrixForOneTwentyFaceDice(yearsList);
        // let yearMatrix = yearData[0];
        // let yearList = yearData[1];
        // calSecondAssetForReshuffle(yearMatrixData, yearsList, item);
        // if (mix.length > 0) { // mix you need to create your own and use useeffect
        calAssetsMixReshuffle(yearMatrixData, yearsList);
        // }
    }

    const handleNocrash = () => {
        setNocrash(true);
        setReshuffle(false);
        setNormal(false);

        calAssetMixNoCrash()
    }

    // /* *
    //  * Developer Notes :
    //  *  SPX is called as First Asset
    //  *    db record for SPX utilized by the API for fetching data is 'spx' and is stored in firstAsset Variable
    //  *    the applilication uses firstAssetName to display the name as 'SPX'
    //  *    the value of SPX in each year from 1901 to 2023 is fetched and stored in FirstAssetValues to minimize the API calls later
    //  *    the returns of SPX are calculated by using a common helper function and stored in FirstAssetReturns
    //  *    yearList contains the years in each regime
    //  *    regimeSelectors contain the index of the regime boundary
    //  *    also the dataBar is a ready chart option similar as to the first chart of OSH
    //  * */

    //=========================== Remove this for after Prod Signoff ========================================================== 
    // const handleAllocSliderChange = (e) => {
    //     console.log("🚀 ~ handleAllocSliderChange ~ id:", id)
    //     setAlloc(prevValue => ({ 'firstAssetAlloc': 100 - e.target.value, 'secondAssetAlloc': e.target.value }))
    // }
    //=========================== Remove this for after Prod Signoff ========================================================== 


    const handleAllocSliderChange = (event, id) => {

        // console.log('event', event.target.value)
        // console.log('id', id)
        setAlloc((prevValue) => ({ ...prevValue, [id]: { 'firstAssetAlloc': 100 - event.target.value, 'secondAssetAlloc': event.target.value } }))
    }

    // useEffect(() => {
    // }, [nocrash])

    useEffect(() => {
        assets.map((item, idx) => {
            if (reshuffle == true) {
                calAssetsMixReshuffle(yearMatrixData, yearsList, item)
            }
        })
    }, [firstAssetValues, secondAssetValues, fromYear, toYear, alloc, yearsList, yearMatrixData, regimeCount, regimeSelectors, baseAssetMatrixRetruns, otherAssetMatrixReshuffleReturns, assets, reshuffle])

    useEffect(() => {
        assets.map((items, idx) => {
            if (nocrash == true) {
                calAssetMixNoCrash(items)
            }
        })
    }, [firstAssetValues, secondAssetValues, secondAssetName, fromYear, toYear, alloc, yearsList, yearMatrixDataNocrash, regimeCount, regimeSelectors, baseAssetMatrixReturnsNoCrash, otherAssetMatrixReturnsNoCrash, nocrash, assets])


    const calAssetsMixReshuffle = (yearMatrix, yearsList, item) => {
        if (baseAssetMatrixRetruns.length > 1 && otherAssetMatrixReshuffleReturns[item] !== undefined && otherAssetMatrixReshuffleReturns[item].length > 1) {

            // 25 X 1000 matrix returns 
            const mixMatrix = generateMix(baseAssetMatrixRetruns, otherAssetMatrixReshuffleReturns[item], alloc[item]['secondAssetAlloc'])
            //bin mapping based on base asset -spc years matrix 
            let binMatrix = createBinMatrix(yearMatrixData, yearsList);
            //categorizing 25 x 1000 retruns based on bin Matrix 
            let mappedBin = binMapping(binMatrix, mixMatrix);

            let avgAssetRegime = [];
            let maxAssetValRegime = [];
            let minAssetValRegime = [];
            for (let bin in mappedBin) {
                let getAvg = avgCalculate(mappedBin[bin]);
                avgAssetRegime.push(getAvg);
                let getMax = maxCalc(mappedBin[bin]);
                maxAssetValRegime.push(getMax);
                let getMin = minCalc(mappedBin[bin]);
                minAssetValRegime.push(getMin);
            }

            //=========================== Remove this for after Prod Signoff ========================================================== 
            // setMixMinAssetReshuffleArray(minAssetValRegime);
            // setMixMaxAssetReshuffleArray(maxAssetValRegime);
            // setMixAvgAssetReshuffleArray(avgAssetRegime);
            //=========================== Remove this for after Prod Signoff ========================================================== 

            setMixMinAssetReshuffleArray((prevValue) => ({ ...prevValue, [item]: minAssetValRegime }));
            setMixMaxAssetReshuffleArray((prevValue) => ({ ...prevValue, [item]: maxAssetValRegime }));
            setMixAvgAssetReshuffleArray((prevValue) => ({ ...prevValue, [item]: avgAssetRegime }));

            // when selected asset (other asset ) method for calculating am
            // let amAsset = avgCalc(avgAssetRegime);
            // setMixAMReshuffle(amAsset);

            // let amAssetbase = avgCalc(averageArray);
            const wealthMatrix = generateMixReturnsWealth(mixMatrix)
            let percentileValuesForLastCol = calculatePercentilePaths(wealthMatrix, selectedValueFlip, selectedValuePath);
            const AmGm = calAmGm(percentileValuesForLastCol, selectedValueFlip, wealthMatrix, mixMatrix);
            setMixAMReshuffle((prevValue) => ({ ...prevValue, [item]: AmGm.am }))
            setMixGMReshuffle((prevValue) => ({ ...prevValue, [item]: AmGm.gm }))

        }

    }

    const generatePNLValues = (assetReturns) => {
        if (Array.isArray(assetReturns)) {
            let initialSPXReturn = [...assetReturns].sort((a, b) => a[0] - b[0]);
            let profitLossVal = new Map();

            for (let i = 0; i < initialSPXReturn.length; i++) {
                profitLossVal.set(initialSPXReturn[i][0], initialSPXReturn[i][1]);
            }

            return profitLossVal
        }
    }

    // // Chart 5 Logic 
    const handlePercentileDropdown = (event, percentileName, id) => {
        switch (percentileName) {
            case 'percentile_1':
                lock ?
                    setPercentile1Dropdown((prevValue) => ({
                        ...prevValue,
                        2: event.target.value,
                        3: event.target.value,
                        4: event.target.value,
                        5: event.target.value,
                        [id]: event.target.value
                    }))
                    : setPercentile1Dropdown((prevValue) => ({ ...prevValue, [id]: event.target.value }))
                break;
            case 'percentile_2':
                lock ?
                    setPercentile2Dropdown((prevValue) => ({
                        ...prevValue,
                        2: event.target.value,
                        3: event.target.value,
                        4: event.target.value,
                        5: event.target.value,
                        [id]: event.target.value
                    })) :
                    setPercentile2Dropdown((prevValue) => ({ ...prevValue, [id]: event.target.value }))
                break;
        }
    }

    //MixGeneration new Logic
    useEffect(() => {
        // console.log('assets', assets)

        assets.map((item, idx) => {

            if (baseAssetMatrixRetruns.length > 1 && otherAssetMatrixRetruns[item] !== undefined && otherAssetMatrixRetruns[item].length > 1) {

                // 25 X 1000 matrix returns 
                const mixMatrix = generateMix(baseAssetMatrixRetruns, otherAssetMatrixRetruns[item], alloc[item]['secondAssetAlloc'])
                //bin mapping based on base asset -spc years matrix 
                let binMatrix = createBinMatrix(yearMatrixData, yearsList);
                //categorizing 25 x 1000 retruns based on bin Matrix 
                let mappedBin = binMapping(binMatrix, mixMatrix);

                let avgAssetRegime = [];
                let maxAssetValRegime = [];
                let minAssetValRegime = [];
                for (let bin in mappedBin) {
                    let getAvg = avgCalculate(mappedBin[bin]);
                    avgAssetRegime.push(getAvg);
                    let getMax = maxCalc(mappedBin[bin]);
                    maxAssetValRegime.push(getMax);
                    let getMin = minCalc(mappedBin[bin]);
                    minAssetValRegime.push(getMin);
                }

                setMixMinAssetArray((prevValue) => ({ ...prevValue, [item]: minAssetValRegime }));
                setMixMaxAssetArray((prevValue) => ({ ...prevValue, [item]: maxAssetValRegime }));
                setMixAvgAssetArray((prevValue) => ({ ...prevValue, [item]: avgAssetRegime }));

                // when selected asset (other asset ) method for calculating am

                // let amAsset = avgCalc(avgAssetRegime);
                // setMixAM(amAsset);
                // let amAssetbase = avgCalc(averageArray);
                const wealthMatrix = generateMixReturnsWealth(mixMatrix)
                let percentileValuesForLastCol = calculatePercentilePaths(wealthMatrix, selectedValueFlip, selectedValuePath);
                const AmGm = calAmGm(percentileValuesForLastCol, selectedValueFlip, wealthMatrix, mixMatrix);
                setMixAM((prevValue) => ({ ...prevValue, [item]: AmGm.am }))
                setMixGM((prevValue) => ({ ...prevValue, [item]: AmGm.gm }))

            }
        })
    }, [baseAssetMatrixRetruns, otherAssetMatrixRetruns, alloc, averageArray, assets])

    const calAmGm = (percentileValuesForLastCol, selectedValueFlip, wealthMatrixData, profitLossData) => {
        let endWealths = lastColumnValue(percentileValuesForLastCol, wealthMatrixData);
        let cagrData = cagrCal(percentileValuesForLastCol, wealthMatrixData, selectedValueFlip);
        const matrixWithOnesColumn = wealthMatrixData.map(row => [1, ...row]);
        let ddData = ddDataCal(percentileValuesForLastCol, matrixWithOnesColumn);
        let amData = amDataCal(percentileValuesForLastCol, profitLossData);

        let amDataAvg = avgCalc(amData);
        let gmCal = cagrData[5];
        return { am: amDataAvg, gm: gmCal }
    }

    // //NoCrashMix 
    const calAssetMixNoCrash = (item) => {
        if (baseAssetMatrixReturnsNoCrash.length > 1 && otherAssetMatrixReturnsNoCrash[item] !== undefined && otherAssetMatrixReturnsNoCrash[item].length > 1) {

            // 25 X 1000 matrix returns 
            const mixMatrix = generateMix(baseAssetMatrixReturnsNoCrash, otherAssetMatrixReturnsNoCrash[item], alloc[item]['secondAssetAlloc'])
            //bin mapping based on base asset -spc years matrix 
            let binMatrix = createBinMatrix(yearMatrixDataNocrash, yearsList);
            //categorizing 25 x 1000 retruns based on bin Matrix 
            let mappedBin = binMapping(binMatrix, mixMatrix);

            let avgAssetRegime = [0];
            let maxAssetValRegime = [0];
            let minAssetValRegime = [0];
            for (let bin in mappedBin) {
                let getAvg = avgCalculate(mappedBin[bin]);
                avgAssetRegime.push(getAvg);
                let getMax = maxCalc(mappedBin[bin]);
                maxAssetValRegime.push(getMax);
                let getMin = minCalc(mappedBin[bin]);
                minAssetValRegime.push(getMin);
            }

            setMinMixNoCrash((prevValue) => ({ ...prevValue, [item]: minAssetValRegime }));
            setMaxMixNoCrash((prevValue) => ({ ...prevValue, [item]: maxAssetValRegime }));
            setAvgMixNoCrash((prevValue) => ({ ...prevValue, [item]: avgAssetRegime }));

            // when selected asset (other asset ) method for calculating am
            // let amAsset = avgCalc(avgAssetRegime);
            // setMixAMReshuffle(amAsset);

            // let amAssetbase = avgCalc(averageArray);
            const wealthMatrix = generateMixReturnsWealth(mixMatrix)
            let percentileValuesForLastCol = calculatePercentilePaths(wealthMatrix, selectedValueFlip, selectedValuePath);
            const AmGm = calAmGm(percentileValuesForLastCol, selectedValueFlip, wealthMatrix, mixMatrix);
            setAmMixNoCrash((prevValue) => ({ ...prevValue, [item]: AmGm.am }));
            setGmMixNoCrash((prevValue) => ({ ...prevValue, [item]: AmGm.gm }));

        }
    }


    //MULTI Asset --------------------------------------------------------------------------------------------
    // const initialAssets = [1];
    // const [assets, setAssets] = useState(initialAssets);

    useEffect(() => {
        // console.log(alloc)
    }, [assets, alloc])

    useEffect(() => {
        mulitiCEAplotCoordinates(mixAM, mixGM, am, gm)
    }, [mixAM, mixGM, am, gm, amNocrash, gmNocrash, amMixNoCrash, gmMixNoCrash, mixAMReshuffle, mixGMReshuffle])

    useEffect(() => {
        if (lock === true) {
            setPercentile1Dropdown({ 1: percentile1Dropdown[1], 2: percentile1Dropdown[1], 3: percentile1Dropdown[1], 4: percentile1Dropdown[1], 5: percentile1Dropdown[1] })
            setPercentile2Dropdown({ 1: percentile2Dropdown[1], 2: percentile2Dropdown[1], 3: percentile2Dropdown[1], 4: percentile2Dropdown[1], 5: percentile2Dropdown[1] })
        }
    }, [lock])

    const handleAddAsset = () => {
        let newAssetId = (assets.length) + 1
        if (assets.length <= 5) {
            setSecondAsset(prevValue => {
                let lastKey = Object.keys(prevValue)[Object.keys(prevValue).length - 1]
                let lastKeyValue = prevValue[lastKey]

                return { ...prevValue, [newAssetId]: lastKeyValue }
            })
            setSecondAssetName((prevValue) => {
                let lastKey = Object.keys(prevValue)[Object.keys(prevValue).length - 1]
                let lastKeyValue = prevValue[lastKey]

                return { ...prevValue, [newAssetId]: lastKeyValue }
            })
            setSecondAssetValues((prevValue) => {
                let lastKey = Object.keys(prevValue)[Object.keys(prevValue).length - 1]
                let lastKeyValue = prevValue[lastKey]

                return { ...prevValue, [newAssetId]: lastKeyValue }
            })
        }

        setAssets((prevValue) => {
            let length = prevValue.length;
            if (length < 5) {
                // setAlloc(() => ({ ...prevValue, [prevValue[length - 1] + 1]: initialAlloc }))
                return [...prevValue, prevValue[length - 1] + 1];
            }
            return prevValue;
        });
    };

    const intitialSecondAssetValues = async (options, id) => {
        const apiValues = await callApiBasic(options)
        // setSecondAssetValuesRet((prevValue) => ({ ...prevValue, [id]: apiValues }))
        setSecondAssetValues((prevValue) => ({ ...prevValue, [id]: apiValues }))
    }

    const callApiBasic = async (options) => {
        try {
            setLoading(prevValue => true);
            const data = await axios.post(`${api_url}/fetch-data`, options);
            let raw_data = data && data.data.data || [];
            setLoading(prevValue => false);
            return raw_data
        } catch (error) {
            setLoading(prevValue => false);
        }
    }

    const handleLock = () => {
        setLock(prevValue => !prevValue);
    }

    const handlePin = () => {
        setPin(prevValue => !prevValue)
    }

    const mulitiCEAplotCoordinates = (mixAM, mixGM, am, gm) => {
        let coordinates = []
        if (Object.keys(mixAM).length === Object.keys(mixGM).length) {
            for (let i = 1; i <= Object.keys(mixAM).length; i++) {
                coordinates.push({
                    asset: secondAssetName[i],
                    xAxis: (-(mixAM[i] - am)).toFixed(1),
                    yAxis: (-(mixGM[i] - gm)).toFixed(1)
                })
            }

        }

        // console.log("🚀 ~ mulitiCEAplotCordinates ~ cordinates:", coordinates)
        return coordinates
    }

    //MULTI Asset --------------------------------------------------------------------------------------------

    //Custom SLider logic 
    const handleInsuranceSliderValue = (event, id) => {
        setInsuranceSliderValue((prevValue) => ({ ...prevValue, [id]: event.target.value }))
        let newValue = parseInt(event.target.value);
        if (newValue <= 60) {
            // newValue = Math.round(newValue / 60 * 5);
            newValue = (0.1 + (newValue / 60) * (10.0 - 0.1)).toFixed(1);
        } else if (newValue >= 61 && newValue <= 100) {
            newValue = Math.round(11 + ((newValue - 61) / (100 - 61) * (100 - 11))).toFixed(0);
        }
        setAlloc((prevValue) => ({ ...prevValue, [id]: { 'firstAssetAlloc': 100 - newValue, 'secondAssetAlloc': newValue } }))
    }
    //Custom SLider logic 





    return (<>
        <Loader loading={loading} />
        <div className='bg-slate-200 py-5 text-sm text-gray-700'>
            <div className='grid grid-cols-12 items-center gap-10 max-w-7xl px-4 mx-auto text-left mb-6'>
                <div className='flex flex-row col-span-4'>
                    {< div className='mr-3'>
                        <button className={`${pin ? '' : 'bg-[#69a4ce] pinned'} border-2 border-[#69a4ce] rounded-md h-7 w-7 p-1`} onClick={handlePin}>
                            <svg stroke="currentColor" fill="currentColor" stroke-width="0" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path d="M7.886 1.553a1.75 1.75 0 0 1 2.869.604l.633 1.629a5.667 5.667 0 0 0 3.725 3.395l3.959 1.131a1.749 1.749 0 0 1 .757 2.92L16.06 15l5.594 5.595a.749.749 0 0 1-.326 1.275.749.749 0 0 1-.734-.215L15 16.061l-3.768 3.768a1.749 1.749 0 0 1-2.92-.757l-1.131-3.96a5.665 5.665 0 0 0-3.395-3.724l-1.63-.633a1.75 1.75 0 0 1-.603-2.869Zm6.589 12.912 4.293-4.294a.25.25 0 0 0-.108-.417L14.7 8.623A7.165 7.165 0 0 1 9.99 4.33L9.357 2.7a.25.25 0 0 0-.41-.086L2.614 8.946a.25.25 0 0 0 .086.41l1.63.634a7.167 7.167 0 0 1 4.294 4.71l1.13 3.96a.25.25 0 0 0 .417.108l4.294-4.293Z"></path></svg>
                        </button>
                    </div>}
                    <label className='font-medium w-16 bold mb-2'>
                        Period
                    </label>
                    <div className='flex flex-col relative w-full items-right justify-right mt-2 px-6'>
                        <MultiRangeSlider
                            min={1901}
                            max={2023}
                            onChange={({ min, max }) => handleYearChange({ min, max })}
                        />
                    </div>
                </div>

                <div className='flex flex-row col-span-5'>
                    <div className='flex items-center me-3'>
                        <label className='font-medium me-3 bold'> Regimes </label>
                        <input
                            type="number"
                            name="min"
                            value={regimeCount + 1}
                            min={2}
                            max={5}
                            className="border border-gray-300 ps-3 py-1 w-16 rounded"
                            onChange={(e) => { setRegimeCount(e.target.value - 1) }}
                        />
                    </div>

                    <div className='flex w-full'>
                        <MUISlider
                            min={regimeSelectors[0]}
                            max={regimeSelectors[regimeSelectors.length - 1]}
                            value={regimeSelectors.slice(1, -1)}
                            valueLabelDisplay={'auto'}
                            disableSwap={true}
                            onChange={(e, v, t) => {
                                let activeThumb = t + 1
                                if (regimeSelectors[activeThumb - 1] <= v[t] && v[t] <= regimeSelectors[activeThumb + 1]) {
                                    let buffer = [...regimeSelectors];
                                    buffer[activeThumb] = v[t]
                                    updateRegime(v[t], "regime_value_" + activeThumb)
                                }
                            }}
                            valueLabelFormat={(x) => {
                                let year = dataBar?.labels[x - 1]
                                let value = dataBar?.datasets[0]?.data[x - 1]
                                if (!year || !value) {
                                    return "Loading..."
                                }
                                return value?.toFixed(3) + " of " + year;
                            }}
                            track={false}
                        />
                    </div>
                </div>

                <div className='flex flex-row col-span-3 justify-end'>
                    <div className=''>
                        {/* <CustomToggle
                            checked={multiAssetToggle}
                            labelRight={"Compare Different Assets"}
                            defaultChecked={multiAssetToggle}
                            onChange={handleMultiAssetToggle}
                        /> */}
                        <ToggleSwitch
                            toggleData={[
                                { id: 'Single Asset', checked: false, color: 'bg-blue-200', label: 'Single Asset', name: 'Single Asset' },
                                { id: 'Different Assets', checked: false, color: 'bg-green-200', label: 'Different Assets', name: 'Different Assets' },
                            ]}
                            value={multiAssetToggle}
                            onChange={handleMultiAssetToggle}
                        />
                    </div>

                </div>
            </div>
            <div className='flex justify-center max-w-2xl mx-auto px-4 text-left mb-4'>
                <div className='rounded-md overflow-hidden border border-[#69a4ce]'>
                    <button className={`text-sm py-2 px-4 ${normal === true ? 'bg-[#69a4ce] text-white' : ''}`} onClick={handleDefault}>
                        Default
                    </button>
                    <button className={`text-sm py-2 border-x border-[#69a4ce] px-4 ${reshuffle === true ? 'bg-[#69a4ce] text-white' : ''}`} onClick={handleReshuffle}>
                        Reshuffle
                    </button>
                    <button className={`text-sm py-2 px-4 ${nocrash === true ? 'bg-[#69a4ce] text-white' : ''}`} onClick={handleNocrash}>
                        No Crash
                    </button>
                    {/* <button className="bg-white hover:bg-gray-100  font-semibold py-2 px-4 border-gray-400 rounded-lg shadow-lg">
            Leverage
          </button> */}
                </div>
            </div>
            <div className='grid grid-cols-5 mx-auto justify-center items-center gap-3 2xl:gap-5 px-5 ps-28'>
                {assets.map((items, idx) => {

                    return (
                        <>
                            <div className='w-full'>
                                <div className='relative'>
                                    {/* <div className='mb-4 flex items-center'> */}
                                    {/* {items === 1 && <div className='justify-center me-2'>SPX Distribution </div>} */}
                                    {pin &&
                                        <>
                                            <div className='bg-white rounded-lg shadow-xl shadow-slate-300 p-2'>
                                                <div className='flex flex-row justify-center w-full h-auto gap-1 text-sm 2xl:text-base'>
                                                    {!isEmpty(regimeBar) && !isEmpty(regimeBar.datasets[0]) && regimeBar.datasets[0].data.map((dataValue, idx) => {
                                                        return (
                                                            <>
                                                                <div className='flex flex-1 flex-col bg-white h-12 w-12 justify-center items-center border rounded' >
                                                                    {dataValue.y}
                                                                </div>
                                                            </>
                                                        )
                                                    })}
                                                </div>
                                            </div>
                                        </>
                                    }
                                    {!pin &&
                                        <>
                                            <div className='bg-white rounded-lg shadow-xl shadow-slate-300 p-2'>

                                                {(normal == true || reshuffle == true) &&
                                                    <BarCandles dataset={regimeBar} title={{ x: "Annual " + firstAssetName + " Returns", y: "Num of Years" }} />
                                                }
                                                {(nocrash == true) &&
                                                    <BarCandles dataset={regimeBarForNocrash} title={{ x: "Annual " + firstAssetName + " Returns", y: "Num of Years" }} />
                                                }

                                            </div>
                                        </>
                                    }
                                    {items === 1 && <div className='graph-header absolute right-full me-4 text-right text-sm 2xl:text-base'>SPX<br /> Distribution</div>}
                                </div>
                                <div className='grid grid-cols-12 my-3 relative text-sm 2xl:text-base'>
                                    {items === 1 && <div className='graph-header absolute right-full me-4 text-right'>Bootstrap</div>}
                                    <div className='col-span-12'>
                                        <hr class="border-1 border-black" />
                                    </div>
                                </div>
                                <div className='mb-4 relative'>
                                    {items === 1 && <div className='graph-header absolute right-full me-4 text-right text-sm 2xl:text-base'>SPX</div>}
                                    {/* <div className='mb-4 flex items-center'> */}
                                    {/* {items === 1 && <div className='justify-center me-2 w-48'>Bootstrap </div>} */}
                                    <div className='col-span-7'>
                                        {pin &&
                                            <>
                                                <div className='col-span-7 bg-white rounded-lg shadow-xl shadow-slate-300 p-2'>
                                                    <div className='flex flex-row justify-center w-full h-auto gap-1 text-sm 2xl:text-base'>
                                                        {!isEmpty(averageArray) && averageArray.map((dataValue, idx) => {
                                                            return (
                                                                <>
                                                                    <div className='flex flex-1 flex-col bg-white h-12 w-12 justify-center items-center border rounded'>
                                                                        {dataValue}
                                                                    </div>
                                                                </>
                                                            )
                                                        })}
                                                    </div>
                                                </div>
                                            </>
                                        }
                                        {
                                            !pin &&
                                            <>
                                                <div className='bg-white rounded-lg shadow-xl shadow-slate-300 p-2'>
                                                    {(normal || reshuffle) &&
                                                        <BarChart2
                                                            min={minArray}
                                                            max={maxArray}
                                                            average={averageArray}
                                                        />
                                                    }
                                                    {(nocrash) &&
                                                        <BarChart2
                                                            min={minArrayNocrash}
                                                            max={maxArrayNocrash}
                                                            average={averageArrayNocrash}
                                                        />
                                                    }
                                                </div>
                                            </>
                                        }
                                    </div>
                                    {/* <div className='col-span-5 mt-2'>
                                        <div className='flex gap-2 flex-col sm:flex-row'>
                                            {(normal || reshuffle) &&
                                                <button className="text-xs bg-white whitespace-nowrap hover:bg-gray-100  font-semibold py-2 px-2 border-gray-400 rounded-lg shadow-lg">
                                                    {'AM: ' + am + '%'}
                                                </button>
                                            }
                                            {(normal || reshuffle) &&
                                                <button className="text-xs bg-white whitespace-nowrap hover:bg-gray-100  font-semibold py-2 px-2 border-gray-400 rounded-lg shadow-lg">
                                                    {'GM: ' + gm + '%'}
                                                </button>
                                            }
                                            {(nocrash) &&
                                                <button className="text-xs bg-white whitespace-nowrap hover:bg-gray-100  font-semibold py-2 px-2 border-gray-400 rounded-lg shadow-lg">
                                                    {'AM: ' + amNocrash + '%'}
                                                </button>
                                            }
                                            {(nocrash) &&
                                                <button className="text-xs bg-white whitespace-nowrap hover:bg-gray-100  font-semibold py-2 px-2 border-gray-400 rounded-lg shadow-lg">
                                                    {'GM: ' + gmNocrash + '%'}
                                                </button>
                                            }
                                        </div>
                                    </div> */}
                                </div>


                                <div className='mb-4 relative'>
                                    {/* <div className='mb-4 flex items-center'> */}
                                    {/* {items === 1 && <div className='justify-center me-2'>Other Asset</div>} */}
                                    <div className='col-span-7'>
                                        {pin &&
                                            <>
                                                <div className='col-span-7 bg-white rounded-lg shadow-xl shadow-slate-300 p-2'>
                                                    <div className='flex flex-row justify-center w-full h-auto gap-1 text-sm 2xl:text-base'>
                                                        {!isEmpty(averageAssetArray[items]) && averageAssetArray[items].map((dataValue, idx) => {
                                                            return (
                                                                <>
                                                                    <div className='flex flex-1 flex-col bg-white h-12 w-12 justify-center items-center border rounded'>
                                                                        {!isEmpty(dataValue) ? dataValue : "X"}
                                                                    </div>
                                                                </>
                                                            )
                                                        })}
                                                    </div>
                                                </div>
                                            </>
                                        }
                                        {
                                            !pin &&
                                            <>
                                                <div className='w-full bg-white rounded-lg shadow-xl shadow-slate-300 p-2'>
                                                    {normal &&
                                                        <BarChart3
                                                            min={minAssetArray[items] || []}
                                                            max={maxAssetArray[items] || []}
                                                            average={averageAssetArray[items] || []}
                                                        />
                                                    }
                                                    {reshuffle &&
                                                        <BarChart3
                                                            min={minAssetReshuffleArray[items] || []}
                                                            max={maxAssetReshuffleArray[items] || []}
                                                            average={averageAssetReshuffleArray[items] || []}
                                                        />
                                                    }
                                                    {nocrash &&
                                                        <BarChart3
                                                            min={minAssetArrayNocrash[items] || []}
                                                            max={maxAssetArrayNocrash[items] || []}
                                                            average={averageAssetArrayNocrash[items] || []}
                                                        />
                                                    }
                                                </div>
                                            </>
                                        }
                                        {items === 1 && <div className='graph-header absolute right-full me-4 text-right text-sm 2xl:text-base'>Other<br /> Asset</div>}
                                    </div>

                                    {/* <div className='col-span-5 flex gap-2 mt-2'> */}
                                    {/* <div className='text-sm'>
                                            <CatListDropdown
                                                id={generateUniqueId()}
                                                label={"Other Asset"}
                                                value={secondAsset[items]}
                                                options={categoryother}
                                                onChange={() => handleSecondAsset(event, items)}
                                            />
                                        </div> */}

                                    {/* <div className=''>
                                            {normal && (
                                                <button className="text-xs bg-white whitespace-nowrap hover:bg-gray-100  font-semibold py-2 px-2 border-gray-400 rounded-lg shadow-lg">
                                                    {'AM: ' + (amMean[items] != undefined ? amMean[items] : 0) + '%'}
                                                </button>
                                            )}
                                            {reshuffle &&
                                                <button className="text-xs bg-white whitespace-nowrap hover:bg-gray-100  font-semibold py-2 px-2 border-gray-400 rounded-lg shadow-lg">
                                                    {'AM: ' + amMeanReshuffle[items] != undefined ? amMeanReshuffle[items] : 0) + '%'}
                                                </button>
                                            }
                                            {nocrash &&
                                                <button className="text-xs bg-white whitespace-nowrap hover:bg-gray-100  font-semibold py-2 px-2 border-gray-400 rounded-lg shadow-lg">
                                                    {'AM: ' + amMeanNocrash + '%'}
                                                </button>
                                            }
                                        </div> */}
                                    {/* </div> */}

                                </div>

                                <div className='grid grid-cols-12 my-2'>
                                    <div className='col-span-12 mb-2'>
                                        <hr class="border-1 border-black" />
                                    </div>
                                    <div className='col-span-12'>
                                        <hr class="border-1 border-black" />
                                    </div>
                                </div>
                                <div className='mb-4 relative'>
                                    {/* <div className='mb-4 flex items-center'> */}
                                    {/* {items === 1 && <div className='justify-center me-2'>SPX + Other Asset </div>} */}
                                    <div className='col-span-7'>
                                        {pin &&
                                            <>
                                                <div className='col-span-7 bg-white rounded-lg shadow-xl shadow-slate-300 p-2'>
                                                    <div className='flex flex-row justify-center w-full h-auto gap-1 relative text-sm 2xl:text-base'>
                                                        {!isEmpty(mixAvgAssetArray[items]) && mixAvgAssetArray[items].map((dataValue, idx) => {
                                                            return (
                                                                <>
                                                                    <div className='flex flex-1 flex-col bg-white h-12 w-12 justify-center items-center border rounded'>
                                                                        {dataValue}
                                                                    </div>
                                                                </>
                                                            )
                                                        })}
                                                        {items === 1 && <div className='graph-header absolute right-full me-4 text-right w-full'>SPX +<br /> Other Asset</div>}
                                                    </div>
                                                </div>
                                            </>
                                        }
                                        {
                                            !pin && <>
                                                <div className='relative'>
                                                    <div className='flex flex-col mx-auto w-full bg-white rounded-lg shadow-xl shadow-slate-300 p-2'>
                                                        {normal && <BarChart4
                                                            min={mixMinAssetArray[items] || []}
                                                            max={mixMaxAssetArray[items] || []}
                                                            average={mixAvgAssetArray[items] || []}
                                                        />
                                                        }
                                                        {reshuffle &&
                                                            <BarChart4
                                                                min={mixMinAssetReshuffleArray[items] || []}
                                                                max={mixMaxAssetReshuffleArray[items] || []}
                                                                average={mixAvgAssetReshuffleArray[items] || []}
                                                            />
                                                        }
                                                        {nocrash &&
                                                            <BarChart4
                                                                min={minMixNoCrash[items] || []}
                                                                max={maxMixNoCrash[items] || []}
                                                                average={avgMixNoCrash[items] || []}
                                                            />
                                                        }

                                                    </div>
                                                    {items === 1 && <div className='graph-header absolute right-full me-4 text-right w-full text-sm 2xl:text-base'>SPX +<br /> Other Asset</div>}
                                                </div>
                                            </>
                                        }

                                    </div>
                                    <div className='col-span-5 mt-2'>
                                        <div className='col-span-5 flex gap-2 mt-2 mb-2'>
                                            <div className='text-sm'>
                                                <CatListDropdown
                                                    id={generateUniqueId()}
                                                    // label={"Other Asset"}
                                                    value={secondAsset[items]}
                                                    options={categoryother}
                                                    onChange={() => handleSecondAsset(event, items)}
                                                />
                                            </div>
                                        </div>
                                        <div className='flex flex-col items-center gap-3 mb-2'>
                                            <div className='w-full'>
                                                {/* <div className='flex w-full'>
                                                    <Slider min={0} max={100} onChange={() => handleAllocSliderChange(event, items)} disabledBin={false} value={alloc[items]['secondAssetAlloc']} />
                                                </div> */}
                                                {
                                                    insuranceTypes.some(type => secondAsset[items].includes(type)) ?
                                                        <>
                                                            {/* <CustomSlider min={0} max={100} sliderValue={insuranceSliderValue} displayValue={alloc['secondAssetAlloc']} step={insuranceSliderValue <= 60 ? 0.1 : 1} onChange={handleInsuranceSliderValue} /> */}
                                                            <CustomSlider min={0} max={100} sliderValue={insuranceSliderValue[items]} displayValue={alloc[items]['secondAssetAlloc']} step={0 <= 60 ? 0.1 : 1} onChange={() => handleInsuranceSliderValue(event, items)} />
                                                        </> :
                                                        <>
                                                            <Slider min={0} max={100} onChange={() => handleAllocSliderChange(event, items)} disabledBin={false} value={alloc[items]['secondAssetAlloc']} />
                                                        </>
                                                }
                                            </div>

                                            <div className=''>
                                                <div className='flex mx-auto'>
                                                    <div className='whitespace-nowrap text-sm'>{alloc[items]['firstAssetAlloc']}% SPX + {alloc[items]['secondAssetAlloc']}% {secondAssetName[items]}</div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='flex items-center gap-2 relative'>
                                            {/* <div>
                                                <div className='flex gap-2'>
                                                    {(normal) &&
                                                        <button className="text-xs bg-white whitespace-nowrap hover:bg-gray-100  font-semibold py-2 px-2 border-gray-400 rounded-lg shadow-lg">
                                                            {'AM: ' + (mixAM[items] != undefined ? mixAM[items] : 0) + '%'}
                                                        </button>
                                                    }
                                                    {reshuffle &&
                                                        <button className="text-xs bg-white whitespace-nowrap hover:bg-gray-100  font-semibold py-2 px-2 border-gray-400 rounded-lg shadow-lg">
                                                            {'AM: ' + mixAMReshuffle + '%'}
                                                        </button>
                                                    }
                                                    {nocrash &&
                                                        <button className="text-xs bg-white whitespace-nowrap hover:bg-gray-100  font-semibold py-2 px-2 border-gray-400 rounded-lg shadow-lg">
                                                            {'AM: ' + amMixNoCrash + '%'}
                                                        </button>
                                                    }
                                                    {(normal) &&
                                                        <button className="text-xs bg-white whitespace-nowrap hover:bg-gray-100  font-semibold py-2 px-2 border-gray-400 rounded-lg shadow-lg">
                                                            {'GM: ' + (mixGM[items] != undefined ? mixGM[items] : 0) + '%'}
                                                        </button>
                                                    }
                                                    {reshuffle &&
                                                        <button className="text-xs bg-white whitespace-nowrap hover:bg-gray-100  font-semibold py-2 px-2 border-gray-400 rounded-lg shadow-lg">
                                                            {'GM: ' + mixGMReshuffle + '%'}
                                                        </button>
                                                    }
                                                    {nocrash &&
                                                        <button className="text-xs bg-white whitespace-nowrap hover:bg-gray-100  font-semibold py-2 px-2 border-gray-400 rounded-lg shadow-lg">
                                                            {'GM: ' + gmMixNoCrash + '%'}
                                                        </button>
                                                    }
                                                </div>

                                            </div> */}
                                            {/* <div className='text-center w-full mb-1'>
                                                <p className='text-sm'>Difference vs SPX</p>
                                            </div> */}
                                            {items === 1 && <div className='graph-header absolute right-full me-4 text-right text-sm 2xl:text-base'>Difference<br /> V/S SPX</div>}

                                            <div>
                                                <div className='flex gap-2'>
                                                    {(normal) &&
                                                        <button className="text-xs bg-white whitespace-nowrap hover:bg-gray-100  font-semibold py-2 px-2 border-gray-400 rounded-lg shadow-lg shadow-slate-300">
                                                            {'AM: ' + (mixAM[items] != undefined ? parseFloat(mixAM[items] - am).toFixed(1) : 0) + '%'}
                                                        </button>}
                                                    {(normal) && <button className="text-xs bg-white whitespace-nowrap hover:bg-gray-100  font-semibold py-2 px-2 border-gray-400 rounded-lg shadow-lg shadow-slate-300">
                                                        {'GM: ' + (mixAM[items] != undefined ? parseFloat(mixGM[items] - gm).toFixed(1) : 0) + '%'}
                                                    </button>}
                                                    {reshuffle &&
                                                        <button className="text-xs bg-white whitespace-nowrap hover:bg-gray-100  font-semibold py-2 px-2 border-gray-400 rounded-lg shadow-lg shadow-slate-300">
                                                            {'AM: ' + (mixAMReshuffle[items] != undefined ? parseFloat(mixAMReshuffle[items] - am).toFixed(1) : 0) + '%'}
                                                        </button>}
                                                    {reshuffle && <button className="text-xs bg-white whitespace-nowrap hover:bg-gray-100  font-semibold py-2 px-2 border-gray-400 rounded-lg shadow-lg shadow-slate-300">
                                                        {'GM: ' + (mixGMReshuffle[items] != undefined ? parseFloat(mixGMReshuffle[items] - gm).toFixed(1) : 0) + '%'}
                                                    </button>}
                                                    {nocrash &&
                                                        <button className="text-xs bg-white whitespace-nowrap hover:bg-gray-100  font-semibold py-2 px-2 border-gray-400 rounded-lg shadow-lg shadow-slate-300">
                                                            {'AM: ' + (amMixNoCrash[items] != undefined ? parseFloat(amMixNoCrash[items] - amNocrash).toFixed(1) : 0) + '%'}
                                                        </button>}
                                                    {nocrash && <button className="text-xs bg-white whitespace-nowrap hover:bg-gray-100  font-semibold py-2 px-2 border-gray-400 rounded-lg shadow-lg shadow-slate-300">
                                                        {'GM: ' + (gmMixNoCrash[items] != undefined ? parseFloat(gmMixNoCrash[items] - gmNocrash).toFixed(1) : 0) + '%'}
                                                    </button>}
                                                </div>
                                                <div className='grid grid-cols-2 text-center'>
                                                    <p className="text-xs p-1 whitespace-nowrap  font-semibold">
                                                        COST
                                                    </p>
                                                    <p className="text-xs p-1 whitespace-nowrap  font-semibold">
                                                        NET
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className='mb-4'>
                                    <div className='col-span-7'>
                                        <div className='w-full bg-white rounded-lg shadow-xl shadow-slate-300 p-2'>
                                            <Chart5
                                                assetType={isInsurance(secondAsset[items])}
                                                fromYear={fromYear}
                                                toYear={toYear}
                                                firstAssetValues={firstAssetValues}
                                                secondAssetValues={secondAssetValues[items] || []}
                                                yearsMatrix={yearMatrixData}
                                                yearsList={yearsList}
                                                percentile1Dropdown={percentile1Dropdown[items]}
                                                percentile2Dropdown={percentile2Dropdown[items]}

                                                //new mix logic props 
                                                baseAssetMatrixRetruns={baseAssetMatrixRetruns}
                                                otherAssetMatrixRetruns={otherAssetMatrixRetruns[items] || []}
                                                reshuffle={reshuffle}
                                                normal={normal}
                                                otherAssetMatrixReshuffleReturns={otherAssetMatrixReshuffleReturns[items] || []}
                                                nocrash={nocrash}
                                                baseAssetMatrixReturnsNoCrash={baseAssetMatrixReturnsNoCrash || []}
                                                otherAssetMatrixReturnsNoCrash={otherAssetMatrixReturnsNoCrash[items] || []}
                                            />
                                        </div>
                                    </div>
                                    <div className='col-span-5 mt-2'>
                                        <div className='flex gap-2'>
                                            <div className=''>
                                                <CatListDropdown
                                                    id={'percentile_1'}
                                                    value={percentile1Dropdown[items]}
                                                    options={percentileOptions0To100}
                                                    onChange={() => handlePercentileDropdown(event, 'percentile_1', items)}
                                                    disabled={items > 1 && lock ? true : false}
                                                />
                                            </div>
                                            <div className=''>
                                                <CatListDropdown
                                                    id={"percentile_2"}
                                                    value={percentile2Dropdown[items]}
                                                    options={percentileOptions5To95}
                                                    onChange={() => handlePercentileDropdown(event, 'percentile_2', items)}
                                                    disabled={items > 1 && lock ? true : false}
                                                />
                                            </div>
                                            {items === 1 && <><div className='bg-[#e9e9ed] h-8 w-8 rounded-md border '>
                                                {lock ?
                                                    <button className='w-full p-1' onClick={handleLock}>
                                                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
                                                            <path stroke-linecap="round" stroke-linejoin="round" d="M16.5 10.5V6.75a4.5 4.5 0 1 0-9 0v3.75m-.75 11.25h10.5a2.25 2.25 0 0 0 2.25-2.25v-6.75a2.25 2.25 0 0 0-2.25-2.25H6.75a2.25 2.25 0 0 0-2.25 2.25v6.75a2.25 2.25 0 0 0 2.25 2.25Z" />
                                                        </svg>
                                                    </button > :
                                                    <button className='h-6 w-6 p-1' onClick={handleLock}>
                                                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
                                                            <path stroke-linecap="round" stroke-linejoin="round" d="M13.5 10.5V6.75a4.5 4.5 0 1 1 9 0v3.75M3.75 21.75h10.5a2.25 2.25 0 0 0 2.25-2.25v-6.75a2.25 2.25 0 0 0-2.25-2.25H3.75a2.25 2.25 0 0 0-2.25 2.25v6.75a2.25 2.25 0 0 0 2.25 2.25Z" />
                                                        </svg>
                                                    </button>
                                                }
                                            </div>
                                            </>
                                            }
                                        </div>
                                    </div>
                                </div>


                            </div >
                        </>
                    )
                })}


                {/* <div className='w-full'>
                    <div className='mb-4'>
                        <div className='col-span-7 bg-white rounded-lg shadow-xl p-2'>
                            {(normal == true || reshuffle == true) &&
                                <BarCandles dataset={regimeBar} title={{ x: "Annual " + firstAssetName + " Returns", y: "Num of Years" }} />
                            }
                            {(nocrash == true) &&
                                <BarCandles dataset={regimeBarForNocrash} title={{ x: "Annual " + firstAssetName + " Returns", y: "Num of Years" }} />
                            }
                        </div>
                    </div>
                    <div className='mb-4'>
                        <div className='col-span-7'>
                            <div className='bg-white rounded-lg shadow-xl p-2'>
                                {(normal || reshuffle) &&
                                    <BarChart2
                                        min={minArray}
                                        max={maxArray}
                                        average={averageArray}
                                    />
                                }
                                {(nocrash) &&
                                    <BarChart2
                                        min={minArrayNocrash}
                                        max={maxArrayNocrash}
                                        average={averageArrayNocrash}
                                    />
                                }
                            </div>
                        </div>
                        <div className='col-span-5 mt-2'>
                            <div className='flex gap-2 flex-col sm:flex-row'>
                                {(normal || reshuffle) &&
                                    <button className="text-xs bg-white whitespace-nowrap hover:bg-gray-100  font-semibold py-2 px-2 border-gray-400 rounded-lg shadow-lg">
                                        {'AM: ' + am + '%'}
                                    </button>
                                }
                                {(normal || reshuffle) &&
                                    <button className="text-xs bg-white whitespace-nowrap hover:bg-gray-100  font-semibold py-2 px-2 border-gray-400 rounded-lg shadow-lg">
                                        {'GM: ' + gm + '%'}
                                    </button>
                                }
                                {(nocrash) &&
                                    <button className="text-xs bg-white whitespace-nowrap hover:bg-gray-100  font-semibold py-2 px-2 border-gray-400 rounded-lg shadow-lg">
                                        {'AM: ' + amNocrash + '%'}
                                    </button>
                                }
                                {(nocrash) &&
                                    <button className="text-xs bg-white whitespace-nowrap hover:bg-gray-100  font-semibold py-2 px-2 border-gray-400 rounded-lg shadow-lg">
                                        {'GM: ' + gmNocrash + '%'}
                                    </button>
                                }
                            </div>
                        </div>
                    </div>


                    <div className='mb-4'>
                        <div className='col-span-7'>
                            <div className='w-full bg-white rounded-lg shadow-xl p-2'>
                                {normal &&
                                    <BarChart3
                                        min={minAssetArray}
                                        max={maxAssetArray}
                                        average={averageAssetArray}
                                    />
                                }
                                {reshuffle &&
                                    <BarChart3
                                        min={minAssetReshuffleArray}
                                        max={maxAssetReshuffleArray}
                                        average={averageAssetReshuffleArray}
                                    />
                                }
                                {nocrash &&
                                    <BarChart3
                                        min={minAssetArrayNocrash}
                                        max={maxAssetArrayNocrash}
                                        average={averageAssetArrayNocrash}
                                    />
                                }
                            </div>
                        </div>

                        <div className='col-span-5 flex gap-2 mt-2'>
                            <div className='text-sm'>
                                <CatListDropdown
                                    id={"SecondAssetDropdown"}
                                    label={"Other Asset"}
                                    value={secondAsset}
                                    options={categoryother}
                                    onChange={handleSecondAsset}
                                />
                            </div>

                            <div className=''>
                                {normal &&
                                    <button className="text-xs bg-white whitespace-nowrap hover:bg-gray-100  font-semibold py-2 px-2 border-gray-400 rounded-lg shadow-lg">
                                        {'AM: ' + amMean + '%'}
                                    </button>
                                }
                                {reshuffle &&
                                    <button className="text-xs bg-white whitespace-nowrap hover:bg-gray-100  font-semibold py-2 px-2 border-gray-400 rounded-lg shadow-lg">
                                        {'AM: ' + amMeanReshuffle + '%'}
                                    </button>
                                }
                                {nocrash &&
                                    <button className="text-xs bg-white whitespace-nowrap hover:bg-gray-100  font-semibold py-2 px-2 border-gray-400 rounded-lg shadow-lg">
                                        {'AM: ' + amMeanNocrash + '%'}
                                    </button>
                                }
                            </div>
                        </div>

                    </div>

                    <div className='mb-4'>
                        <div className='col-span-7'>
                            <div className='flex flex-col mx-auto w-full bg-white rounded-lg shadow-xl p-2'>
                                {normal && <BarChart4
                                    min={mixMinAssetArray}
                                    max={mixMaxAssetArray}
                                    average={mixAvgAssetArray}
                                />
                                }
                                {reshuffle &&
                                    <BarChart4
                                        min={mixMinAssetReshuffleArray}
                                        max={mixMaxAssetReshuffleArray}
                                        average={mixAvgAssetReshuffleArray}
                                    />
                                }
                                {nocrash &&
                                    <BarChart4
                                        min={minMixNoCrash}
                                        max={maxMixNoCrash}
                                        average={avgMixNoCrash}
                                    />
                                }
                            </div>
                        </div>
                        <div className='col-span-5 mt-2'>
                            <div className='flex items-center gap-3 mb-2'>
                            <div className='w-full'>
                                <div className='flex w-full'>
                                    <Slider min={0} max={100} onChange={handleAllocSliderChange} disabledBin={false} value={alloc['secondAssetAlloc']} />
                                </div>
                            </div>

                            <div className=''>
                                <div className='flex mx-auto'>
                                    <div className='whitespace-nowrap text-sm'>{alloc['firstAssetAlloc']} / {alloc['secondAssetAlloc']} </div>
                                </div>
                            </div>
                            </div>
                            <div className='flex items-center gap-3'>
                            <div>
                            <div className='flex gap-2'>
                                {(normal) &&
                                    <button className="text-xs bg-white whitespace-nowrap hover:bg-gray-100  font-semibold py-2 px-2 border-gray-400 rounded-lg shadow-lg">
                                        {'AM: ' + mixAM + '%'}
                                    </button>
                                }
                                {reshuffle &&
                                    <button className="text-xs bg-white whitespace-nowrap hover:bg-gray-100  font-semibold py-2 px-2 border-gray-400 rounded-lg shadow-lg">
                                        {'AM: ' + mixAMReshuffle + '%'}
                                    </button>
                                }
                                {nocrash &&
                                    <button className="text-xs bg-white whitespace-nowrap hover:bg-gray-100  font-semibold py-2 px-2 border-gray-400 rounded-lg shadow-lg">
                                        {'AM: ' + amMixNoCrash + '%'}
                                    </button>
                                }
                                {(normal) &&
                                    <button className="text-xs bg-white whitespace-nowrap hover:bg-gray-100  font-semibold py-2 px-2 border-gray-400 rounded-lg shadow-lg">
                                        {'GM: ' + mixGM + '%'}
                                    </button>
                                }
                                {reshuffle &&
                                    <button className="text-xs bg-white whitespace-nowrap hover:bg-gray-100  font-semibold py-2 px-2 border-gray-400 rounded-lg shadow-lg">
                                        {'GM: ' + mixGMReshuffle + '%'}
                                    </button>
                                }
                                {nocrash &&
                                    <button className="text-xs bg-white whitespace-nowrap hover:bg-gray-100  font-semibold py-2 px-2 border-gray-400 rounded-lg shadow-lg">
                                        {'GM: ' + gmMixNoCrash + '%'}
                                    </button>
                                }
                            </div>
                            
                            </div>
                            <div>
                            <div className='text-center w-full mb-1'>
                                <p className='text-xs'>Difference vs SPX</p>
                            </div>
                            <div className='flex gap-2'>
                                {(normal) &&
                                    <button className="text-xs bg-white whitespace-nowrap hover:bg-gray-100  font-semibold py-2 px-2 border-gray-400 rounded-lg shadow-lg">
                                        {'AM: ' + parseFloat(mixAM - am).toFixed(1) + '%'}
                                    </button>}
                                {(normal) && <button className="text-xs bg-white whitespace-nowrap hover:bg-gray-100  font-semibold py-2 px-2 border-gray-400 rounded-lg shadow-lg">
                                    {'GM: ' + parseFloat(mixGM - gm).toFixed(1) + '%'}
                                </button>}
                                {reshuffle &&
                                    <button className="text-xs bg-white whitespace-nowrap hover:bg-gray-100  font-semibold py-2 px-2 border-gray-400 rounded-lg shadow-lg">
                                        {'AM: ' + parseFloat(mixAMReshuffle - am).toFixed(1) + '%'}
                                    </button>}
                                {reshuffle && <button className="text-xs bg-white whitespace-nowrap hover:bg-gray-100  font-semibold py-2 px-2 border-gray-400 rounded-lg shadow-lg">
                                    {'GM: ' + parseFloat(mixGMReshuffle - gm).toFixed(1) + '%'}
                                </button>}
                                {nocrash &&
                                    <button className="text-xs bg-white whitespace-nowrap hover:bg-gray-100  font-semibold py-2 px-2 border-gray-400 rounded-lg shadow-lg">
                                        {'AM: ' + parseFloat(amMixNoCrash - amNocrash).toFixed(1) + '%'}
                                    </button>}
                                {nocrash && <button className="text-xs bg-white whitespace-nowrap hover:bg-gray-100  font-semibold py-2 px-2 border-gray-400 rounded-lg shadow-lg">
                                    {'GM: ' + parseFloat(gmMixNoCrash - gmNocrash).toFixed(1) + '%'}
                                </button>}
                            </div>
                            <div className='grid grid-cols-2 text-center'>
                                <p className="text-xs p-1 whitespace-nowrap  font-semibold">
                                    COST
                                </p>
                                <p className="text-xs p-1 whitespace-nowrap  font-semibold">
                                    NET
                                </p>
                            </div>
                            </div>
                            </div>
                        </div>
                    </div>

                    <div className='mb-4'>
                        <div className='col-span-7'>
                        <div className='w-full bg-white rounded-lg shadow-xl p-2'>
                                    <Chart5
                                        fromYear={fromYear}
                                        toYear={toYear}
                                        firstAssetValues={firstAssetValues}
                                        secondAssetValues={secondAssetValues}
                                        yearsMatrix={yearMatrixData}
                                        yearsList={yearsList}
                                        percentile1Dropdown={percentile1Dropdown}
                                        percentile2Dropdown={percentile2Dropdown}

                                        //new mix logic props 
                                        baseAssetMatrixRetruns={baseAssetMatrixRetruns}
                                        otherAssetMatrixRetruns={otherAssetMatrixRetruns}
                                        reshuffle={reshuffle}
                                        normal={normal}
                                        otherAssetMatrixReshuffleReturns={otherAssetMatrixReshuffleReturns}
                                        nocrash={nocrash}
                                        baseAssetMatrixReturnsNoCrash={baseAssetMatrixReturnsNoCrash}
                                        otherAssetMatrixReturnsNoCrash={otherAssetMatrixReturnsNoCrash}
                                    />
                                </div>
                        </div>
                        <div className='col-span-5 mt-2'>
                        <div className='flex gap-2'>
                                <div className=''>
                                    <CatListDropdown
                                        id={'percentile_1'}
                                        value={percentile1Dropdown}
                                        options={percentileOptions0To100}
                                        onChange={() => handlePercentileDropdown(event, 'percentile_1')}
                                    />
                                </div>
                                <div className=''>
                                    <CatListDropdown
                                        id={"percentile_2"}
                                        value={percentile2Dropdown}
                                        options={percentileOptions5To95}
                                        onChange={() => handlePercentileDropdown(event, 'percentile_2')}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className='mb-4'>
                        <div className='col-span-7'>
                        <div className='flex flex-col mx-auto w-full bg-white rounded-lg shadow-xl p-2'>
                                    {(normal) && <CEAplot
                                        xAxis={(-(mixAM - am)).toFixed(1)}
                                        yAxis={(-(mixGM - gm)).toFixed(1)}
                                    />}
                                    {reshuffle &&
                                        <CEAplot
                                            xAxis={(-(mixAMReshuffle - am)).toFixed(1)}
                                            yAxis={(-(mixGMReshuffle - gm)).toFixed(1)}
                                        />
                                    }
                                    {nocrash &&
                                        <CEAplot
                                            xAxis={(-(amMixNoCrash - amNocrash)).toFixed(1)}
                                            yAxis={(-(gmMixNoCrash - gmNocrash)).toFixed(1)}
                                        />
                                    }
                                </div>
                        </div>
                    </div>

                </div>
                <div className='w-full'>
                    <div className='mb-4'>
                        <div className='col-span-7 bg-white rounded-lg shadow-xl p-2'>
                            {(normal == true || reshuffle == true) &&
                                <BarCandles dataset={regimeBar} title={{ x: "Annual " + firstAssetName + " Returns", y: "Num of Years" }} />
                            }
                            {(nocrash == true) &&
                                <BarCandles dataset={regimeBarForNocrash} title={{ x: "Annual " + firstAssetName + " Returns", y: "Num of Years" }} />
                            }
                        </div>
                    </div>
                    <div className='mb-4'>
                        <div className='col-span-7'>
                            <div className='bg-white rounded-lg shadow-xl p-2'>
                                {(normal || reshuffle) &&
                                    <BarChart2
                                        min={minArray}
                                        max={maxArray}
                                        average={averageArray}
                                    />
                                }
                                {(nocrash) &&
                                    <BarChart2
                                        min={minArrayNocrash}
                                        max={maxArrayNocrash}
                                        average={averageArrayNocrash}
                                    />
                                }
                            </div>
                        </div>
                        <div className='col-span-5 mt-2'>
                            <div className='flex gap-2 flex-col sm:flex-row'>
                                {(normal || reshuffle) &&
                                    <button className="text-xs bg-white whitespace-nowrap hover:bg-gray-100  font-semibold py-2 px-2 border-gray-400 rounded-lg shadow-lg">
                                        {'AM: ' + am + '%'}
                                    </button>
                                }
                                {(normal || reshuffle) &&
                                    <button className="text-xs bg-white whitespace-nowrap hover:bg-gray-100  font-semibold py-2 px-2 border-gray-400 rounded-lg shadow-lg">
                                        {'GM: ' + gm + '%'}
                                    </button>
                                }
                                {(nocrash) &&
                                    <button className="text-xs bg-white whitespace-nowrap hover:bg-gray-100  font-semibold py-2 px-2 border-gray-400 rounded-lg shadow-lg">
                                        {'AM: ' + amNocrash + '%'}
                                    </button>
                                }
                                {(nocrash) &&
                                    <button className="text-xs bg-white whitespace-nowrap hover:bg-gray-100  font-semibold py-2 px-2 border-gray-400 rounded-lg shadow-lg">
                                        {'GM: ' + gmNocrash + '%'}
                                    </button>
                                }
                            </div>
                        </div>
                    </div>


                    <div className='mb-4'>
                        <div className='col-span-7'>
                            <div className='w-full bg-white rounded-lg shadow-xl p-2'>
                                {normal &&
                                    <BarChart3
                                        min={minAssetArray}
                                        max={maxAssetArray}
                                        average={averageAssetArray}
                                    />
                                }
                                {reshuffle &&
                                    <BarChart3
                                        min={minAssetReshuffleArray}
                                        max={maxAssetReshuffleArray}
                                        average={averageAssetReshuffleArray}
                                    />
                                }
                                {nocrash &&
                                    <BarChart3
                                        min={minAssetArrayNocrash}
                                        max={maxAssetArrayNocrash}
                                        average={averageAssetArrayNocrash}
                                    />
                                }
                            </div>
                        </div>

                        <div className='col-span-5 flex gap-2 mt-2'>
                            <div className='text-sm'>
                                <CatListDropdown
                                    id={"SecondAssetDropdown"}
                                    label={"Other Asset"}
                                    value={secondAsset}
                                    options={categoryother}
                                    onChange={handleSecondAsset}
                                />
                            </div>

                            <div className=''>
                                {normal &&
                                    <button className="text-xs bg-white whitespace-nowrap hover:bg-gray-100  font-semibold py-2 px-2 border-gray-400 rounded-lg shadow-lg">
                                        {'AM: ' + amMean + '%'}
                                    </button>
                                }
                                {reshuffle &&
                                    <button className="text-xs bg-white whitespace-nowrap hover:bg-gray-100  font-semibold py-2 px-2 border-gray-400 rounded-lg shadow-lg">
                                        {'AM: ' + amMeanReshuffle + '%'}
                                    </button>
                                }
                                {nocrash &&
                                    <button className="text-xs bg-white whitespace-nowrap hover:bg-gray-100  font-semibold py-2 px-2 border-gray-400 rounded-lg shadow-lg">
                                        {'AM: ' + amMeanNocrash + '%'}
                                    </button>
                                }
                            </div>
                        </div>

                    </div>

                    <div className='mb-4'>
                        <div className='col-span-7'>
                            <div className='flex flex-col mx-auto w-full bg-white rounded-lg shadow-xl p-2'>
                                {normal && <BarChart4
                                    min={mixMinAssetArray}
                                    max={mixMaxAssetArray}
                                    average={mixAvgAssetArray}
                                />
                                }
                                {reshuffle &&
                                    <BarChart4
                                        min={mixMinAssetReshuffleArray}
                                        max={mixMaxAssetReshuffleArray}
                                        average={mixAvgAssetReshuffleArray}
                                    />
                                }
                                {nocrash &&
                                    <BarChart4
                                        min={minMixNoCrash}
                                        max={maxMixNoCrash}
                                        average={avgMixNoCrash}
                                    />
                                }
                            </div>
                        </div>
                        <div className='col-span-5 mt-2'>
                            <div className='flex items-center gap-3 mb-2'>
                            <div className='w-full'>
                                <div className='flex w-full'>
                                    <Slider min={0} max={100} onChange={handleAllocSliderChange} disabledBin={false} value={alloc['secondAssetAlloc']} />
                                </div>
                            </div>

                            <div className=''>
                                <div className='flex mx-auto'>
                                    <div className='whitespace-nowrap text-sm'>{alloc['firstAssetAlloc']} / {alloc['secondAssetAlloc']} </div>
                                </div>
                            </div>
                            </div>
                            <div className='flex items-center gap-3'>
                            <div>
                            <div className='flex gap-2'>
                                {(normal) &&
                                    <button className="text-xs bg-white whitespace-nowrap hover:bg-gray-100  font-semibold py-2 px-2 border-gray-400 rounded-lg shadow-lg">
                                        {'AM: ' + mixAM + '%'}
                                    </button>
                                }
                                {reshuffle &&
                                    <button className="text-xs bg-white whitespace-nowrap hover:bg-gray-100  font-semibold py-2 px-2 border-gray-400 rounded-lg shadow-lg">
                                        {'AM: ' + mixAMReshuffle + '%'}
                                    </button>
                                }
                                {nocrash &&
                                    <button className="text-xs bg-white whitespace-nowrap hover:bg-gray-100  font-semibold py-2 px-2 border-gray-400 rounded-lg shadow-lg">
                                        {'AM: ' + amMixNoCrash + '%'}
                                    </button>
                                }
                                {(normal) &&
                                    <button className="text-xs bg-white whitespace-nowrap hover:bg-gray-100  font-semibold py-2 px-2 border-gray-400 rounded-lg shadow-lg">
                                        {'GM: ' + mixGM + '%'}
                                    </button>
                                }
                                {reshuffle &&
                                    <button className="text-xs bg-white whitespace-nowrap hover:bg-gray-100  font-semibold py-2 px-2 border-gray-400 rounded-lg shadow-lg">
                                        {'GM: ' + mixGMReshuffle + '%'}
                                    </button>
                                }
                                {nocrash &&
                                    <button className="text-xs bg-white whitespace-nowrap hover:bg-gray-100  font-semibold py-2 px-2 border-gray-400 rounded-lg shadow-lg">
                                        {'GM: ' + gmMixNoCrash + '%'}
                                    </button>
                                }
                            </div>
                            
                            </div>
                            <div>
                            <div className='text-center w-full mb-1'>
                                <p className='text-xs'>Difference vs SPX</p>
                            </div>
                            <div className='flex gap-2'>
                                {(normal) &&
                                    <button className="text-xs bg-white whitespace-nowrap hover:bg-gray-100  font-semibold py-2 px-2 border-gray-400 rounded-lg shadow-lg">
                                        {'AM: ' + parseFloat(mixAM - am).toFixed(1) + '%'}
                                    </button>}
                                {(normal) && <button className="text-xs bg-white whitespace-nowrap hover:bg-gray-100  font-semibold py-2 px-2 border-gray-400 rounded-lg shadow-lg">
                                    {'GM: ' + parseFloat(mixGM - gm).toFixed(1) + '%'}
                                </button>}
                                {reshuffle &&
                                    <button className="text-xs bg-white whitespace-nowrap hover:bg-gray-100  font-semibold py-2 px-2 border-gray-400 rounded-lg shadow-lg">
                                        {'AM: ' + parseFloat(mixAMReshuffle - am).toFixed(1) + '%'}
                                    </button>}
                                {reshuffle && <button className="text-xs bg-white whitespace-nowrap hover:bg-gray-100  font-semibold py-2 px-2 border-gray-400 rounded-lg shadow-lg">
                                    {'GM: ' + parseFloat(mixGMReshuffle - gm).toFixed(1) + '%'}
                                </button>}
                                {nocrash &&
                                    <button className="text-xs bg-white whitespace-nowrap hover:bg-gray-100  font-semibold py-2 px-2 border-gray-400 rounded-lg shadow-lg">
                                        {'AM: ' + parseFloat(amMixNoCrash - amNocrash).toFixed(1) + '%'}
                                    </button>}
                                {nocrash && <button className="text-xs bg-white whitespace-nowrap hover:bg-gray-100  font-semibold py-2 px-2 border-gray-400 rounded-lg shadow-lg">
                                    {'GM: ' + parseFloat(gmMixNoCrash - gmNocrash).toFixed(1) + '%'}
                                </button>}
                            </div>
                            <div className='grid grid-cols-2 text-center'>
                                <p className="text-xs p-1 whitespace-nowrap  font-semibold">
                                    COST
                                </p>
                                <p className="text-xs p-1 whitespace-nowrap  font-semibold">
                                    NET
                                </p>
                            </div>
                            </div>
                            </div>
                        </div>
                    </div>

                    <div className='mb-4'>
                        <div className='col-span-7'>
                        <div className='w-full bg-white rounded-lg shadow-xl p-2'>
                                    <Chart5
                                        fromYear={fromYear}
                                        toYear={toYear}
                                        firstAssetValues={firstAssetValues}
                                        secondAssetValues={secondAssetValues}
                                        yearsMatrix={yearMatrixData}
                                        yearsList={yearsList}
                                        percentile1Dropdown={percentile1Dropdown}
                                        percentile2Dropdown={percentile2Dropdown}

                                        //new mix logic props 
                                        baseAssetMatrixRetruns={baseAssetMatrixRetruns}
                                        otherAssetMatrixRetruns={otherAssetMatrixRetruns}
                                        reshuffle={reshuffle}
                                        normal={normal}
                                        otherAssetMatrixReshuffleReturns={otherAssetMatrixReshuffleReturns}
                                        nocrash={nocrash}
                                        baseAssetMatrixReturnsNoCrash={baseAssetMatrixReturnsNoCrash}
                                        otherAssetMatrixReturnsNoCrash={otherAssetMatrixReturnsNoCrash}
                                    />
                                </div>
                        </div>
                        <div className='col-span-5 mt-2'>
                        <div className='flex gap-2'>
                                <div className=''>
                                    <CatListDropdown
                                        id={'percentile_1'}
                                        value={percentile1Dropdown}
                                        options={percentileOptions0To100}
                                        onChange={() => handlePercentileDropdown(event, 'percentile_1')}
                                    />
                                </div>
                                <div className=''>
                                    <CatListDropdown
                                        id={"percentile_2"}
                                        value={percentile2Dropdown}
                                        options={percentileOptions5To95}
                                        onChange={() => handlePercentileDropdown(event, 'percentile_2')}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className='mb-4'>
                        <div className='col-span-7'>
                        <div className='flex flex-col mx-auto w-full bg-white rounded-lg shadow-xl p-2'>
                                    {(normal) && <CEAplot
                                        xAxis={(-(mixAM - am)).toFixed(1)}
                                        yAxis={(-(mixGM - gm)).toFixed(1)}
                                    />}
                                    {reshuffle &&
                                        <CEAplot
                                            xAxis={(-(mixAMReshuffle - am)).toFixed(1)}
                                            yAxis={(-(mixGMReshuffle - gm)).toFixed(1)}
                                        />
                                    }
                                    {nocrash &&
                                        <CEAplot
                                            xAxis={(-(amMixNoCrash - amNocrash)).toFixed(1)}
                                            yAxis={(-(gmMixNoCrash - gmNocrash)).toFixed(1)}
                                        />
                                    }
                                </div>
                        </div>
                    </div>

                </div>
                <div className='w-full'>
                    <div className='mb-4'>
                        <div className='col-span-7 bg-white rounded-lg shadow-xl p-2'>
                            {(normal == true || reshuffle == true) &&
                                <BarCandles dataset={regimeBar} title={{ x: "Annual " + firstAssetName + " Returns", y: "Num of Years" }} />
                            }
                            {(nocrash == true) &&
                                <BarCandles dataset={regimeBarForNocrash} title={{ x: "Annual " + firstAssetName + " Returns", y: "Num of Years" }} />
                            }
                        </div>
                    </div>
                    <div className='mb-4'>
                        <div className='col-span-7'>
                            <div className='bg-white rounded-lg shadow-xl p-2'>
                                {(normal || reshuffle) &&
                                    <BarChart2
                                        min={minArray}
                                        max={maxArray}
                                        average={averageArray}
                                    />
                                }
                                {(nocrash) &&
                                    <BarChart2
                                        min={minArrayNocrash}
                                        max={maxArrayNocrash}
                                        average={averageArrayNocrash}
                                    />
                                }
                            </div>
                        </div>
                        <div className='col-span-5 mt-2'>
                            <div className='flex gap-2 flex-col sm:flex-row'>
                                {(normal || reshuffle) &&
                                    <button className="text-xs bg-white whitespace-nowrap hover:bg-gray-100  font-semibold py-2 px-2 border-gray-400 rounded-lg shadow-lg">
                                        {'AM: ' + am + '%'}
                                    </button>
                                }
                                {(normal || reshuffle) &&
                                    <button className="text-xs bg-white whitespace-nowrap hover:bg-gray-100  font-semibold py-2 px-2 border-gray-400 rounded-lg shadow-lg">
                                        {'GM: ' + gm + '%'}
                                    </button>
                                }
                                {(nocrash) &&
                                    <button className="text-xs bg-white whitespace-nowrap hover:bg-gray-100  font-semibold py-2 px-2 border-gray-400 rounded-lg shadow-lg">
                                        {'AM: ' + amNocrash + '%'}
                                    </button>
                                }
                                {(nocrash) &&
                                    <button className="text-xs bg-white whitespace-nowrap hover:bg-gray-100  font-semibold py-2 px-2 border-gray-400 rounded-lg shadow-lg">
                                        {'GM: ' + gmNocrash + '%'}
                                    </button>
                                }
                            </div>
                        </div>
                    </div>


                    <div className='mb-4'>
                        <div className='col-span-7'>
                            <div className='w-full bg-white rounded-lg shadow-xl p-2'>
                                {normal &&
                                    <BarChart3
                                        min={minAssetArray}
                                        max={maxAssetArray}
                                        average={averageAssetArray}
                                    />
                                }
                                {reshuffle &&
                                    <BarChart3
                                        min={minAssetReshuffleArray}
                                        max={maxAssetReshuffleArray}
                                        average={averageAssetReshuffleArray}
                                    />
                                }
                                {nocrash &&
                                    <BarChart3
                                        min={minAssetArrayNocrash}
                                        max={maxAssetArrayNocrash}
                                        average={averageAssetArrayNocrash}
                                    />
                                }
                            </div>
                        </div>

                        <div className='col-span-5 flex gap-2 mt-2'>
                            <div className='text-sm'>
                                <CatListDropdown
                                    id={"SecondAssetDropdown"}
                                    label={"Other Asset"}
                                    value={secondAsset}
                                    options={categoryother}
                                    onChange={handleSecondAsset}
                                />
                            </div>

                            <div className=''>
                                {normal &&
                                    <button className="text-xs bg-white whitespace-nowrap hover:bg-gray-100  font-semibold py-2 px-2 border-gray-400 rounded-lg shadow-lg">
                                        {'AM: ' + amMean + '%'}
                                    </button>
                                }
                                {reshuffle &&
                                    <button className="text-xs bg-white whitespace-nowrap hover:bg-gray-100  font-semibold py-2 px-2 border-gray-400 rounded-lg shadow-lg">
                                        {'AM: ' + amMeanReshuffle + '%'}
                                    </button>
                                }
                                {nocrash &&
                                    <button className="text-xs bg-white whitespace-nowrap hover:bg-gray-100  font-semibold py-2 px-2 border-gray-400 rounded-lg shadow-lg">
                                        {'AM: ' + amMeanNocrash + '%'}
                                    </button>
                                }
                            </div>
                        </div>

                    </div>

                    <div className='mb-4'>
                        <div className='col-span-7'>
                            <div className='flex flex-col mx-auto w-full bg-white rounded-lg shadow-xl p-2'>
                                {normal && <BarChart4
                                    min={mixMinAssetArray}
                                    max={mixMaxAssetArray}
                                    average={mixAvgAssetArray}
                                />
                                }
                                {reshuffle &&
                                    <BarChart4
                                        min={mixMinAssetReshuffleArray}
                                        max={mixMaxAssetReshuffleArray}
                                        average={mixAvgAssetReshuffleArray}
                                    />
                                }
                                {nocrash &&
                                    <BarChart4
                                        min={minMixNoCrash}
                                        max={maxMixNoCrash}
                                        average={avgMixNoCrash}
                                    />
                                }
                            </div>
                        </div>
                        <div className='col-span-5 mt-2'>
                            <div className='flex items-center gap-3 mb-2'>
                            <div className='w-full'>
                                <div className='flex w-full'>
                                    <Slider min={0} max={100} onChange={handleAllocSliderChange} disabledBin={false} value={alloc['secondAssetAlloc']} />
                                </div>
                            </div>

                            <div className=''>
                                <div className='flex mx-auto'>
                                    <div className='whitespace-nowrap text-sm'>{alloc['firstAssetAlloc']} / {alloc['secondAssetAlloc']} </div>
                                </div>
                            </div>
                            </div>
                            <div className='flex items-center gap-3'>
                            <div>
                            <div className='flex gap-2'>
                                {(normal) &&
                                    <button className="text-xs bg-white whitespace-nowrap hover:bg-gray-100  font-semibold py-2 px-2 border-gray-400 rounded-lg shadow-lg">
                                        {'AM: ' + mixAM + '%'}
                                    </button>
                                }
                                {reshuffle &&
                                    <button className="text-xs bg-white whitespace-nowrap hover:bg-gray-100  font-semibold py-2 px-2 border-gray-400 rounded-lg shadow-lg">
                                        {'AM: ' + mixAMReshuffle + '%'}
                                    </button>
                                }
                                {nocrash &&
                                    <button className="text-xs bg-white whitespace-nowrap hover:bg-gray-100  font-semibold py-2 px-2 border-gray-400 rounded-lg shadow-lg">
                                        {'AM: ' + amMixNoCrash + '%'}
                                    </button>
                                }
                                {(normal) &&
                                    <button className="text-xs bg-white whitespace-nowrap hover:bg-gray-100  font-semibold py-2 px-2 border-gray-400 rounded-lg shadow-lg">
                                        {'GM: ' + mixGM + '%'}
                                    </button>
                                }
                                {reshuffle &&
                                    <button className="text-xs bg-white whitespace-nowrap hover:bg-gray-100  font-semibold py-2 px-2 border-gray-400 rounded-lg shadow-lg">
                                        {'GM: ' + mixGMReshuffle + '%'}
                                    </button>
                                }
                                {nocrash &&
                                    <button className="text-xs bg-white whitespace-nowrap hover:bg-gray-100  font-semibold py-2 px-2 border-gray-400 rounded-lg shadow-lg">
                                        {'GM: ' + gmMixNoCrash + '%'}
                                    </button>
                                }
                            </div>
                            
                            </div>
                            <div>
                            <div className='text-center w-full mb-1'>
                                <p className='text-xs'>Difference vs SPX</p>
                            </div>
                            <div className='flex gap-2'>
                                {(normal) &&
                                    <button className="text-xs bg-white whitespace-nowrap hover:bg-gray-100  font-semibold py-2 px-2 border-gray-400 rounded-lg shadow-lg">
                                        {'AM: ' + parseFloat(mixAM - am).toFixed(1) + '%'}
                                    </button>}
                                {(normal) && <button className="text-xs bg-white whitespace-nowrap hover:bg-gray-100  font-semibold py-2 px-2 border-gray-400 rounded-lg shadow-lg">
                                    {'GM: ' + parseFloat(mixGM - gm).toFixed(1) + '%'}
                                </button>}
                                {reshuffle &&
                                    <button className="text-xs bg-white whitespace-nowrap hover:bg-gray-100  font-semibold py-2 px-2 border-gray-400 rounded-lg shadow-lg">
                                        {'AM: ' + parseFloat(mixAMReshuffle - am).toFixed(1) + '%'}
                                    </button>}
                                {reshuffle && <button className="text-xs bg-white whitespace-nowrap hover:bg-gray-100  font-semibold py-2 px-2 border-gray-400 rounded-lg shadow-lg">
                                    {'GM: ' + parseFloat(mixGMReshuffle - gm).toFixed(1) + '%'}
                                </button>}
                                {nocrash &&
                                    <button className="text-xs bg-white whitespace-nowrap hover:bg-gray-100  font-semibold py-2 px-2 border-gray-400 rounded-lg shadow-lg">
                                        {'AM: ' + parseFloat(amMixNoCrash - amNocrash).toFixed(1) + '%'}
                                    </button>}
                                {nocrash && <button className="text-xs bg-white whitespace-nowrap hover:bg-gray-100  font-semibold py-2 px-2 border-gray-400 rounded-lg shadow-lg">
                                    {'GM: ' + parseFloat(gmMixNoCrash - gmNocrash).toFixed(1) + '%'}
                                </button>}
                            </div>
                            <div className='grid grid-cols-2 text-center'>
                                <p className="text-xs p-1 whitespace-nowrap  font-semibold">
                                    COST
                                </p>
                                <p className="text-xs p-1 whitespace-nowrap  font-semibold">
                                    NET
                                </p>
                            </div>
                            </div>
                            </div>
                        </div>
                    </div>

                    <div className='mb-4'>
                        <div className='col-span-7'>
                        <div className='w-full bg-white rounded-lg shadow-xl p-2'>
                                    <Chart5
                                        fromYear={fromYear}
                                        toYear={toYear}
                                        firstAssetValues={firstAssetValues}
                                        secondAssetValues={secondAssetValues}
                                        yearsMatrix={yearMatrixData}
                                        yearsList={yearsList}
                                        percentile1Dropdown={percentile1Dropdown}
                                        percentile2Dropdown={percentile2Dropdown}

                                        //new mix logic props 
                                        baseAssetMatrixRetruns={baseAssetMatrixRetruns}
                                        otherAssetMatrixRetruns={otherAssetMatrixRetruns}
                                        reshuffle={reshuffle}
                                        normal={normal}
                                        otherAssetMatrixReshuffleReturns={otherAssetMatrixReshuffleReturns}
                                        nocrash={nocrash}
                                        baseAssetMatrixReturnsNoCrash={baseAssetMatrixReturnsNoCrash}
                                        otherAssetMatrixReturnsNoCrash={otherAssetMatrixReturnsNoCrash}
                                    />
                                </div>
                        </div>
                        <div className='col-span-5 mt-2'>
                        <div className='flex gap-2'>
                                <div className=''>
                                    <CatListDropdown
                                        id={'percentile_1'}
                                        value={percentile1Dropdown}
                                        options={percentileOptions0To100}
                                        onChange={() => handlePercentileDropdown(event, 'percentile_1')}
                                    />
                                </div>
                                <div className=''>
                                    <CatListDropdown
                                        id={"percentile_2"}
                                        value={percentile2Dropdown}
                                        options={percentileOptions5To95}
                                        onChange={() => handlePercentileDropdown(event, 'percentile_2')}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className='mb-4'>
                        <div className='col-span-7'>
                        <div className='flex flex-col mx-auto w-full bg-white rounded-lg shadow-xl p-2'>
                                    {(normal) && <CEAplot
                                        xAxis={(-(mixAM - am)).toFixed(1)}
                                        yAxis={(-(mixGM - gm)).toFixed(1)}
                                    />}
                                    {reshuffle &&
                                        <CEAplot
                                            xAxis={(-(mixAMReshuffle - am)).toFixed(1)}
                                            yAxis={(-(mixGMReshuffle - gm)).toFixed(1)}
                                        />
                                    }
                                    {nocrash &&
                                        <CEAplot
                                            xAxis={(-(amMixNoCrash - amNocrash)).toFixed(1)}
                                            yAxis={(-(gmMixNoCrash - gmNocrash)).toFixed(1)}
                                        />
                                    }
                                </div>
                        </div>
                    </div>

                </div>
                <div className='w-full'>
                    <div className='mb-4'>
                        <div className='col-span-7 bg-white rounded-lg shadow-xl p-2'>
                            {(normal == true || reshuffle == true) &&
                                <BarCandles dataset={regimeBar} title={{ x: "Annual " + firstAssetName + " Returns", y: "Num of Years" }} />
                            }
                            {(nocrash == true) &&
                                <BarCandles dataset={regimeBarForNocrash} title={{ x: "Annual " + firstAssetName + " Returns", y: "Num of Years" }} />
                            }
                        </div>
                    </div>
                    <div className='mb-4'>
                        <div className='col-span-7'>
                            <div className='bg-white rounded-lg shadow-xl p-2'>
                                {(normal || reshuffle) &&
                                    <BarChart2
                                        min={minArray}
                                        max={maxArray}
                                        average={averageArray}
                                    />
                                }
                                {(nocrash) &&
                                    <BarChart2
                                        min={minArrayNocrash}
                                        max={maxArrayNocrash}
                                        average={averageArrayNocrash}
                                    />
                                }
                            </div>
                        </div>
                        <div className='col-span-5 mt-2'>
                            <div className='flex gap-2 flex-col sm:flex-row'>
                                {(normal || reshuffle) &&
                                    <button className="text-xs bg-white whitespace-nowrap hover:bg-gray-100  font-semibold py-2 px-2 border-gray-400 rounded-lg shadow-lg">
                                        {'AM: ' + am + '%'}
                                    </button>
                                }
                                {(normal || reshuffle) &&
                                    <button className="text-xs bg-white whitespace-nowrap hover:bg-gray-100  font-semibold py-2 px-2 border-gray-400 rounded-lg shadow-lg">
                                        {'GM: ' + gm + '%'}
                                    </button>
                                }
                                {(nocrash) &&
                                    <button className="text-xs bg-white whitespace-nowrap hover:bg-gray-100  font-semibold py-2 px-2 border-gray-400 rounded-lg shadow-lg">
                                        {'AM: ' + amNocrash + '%'}
                                    </button>
                                }
                                {(nocrash) &&
                                    <button className="text-xs bg-white whitespace-nowrap hover:bg-gray-100  font-semibold py-2 px-2 border-gray-400 rounded-lg shadow-lg">
                                        {'GM: ' + gmNocrash + '%'}
                                    </button>
                                }
                            </div>
                        </div>
                    </div>


                    <div className='mb-4'>
                        <div className='col-span-7'>
                            <div className='w-full bg-white rounded-lg shadow-xl p-2'>
                                {normal &&
                                    <BarChart3
                                        min={minAssetArray}
                                        max={maxAssetArray}
                                        average={averageAssetArray}
                                    />
                                }
                                {reshuffle &&
                                    <BarChart3
                                        min={minAssetReshuffleArray}
                                        max={maxAssetReshuffleArray}
                                        average={averageAssetReshuffleArray}
                                    />
                                }
                                {nocrash &&
                                    <BarChart3
                                        min={minAssetArrayNocrash}
                                        max={maxAssetArrayNocrash}
                                        average={averageAssetArrayNocrash}
                                    />
                                }
                            </div>
                        </div>

                        <div className='col-span-5 flex gap-2 mt-2'>
                            <div className='text-sm'>
                                <CatListDropdown
                                    id={"SecondAssetDropdown"}
                                    label={"Other Asset"}
                                    value={secondAsset}
                                    options={categoryother}
                                    onChange={handleSecondAsset}
                                />
                            </div>

                            <div className=''>
                                {normal &&
                                    <button className="text-xs bg-white whitespace-nowrap hover:bg-gray-100  font-semibold py-2 px-2 border-gray-400 rounded-lg shadow-lg">
                                        {'AM: ' + amMean + '%'}
                                    </button>
                                }
                                {reshuffle &&
                                    <button className="text-xs bg-white whitespace-nowrap hover:bg-gray-100  font-semibold py-2 px-2 border-gray-400 rounded-lg shadow-lg">
                                        {'AM: ' + amMeanReshuffle + '%'}
                                    </button>
                                }
                                {nocrash &&
                                    <button className="text-xs bg-white whitespace-nowrap hover:bg-gray-100  font-semibold py-2 px-2 border-gray-400 rounded-lg shadow-lg">
                                        {'AM: ' + amMeanNocrash + '%'}
                                    </button>
                                }
                            </div>
                        </div>

                    </div>

                    <div className='mb-4'>
                        <div className='col-span-7'>
                            <div className='flex flex-col mx-auto w-full bg-white rounded-lg shadow-xl p-2'>
                                {normal && <BarChart4
                                    min={mixMinAssetArray}
                                    max={mixMaxAssetArray}
                                    average={mixAvgAssetArray}
                                />
                                }
                                {reshuffle &&
                                    <BarChart4
                                        min={mixMinAssetReshuffleArray}
                                        max={mixMaxAssetReshuffleArray}
                                        average={mixAvgAssetReshuffleArray}
                                    />
                                }
                                {nocrash &&
                                    <BarChart4
                                        min={minMixNoCrash}
                                        max={maxMixNoCrash}
                                        average={avgMixNoCrash}
                                    />
                                }
                            </div>
                        </div>
                        <div className='col-span-5 mt-2'>
                            <div className='flex items-center gap-3 mb-2'>
                            <div className='w-full'>
                                <div className='flex w-full'>
                                    <Slider min={0} max={100} onChange={handleAllocSliderChange} disabledBin={false} value={alloc['secondAssetAlloc']} />
                                </div>
                            </div>

                            <div className=''>
                                <div className='flex mx-auto'>
                                    <div className='whitespace-nowrap text-sm'>{alloc['firstAssetAlloc']} / {alloc['secondAssetAlloc']} </div>
                                </div>
                            </div>
                            </div>
                            <div className='flex items-center gap-3'>
                            <div>
                            <div className='flex gap-2'>
                                {(normal) &&
                                    <button className="text-xs bg-white whitespace-nowrap hover:bg-gray-100  font-semibold py-2 px-2 border-gray-400 rounded-lg shadow-lg">
                                        {'AM: ' + mixAM + '%'}
                                    </button>
                                }
                                {reshuffle &&
                                    <button className="text-xs bg-white whitespace-nowrap hover:bg-gray-100  font-semibold py-2 px-2 border-gray-400 rounded-lg shadow-lg">
                                        {'AM: ' + mixAMReshuffle + '%'}
                                    </button>
                                }
                                {nocrash &&
                                    <button className="text-xs bg-white whitespace-nowrap hover:bg-gray-100  font-semibold py-2 px-2 border-gray-400 rounded-lg shadow-lg">
                                        {'AM: ' + amMixNoCrash + '%'}
                                    </button>
                                }
                                {(normal) &&
                                    <button className="text-xs bg-white whitespace-nowrap hover:bg-gray-100  font-semibold py-2 px-2 border-gray-400 rounded-lg shadow-lg">
                                        {'GM: ' + mixGM + '%'}
                                    </button>
                                }
                                {reshuffle &&
                                    <button className="text-xs bg-white whitespace-nowrap hover:bg-gray-100  font-semibold py-2 px-2 border-gray-400 rounded-lg shadow-lg">
                                        {'GM: ' + mixGMReshuffle + '%'}
                                    </button>
                                }
                                {nocrash &&
                                    <button className="text-xs bg-white whitespace-nowrap hover:bg-gray-100  font-semibold py-2 px-2 border-gray-400 rounded-lg shadow-lg">
                                        {'GM: ' + gmMixNoCrash + '%'}
                                    </button>
                                }
                            </div>
                            
                            </div>
                            <div>
                            <div className='text-center w-full mb-1'>
                                <p className='text-xs'>Difference vs SPX</p>
                            </div>
                            <div className='flex gap-2'>
                                {(normal) &&
                                    <button className="text-xs bg-white whitespace-nowrap hover:bg-gray-100  font-semibold py-2 px-2 border-gray-400 rounded-lg shadow-lg">
                                        {'AM: ' + parseFloat(mixAM - am).toFixed(1) + '%'}
                                    </button>}
                                {(normal) && <button className="text-xs bg-white whitespace-nowrap hover:bg-gray-100  font-semibold py-2 px-2 border-gray-400 rounded-lg shadow-lg">
                                    {'GM: ' + parseFloat(mixGM - gm).toFixed(1) + '%'}
                                </button>}
                                {reshuffle &&
                                    <button className="text-xs bg-white whitespace-nowrap hover:bg-gray-100  font-semibold py-2 px-2 border-gray-400 rounded-lg shadow-lg">
                                        {'AM: ' + parseFloat(mixAMReshuffle - am).toFixed(1) + '%'}
                                    </button>}
                                {reshuffle && <button className="text-xs bg-white whitespace-nowrap hover:bg-gray-100  font-semibold py-2 px-2 border-gray-400 rounded-lg shadow-lg">
                                    {'GM: ' + parseFloat(mixGMReshuffle - gm).toFixed(1) + '%'}
                                </button>}
                                {nocrash &&
                                    <button className="text-xs bg-white whitespace-nowrap hover:bg-gray-100  font-semibold py-2 px-2 border-gray-400 rounded-lg shadow-lg">
                                        {'AM: ' + parseFloat(amMixNoCrash - amNocrash).toFixed(1) + '%'}
                                    </button>}
                                {nocrash && <button className="text-xs bg-white whitespace-nowrap hover:bg-gray-100  font-semibold py-2 px-2 border-gray-400 rounded-lg shadow-lg">
                                    {'GM: ' + parseFloat(gmMixNoCrash - gmNocrash).toFixed(1) + '%'}
                                </button>}
                            </div>
                            <div className='grid grid-cols-2 text-center'>
                                <p className="text-xs p-1 whitespace-nowrap  font-semibold">
                                    COST
                                </p>
                                <p className="text-xs p-1 whitespace-nowrap  font-semibold">
                                    NET
                                </p>
                            </div>
                            </div>
                            </div>
                        </div>
                    </div>

                    <div className='mb-4'>
                        <div className='col-span-7'>
                        <div className='w-full bg-white rounded-lg shadow-xl p-2'>
                                    <Chart5
                                        fromYear={fromYear}
                                        toYear={toYear}
                                        firstAssetValues={firstAssetValues}
                                        secondAssetValues={secondAssetValues}
                                        yearsMatrix={yearMatrixData}
                                        yearsList={yearsList}
                                        percentile1Dropdown={percentile1Dropdown}
                                        percentile2Dropdown={percentile2Dropdown}

                                        //new mix logic props 
                                        baseAssetMatrixRetruns={baseAssetMatrixRetruns}
                                        otherAssetMatrixRetruns={otherAssetMatrixRetruns}
                                        reshuffle={reshuffle}
                                        normal={normal}
                                        otherAssetMatrixReshuffleReturns={otherAssetMatrixReshuffleReturns}
                                        nocrash={nocrash}
                                        baseAssetMatrixReturnsNoCrash={baseAssetMatrixReturnsNoCrash}
                                        otherAssetMatrixReturnsNoCrash={otherAssetMatrixReturnsNoCrash}
                                    />
                                </div>
                        </div>
                        <div className='col-span-5 mt-2'>
                        <div className='flex gap-2'>
                                <div className=''>
                                    <CatListDropdown
                                        id={'percentile_1'}
                                        value={percentile1Dropdown}
                                        options={percentileOptions0To100}
                                        onChange={() => handlePercentileDropdown(event, 'percentile_1')}
                                    />
                                </div>
                                <div className=''>
                                    <CatListDropdown
                                        id={"percentile_2"}
                                        value={percentile2Dropdown}
                                        options={percentileOptions5To95}
                                        onChange={() => handlePercentileDropdown(event, 'percentile_2')}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className='mb-4'>
                        <div className='col-span-7'>
                        <div className='flex flex-col mx-auto w-full bg-white rounded-lg shadow-xl p-2'>
                                    {(normal) && <CEAplot
                                        xAxis={(-(mixAM - am)).toFixed(1)}
                                        yAxis={(-(mixGM - gm)).toFixed(1)}
                                    />}
                                    {reshuffle &&
                                        <CEAplot
                                            xAxis={(-(mixAMReshuffle - am)).toFixed(1)}
                                            yAxis={(-(mixGMReshuffle - gm)).toFixed(1)}
                                        />
                                    }
                                    {nocrash &&
                                        <CEAplot
                                            xAxis={(-(amMixNoCrash - amNocrash)).toFixed(1)}
                                            yAxis={(-(gmMixNoCrash - gmNocrash)).toFixed(1)}
                                        />
                                    }
                                </div>
                        </div>
                    </div>

                </div> */}
                {assets.length !== 5 && <div className='grid grid-col-1 gap-8 h-10 w-10 rounded-xl'>
                    <button className='border-none bg-transparent rounded-xl'
                        onClick={handleAddAsset}
                    >
                        <svg width="38" height="38" viewBox="0 0 38 38" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <circle cx="19" cy="19" r="19" fill="#69A4CE" />
                            <rect x="18" y="10" width="3" height="18" fill="white" />
                            <rect x="10" y="21" width="3" height="19" transform="rotate(-90 10 21)" fill="white" />
                        </svg>
                    </button>
                </div>}
            </div>

            <div className='flex flex-row max-w-5xl mx-auto'>
                <div className='flex flex-col mx-auto w-full bg-white rounded-lg shadow-xl p-2'>
                    {(normal) && <CEAplot
                        // xAxis={(-(mixAM - am)).toFixed(1)}
                        // yAxis={(-(mixGM - gm)).toFixed(1)}
                        isMulti={true}
                        coordinates={mulitiCEAplotCoordinates(mixAM, mixGM, am, gm)}
                        xAxis={(-(mixAM - am)).toFixed(1)}
                        yAxis={(-(mixGM - gm)).toFixed(1)}
                    />}
                    {reshuffle &&
                        <CEAplot
                            isMulti={true}
                            coordinates={mulitiCEAplotCoordinates(mixAMReshuffle, mixGMReshuffle, am, gm)}
                            xAxis={(-(mixAMReshuffle - am)).toFixed(1)}
                            yAxis={(-(mixGMReshuffle - gm)).toFixed(1)}
                        />
                    }
                    {nocrash &&
                        <CEAplot
                            isMulti={true}
                            coordinates={mulitiCEAplotCoordinates(amMixNoCrash, gmMixNoCrash, amNocrash, gmNocrash)}
                            xAxis={(-(amMixNoCrash - amNocrash)).toFixed(1)}
                            yAxis={(-(gmMixNoCrash - gmNocrash)).toFixed(1)}
                        />
                    }
                </div>
            </div>
        </div >
    </>
    )
}
export default MultiAsset;