import React, { useEffect, useState } from 'react';
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    BarElement,
    Title,
    Tooltip,
    Legend,
    LogarithmicScale
} from 'chart.js';
import { Line } from 'react-chartjs-2';
// import 'chartjs-plugin-dragdata';


ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    PointElement,
    LineElement,
    LogarithmicScale,
    Title,
    Tooltip,
    Legend
);

const MultiAxisComponent = (props) => {
    const { labels, datasets, options } = props;

    const [chartJSLoaded, setChartJSLoaded] = useState(false);

    useEffect(() => {
        if (typeof window !== 'undefined') {
            import('chart.js/auto').then((Chart) => {
                import('chartjs-plugin-zoom').then((Zoom) => {
                    Chart.Chart.register(Zoom.default);
                    setChartJSLoaded(true);
                });
            });
        }
    }, []);

    useEffect(() => {
    }, [labels, datasets, options]);

    const data = {
        labels: labels,
        datasets: datasets
    }

    return (<>
        {chartJSLoaded && <Line options={options} data={data} />}
    </>
    );
};

export default MultiAxisComponent;