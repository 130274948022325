import SafeHavenScorecard from '@/modules/SafeHavenScorecard';
import ScorecardHome from '@/modules/ScorecardHome';
import AuthWrapper from '@/services/AuthWrapper';

const Home = () => {
  return (
    <>
      {/* <SafeHavenScorecard /> */}
      <AuthWrapper>
        <ScorecardHome />
      </AuthWrapper>
    </>
  )
}

export default Home;