import React from 'react';
import { Bar } from 'react-chartjs-2';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  BarElement,
  Title,
  Tooltip,
  Legend,
  LogarithmicScale,
  LineController
} from 'chart.js';
ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  PointElement,
  LineElement,
  LogarithmicScale,
  Title,
  Tooltip,
  Legend,
  LineController
);

const BarChart4 = ({ min, max, average }) => {
  // Check if all values (min, max, and average) are zero for the first regime
  const isFirstRegimeAllZero = min[0] === 0 && max[0] === 0 && average[0] === 0;
  const data = {
    labels: Array.from({ length: min.length }, (_, i) => `Regime ${i + 1}`),
    datasets: [
      {
        label: 'Min-Max',
        data: min.map((value, index) => [value, max[index]]),
        backgroundColor: 'rgba(53, 162, 235, 0.4)',
        borderColor: 'rgba(53, 162, 235, 1)',
        borderWidth: 1,
      },

      {
        type: 'line',
        label: 'Average',
        data: average.map((value, index) => (index === 0 && isFirstRegimeAllZero) ? null : value),
        fill: false,
        backgroundColor: 'rgba(0,0,0,0.4)',
        borderColor: 'rgba(0,0,0,1)',
        pointRadius: 8,
        pointHoverRadius: 10,
        showLine: false,
        borderWidth: 2

      },
      {
        type: 'line',
        // label: 'Average Cross',
        data: average.map((value, index) => (index === 0 && isFirstRegimeAllZero) ? null : value),
        fill: false,
        backgroundColor: 'rgba(0,0,0,0.4)',
        borderColor: 'rgba(0,0,0,1)',
        pointRadius: 8,
        pointHoverRadius: 10,
        showLine: false,
        pointStyle: 'crossRot',
        borderWidth: 2
      },
    ]
    // datasets: []
  };

  const options = {
    animation: {
      duration: 0
    },
    plugins: {
      legend: {
        display: false,
      },
      tooltip: {
        filter: function (tooltipItem) {
          return tooltipItem.dataset.label === "Min-Max" || tooltipItem.dataset.label === 'Average'; // Only show tooltip for the first dataset
        }
      }
    },
    scales: {
      x: {
        ticks: {
          display: false, // This will hide the x-axis labels
        },
      },
      yAxes: [
        {
          ticks: {
            beginAtZero: true,
          },
        },
      ],
      y: {
        suggestedMin: -60,
        suggestedMax: 60,
      }
    },
  };


  return <Bar data={data} options={options} />;
};

export default BarChart4;
