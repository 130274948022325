import React from 'react'
import Image from 'next/image';

import loaderGif from '../../public/Logo_Animation2.gif';

const Loader = ({ loading, ...props }) => {
    return (
        <>
            {loading &&
            <div className="w-full h-full fixed top-0 left-0 z-50 flex justify-center items-center">
            <div className="absolute left-0 top-0 right-0 bottom-0 bg-white opacity-80"></div>
            <div className="text-center relative">
                <Image
                    className="inline"
                    width={'100'}
                    height={'100'}
                    src={loaderGif.src} alt='Loading...'
                />
            </div>
        </div>}
        </>
    )
}

export default Loader