import React, { useState } from 'react'
import SafeHavenScorecard from './SafeHavenScorecard';
import MultiAsset from './multiAsset/MultiAsset';

const ScorecardHome = () => {
    const [multiAssetToggle, setMultiAssetToggle] = useState('Single Asset');

    const handleMultiAssetToggle = (e) => {
        // console.log(e.target.value);
        console.log('hit');
        // setMultiAssetToggle(prevValue => !prevValue);
        setMultiAssetToggle(prevValue => e.target.name);
    }

    return (
        <>
            {multiAssetToggle == 'Single Asset' && <SafeHavenScorecard
                multiAssetToggle={multiAssetToggle}
                handleMultiAssetToggle={handleMultiAssetToggle}
            />}
            {multiAssetToggle == 'Different Assets' && <MultiAsset
                multiAssetToggle={multiAssetToggle}
                handleMultiAssetToggle={handleMultiAssetToggle}
            />}
        </>
    )
}

export default ScorecardHome