// ToggleSwitch.js
import React from 'react'

const ToggleSwitch = ({ toggleData, value, onChange, ...props }) => {
    return (
        <>
            <div className="flex overflow-hidden border border-[#69a4ce] rounded-md">
                {toggleData.map((data, index) => (
                    <label htmlFor={data.id} className="cursor-pointer" key={index}>
                        <input
                            type="radio"
                            name={data.name}
                            id={data.id}
                            className="sr-only peer"
                            checked={value === data.name ? true : false}
                            onChange={onChange}
                        />
                        <span className={`relative inline-flex items-center h-full py-1.5 px-3 text-sm peer-checked:bg-[#69a4ce] peer-checked:text-white`}>
                            {/* <span className={`before:w-2 before:h-2 before:bg-green-500 before:rounded-full before:absolute before:top-[14px] before:left-3`}>{data.label}</span> */}
                            {data.label}
                        </span>
                    </label>
                ))}
            </div>
        </>
    )
}

export default ToggleSwitch;