const generateValuesSPX = (start, end, SPXReturn) => { // 1973, 2022
    let val = 1;
    let year = start - 1; // 173-1 = 1972
    let spx;
    let values1 = [];
    values1.push(val);

    year = year + 1 // 1973

    for (let i = 0; i <= (end - start); i++) { //2022-1973 = 49
        let returns = SPXReturn.filter(function (v, i) {
            return v[0] == year;
        });
        if (returns.length > 0) {
            spx = returns[0][1];
        }
        else {
            spx = 0;
        }
        val = val * (1 + spx);

        values1.push(val.toFixed(5));
        year = year + 1;
    }
    return values1;
}

const OtherDropdown = [
    {
        label: "Systematic",
        options: [
            { value: "acwi minvol", text: "ACWI MinVol" },
            { value: "sp riskparity", text: "Risk Parity" },
            { value: "sp rskctrl 10%", text: "Risk Control" }
        ]
    }, {
        label: "Risk Mitigation",
        options: [
            { value: "gold", text: "Gold" },
            { value: "comm", text: "Commodities" },
            // { value: "US TSY (LT)", text: "US TSY (LT)" },
            { value: "tips", text: "US TIPS" },
            { value: "hycorp", text: "US Corp HY" },
            { value: "cta", text: "CTA" },
            // { value: "longvol", text: "LongVol" },
            { value: "longvol", text: "LongVol HF" },
            { value: "reits", text: "REITs" },
            { value: "pvt realestate", text: "Pvt Real Estate" },
            { value: "munis", text: "MUNIS" },
        ]
    },
    {
        label: "ALT Hedge Funds HF",
        options: [
            { value: "hedgefunds", text: "Hedge Funds" },
            { value: "eventdriven hf", text: "Event Driven" },
            { value: "relvalue hf", text: "Relative Value" },
            { value: "relvol hf", text: "Relative Vol" },
            { value: "shortvol hf", text: "Short Vol" },
            { value: "struccredit hf", text: "Structured Credit" },
            { value: "arbitrage hf", text: "Arbitrage" },
            { value: "eqtmktneutral hf", text: "Equity Mkt Neutral" },
            { value: "macro hf", text: "Macro" },
            { value: "fundoffunds", text: "Fund Of Funds" },
        ]
    },
    {
        label: "Insurance",
        options: [
            // { value: "eurth", text: "EurekaTH" },
            { value: "eurth", text: "TailHedge HF" },
            { value: "pput", text: "PPUT" },
            { value: "uvxy", text: "UVXY" },
            // { value: "BSPP", text: "BSPP" },
            { value: "cash", text: "Cash" },
        ]
    },
    {
        label: "Cartoons",
        options: [
            { value: "Store-of-Value [CPI+2%]", text: "Store-of-Value [CPI+2%]" },
            // { value: "Store-of-Value [5%]", text: "Store-of-Value [5%]" },
            { value: "Store-of-Value [7%]", text: "Store-of-Value [7%]" },
            // { value: "Alpha [20,10,5,5]", text: "Alpha [20,10,5,5]" },
            // { value: "Alpha [15,7.5,2.5,2.5]", text: "Alpha [15,7.5,2.5,2.5]" },
            { value: "Alpha [20,7.5,5,5]", text: "Alpha [20,7.5,5,5]" },
            { value: "Alpha [15,10,2.5,2.5]", text: "Alpha [15,10,2.5,2.5]" },
            { value: "INSUR[10-to-1]", text: "INSUR[10-to-1]" },
            { value: "INSUR[12-to-1]", text: "INSUR[12-to-1]" },
            { value: "INSUR[15-to-1]", text: "INSUR[15-to-1]" }
        ]
    }
]

const percentileOptions0To100 = [
    {
        label: "Percentile",
        options: [
            { value: 10, text: "10 %" },
            { value: 20, text: "20 %" },
            { value: 30, text: "30 %" },
            { value: 40, text: "40 %" },
            { value: 50, text: "50 %" },
            { value: 60, text: "60 %" },
            { value: 70, text: "70 %" },
            { value: 80, text: "80 %" },
            { value: 90, text: "90 %" },
            { value: 100, text: "100 %" }
        ]
    }
]

const percentileOptions5To95 = [
    {
        label: "Percentile",
        options: [
            { value: 5, text: "5 %" },
            { value: 15, text: "15 %" },
            { value: 25, text: "25 %" },
            { value: 35, text: "35 %" },
            { value: 45, text: "45 %" },
            { value: 55, text: "55 %" },
            { value: 65, text: "65 %" },
            { value: 75, text: "75 %" },
            { value: 85, text: "85 %" },
            { value: 95, text: "95 %" }
        ]
    }
]

const generatePlusValuesNonCartoonsWealth = (fromYear, toYear, sliderValue, secondAsset, firstAsset) => {

    let val = 1;
    let year = fromYear - 1;
    let firstAssetValue;
    const valuesGen = [];
    valuesGen.push(val);

    year = year + 1;

    let alloc = sliderValue;

    for (let i = 0; i <= (toYear - fromYear); i++) {
        let returns = firstAsset.filter(function (v, i) {
            return v[0] == year;
        });

        if (returns.length > 0) {
            firstAssetValue = returns[0][1];
        }
        else {
            firstAssetValue = 0;
        }

        let secondAssetValue = 0;
        let values = secondAsset.filter(function (v, i) {
            return v[0] == year;
        });

        if (values.length > 0) {
            secondAssetValue = values[0][1];
        }
        else {
            secondAssetValue = 0;
        }

        // 0 w->

        let plusValue = secondAssetValue; //asset 2 returns

        let combined = (100 - alloc) / 100 * firstAssetValue + alloc / 100 * plusValue //asset 1 spx ?wavg

        val = val * (1 + combined);

        valuesGen.push(val.toFixed(2));
        year = year + 1;
    }
    return valuesGen;
}

//for generating mix of retruns and give output as return !!!! retrun Not wealth 
const generatePlusValuesNonCartoonsReturns = (fromYear, toYear, sliderValue, secondAsset, firstAsset) => {

    let val = 1;
    let year = fromYear - 1;
    let firstAssetValue;
    const valuesGen = [];
    const yearlyReturns = [];
    valuesGen.push(val);

    year = year + 1;

    let alloc = sliderValue;

    for (let i = 0; i <= (toYear - fromYear); i++) {
        let returns = firstAsset.filter(function (v, i) {
            return v[0] == year;
        });

        if (returns.length > 0) {
            firstAssetValue = returns[0][1];
        }
        else {
            firstAssetValue = 0;
        }

        let secondAssetValue = 0;
        let values = secondAsset.filter(function (v, i) {
            return v[0] == year;
        });

        if (values.length > 0) {
            secondAssetValue = values[0][1];
        }
        else {
            secondAssetValue = 0;
        }

        // 0 w->

        let plusValue = secondAssetValue; //asset 2 returns

        let combined = (100 - alloc) / 100 * firstAssetValue + alloc / 100 * plusValue //asset 1 spx ?wavg

        // val = val * (1 + combined);
        valuesGen.push(combined);
        yearlyReturns.push([year, combined])
        year = year + 1;
    }

    // return valuesGen;
    return yearlyReturns
}

const getReturnFromWealthByYear = (fromYear, toYear, wealthArray) => {
    const returnsArr = [];
    wealthArray.forEach((currentYearWealth, idx) => {
        let year = fromYear;
        if (idx < wealthArray.length - 1) {
            const yearlyReturns = parseFloat(wealthArray[idx + 1]) / parseFloat(currentYearWealth) - 1;
            returnsArr.push([year, parseFloat(yearlyReturns)]);
            fromYear++;
        }
    });
    return returnsArr;
}

const isObjectEmpty = (obj) => {
    return Object.keys(obj).length === 0;
}

// chart 5 functions 


const calAssetsMix = (yearMatrix, yearsList, mixReturns, percentileValue) => {
    // if (yearMatrix.length == 0 && yearMatrixData.length != 0) {
    //     yearMatrix = yearMatrixData;
    // }
    let binMatrix = createBinMatrix(yearMatrix, yearsList);
    let assetBin = filterRegimeYears(yearsList, mixReturns);
    let newAssetYearMatrix = fillNewMatrix(binMatrix, assetBin);

    // not 
    // const resultAssetArray = new Array(assetBin.length).fill([]).map(() => []);
    // // Iterate through yearsMatrix and categorize years into the resultArray
    // for (let i = 0; i < newAssetYearMatrix.length; i++) {
    //   for (let j = 0; j < newAssetYearMatrix[i].length; j++) {
    //     assetBin.forEach((regime, index) => {
    //       if (regime.includes(newAssetYearMatrix[i][j])) {
    //         resultAssetArray[index].push(newAssetYearMatrix[i][j]);
    //       }
    //     });
    //   }
    // }
    // let avgAssetRegime = [];
    // let maxAssetValRegime = [];
    // let minAssetValRegime = [];
    // let resultArrayReturns = calReturn(resultAssetArray, mix);

    // for (let i = 0; i < resultArrayReturns.length; i++) {
    //   calculatePercentilePaths
    //   let getAvg = avgCalculate(resultArrayReturns[i]);
    //   avgAssetRegime.push(getAvg);
    //   let getMax = maxCalc(resultArrayReturns[i]);
    //   maxAssetValRegime.push(getMax);
    //   let getMin = minCalc(resultArrayReturns[i]);
    //   minAssetValRegime.push(getMin);
    // }

    // let amAsset = avgCalc(avgAssetRegime);
    // setMixAM(amAsset);

    // for mix gm calculation 

    let pnlValues = generatePNLValues(mixReturns)
    // fn calculateWealthMatrixForOneTwenty returns [wealthMatrix, profitLossMatrix]
    const matrixData = calculateWealthMatrixForOneTwenty(newAssetYearMatrix, pnlValues, 'mix');
    let wealthMatrixData = matrixData[0];
    let profitLossData = matrixData[1];
    let percentileValuesForLastCol = calculatePercentilePaths(wealthMatrixData, 25, 1000, percentileValue);
    // calMixGM(percentileValuesForLastCol, selectedValueFlip, wealthMatrixData, profitLossData);
    let cagrData = cagrCal(percentileValuesForLastCol, wealthMatrixData, 25);
    return cagrData[0]
}

function createBinMatrix(yearsMatrix, regimeMatrix) {
    const newMatrix = Array.from({ length: yearsMatrix.length }, () => Array(yearsMatrix[0].length).fill(0));
    for (let i = 0; i < yearsMatrix.length; i++) {
        for (let j = 0; j < yearsMatrix[0].length; j++) {
            const currentYear = yearsMatrix[i][j];

            for (let regimeIndex = 0; regimeIndex < regimeMatrix.length; regimeIndex++) {
                if (regimeMatrix[regimeIndex].includes(currentYear)) {
                    newMatrix[i][j] = regimeIndex;
                    break;
                }
            }
        }
    }


    return newMatrix;
}

function filterRegimeYears(regimeMatrix, yearAssets) {
    return regimeMatrix.map(regimeYears =>
        regimeYears.filter(year => {
            for (const asset of yearAssets) {
                if (asset[0] === year) {
                    return true;
                }
            }
            return false;
        })
    );
}

function fillNewMatrix(binMatrix, assetbinMatrix) {
    return binMatrix.map(row =>
        row.map(binIndex => {
            const availableYears = assetbinMatrix[binIndex];
            return availableYears[Math.floor(Math.random() * availableYears.length)];
        })
    );
}

const generatePNLValues = (assetReturns) => {
    let initialSPXReturn = [...assetReturns].sort((a, b) => a[0] - b[0]);
    let profitLossVal = new Map();

    for (let i = 0; i < initialSPXReturn.length; i++) {
        profitLossVal.set(initialSPXReturn[i][0], initialSPXReturn[i][1]);
    }

    return profitLossVal
}

const calculateWealthMatrixForOneTwenty = (matrix, profitLossVal, type) => {
    const wealthMatrix = [];
    const profitLossMatrix = [];

    for (let row = 0; row < matrix.length; row++) {
        const newRow = [];
        const profitLossRow = [];
        let wealth = 1; // Initial wealth at the start of calculation for each row

        for (let col = 0; col < matrix[row].length; col++) {
            let faceNo = matrix[row][col];
            let faceNoProfitLossVal;
            if (profitLossVal.has(faceNo)) {
                faceNoProfitLossVal = profitLossVal.get(faceNo);
            }
            let profitLossPercentage = faceNoProfitLossVal; // faceNo is 1-based index, array index is 0-based
            profitLossRow.push(profitLossPercentage);
            let profitLossPercentageWithoutSign = Math.abs(profitLossPercentage);
            // Calculate wealth for each column in the row based on the profit/loss percentage
            let profitLossCalc = wealth;
            profitLossCalc *= profitLossPercentageWithoutSign;
            if (profitLossPercentage < 0) {
                wealth -= profitLossCalc;
            }
            else {
                wealth += profitLossCalc;
            }
            newRow.push(wealth);
        }

        wealthMatrix.push(newRow);
        profitLossMatrix.push(profitLossRow);
    }
    return [wealthMatrix, profitLossMatrix];
}

const cagrCal = (percentileValuesForLastCol, wealthMatrixData, selectedValueFlip) => {
    const resultArray = [];
    if (percentileValuesForLastCol !== undefined) {
        percentileValuesForLastCol.forEach(rowNo => {
            if (wealthMatrixData[rowNo]) {
                const rowVal = wealthMatrixData[rowNo];
                const endColValue = rowVal[rowVal.length - 1]; // Fetching the last element of each row
                const modifiedValue = Math.pow(endColValue, 1 / selectedValueFlip) - 1;
                const newModifiedNo = (modifiedValue * 100).toFixed(2);
                resultArray.push(newModifiedNo);
            }
        });
    }
    return resultArray;
}

const calculatePercentilePaths = (wealthMatrix, selectedValueFlip, selectedValuePath, percentileValue = 50) => {
    if (wealthMatrix.length > 1) {
        const lastColumn = wealthMatrix.map(row => row[selectedValueFlip - 1]); // Get the last column (end wealth)

        // Create an array of objects with row numbers and their corresponding end wealth
        const rowsWithWealth = lastColumn.map((wealth, index) => ({ wealth, row: index }));


        // Sort the rows based on the end wealth
        rowsWithWealth.sort((a, b) => a.wealth - b.wealth);

        // Calculate percentile paths based on the sorted end wealth
        const percentileValues = [];
        //   const percentiles = [50];
        const percentiles = [parseFloat(percentileValue)];
        // percentileValues.push(rowsWithWealth[0].row);

        for (let i = 0; i < percentiles.length; i++) {
            const index = Math.floor((percentiles[i] / 100) * selectedValuePath);
            if (!isEmpty(index)) {
                if (index >= 1 && index <= rowsWithWealth.length) {
                    const percentilePath = rowsWithWealth[index - 1].row;
                    percentileValues.push(percentilePath);
                }
            }
        }

        // for (let i = 0; i < percentiles.length; i++) {
        //     const index = Math.floor((percentiles[i] / 100) * selectedValuePath);

        //     // Ensure index is within bounds
        //     if (index >= 1 && index <= rowsWithWealth.length) {
        //         const percentilePath = rowsWithWealth[index - 1].row;
        //         percentileValues.push(percentilePath);
        //     } else {
        //         console.log(`Invalid index: ${index}`);
        //     }
        // }

        // percentileValues.push(rowsWithWealth[rowsWithWealth.length - 1].row);
        return percentileValues;
    }
};

//new mix logic 
const generateMix = (baseReturns, otherAssetReturns, alloc) => {
    let mixMatrix = [];
    for (let i = 0; i < baseReturns.length; i++) {
        let temp = [];
        for (let j = 0; j < baseReturns[i].length; j++) {
            temp.push((100 - alloc) / 100 * baseReturns[i][j] + alloc / 100 * otherAssetReturns[i][j]);
        }
        mixMatrix.push(temp);
    }

    return mixMatrix
}

//chart5 logic 
const gernerate0toMixMatrixReturns = (baseAssetMatrixRetruns, otherAssetMatrixRetruns) => {
    let mixCombos = {}
    const allocRangeConfig = {
        fromAlloc: 0,
        toAlloc: 100
    }

    for (let i = 1; i <= allocRangeConfig.toAlloc; i++) {
        let mixReturns = generateMix(baseAssetMatrixRetruns, otherAssetMatrixRetruns, i)
        mixCombos[i] = mixReturns
    }

    return mixCombos
}

const generateMixReturnsWealth = (mixMatrixRetruns) => {
    let wealthMatrix = []
    for (let i = 0; i < mixMatrixRetruns.length; i++) {
        let initialWealth = 1
        let temp = [];
        for (let j = 0; j < mixMatrixRetruns[i].length; j++) {
            // temp.push(a[i][j] + b[i][j]);
            initialWealth = initialWealth * (1 + mixMatrixRetruns[i][j]);
            temp.push(initialWealth);
        }
        wealthMatrix.push(temp);
    }

    return wealthMatrix
}


const generateCagrAllocationPoints = (matrixReturns, percentileValue) => {
    const wealthMatrix = generateMixReturnsWealth(matrixReturns);
    let percentileValuesForLastCol = calculatePercentilePaths(wealthMatrix, 25, 1000, percentileValue);
    let cagrData = cagrCal(percentileValuesForLastCol, wealthMatrix, 25);
    return cagrData[0]
}

const binMapping = (binMatrix, mixMatrix) => {
    let binCatgory = {};
    for (let i = 0; i < binMatrix.length; i++) {
        for (let j = 0; j < binMatrix[i].length; j++) {
            let category = binMatrix[i][j];
            if (!binCatgory[category]) {
                binCatgory[category] = [];
            }
            binCatgory[category].push(mixMatrix[i][j]);
        }
    }

    return binCatgory
}

const calAmGm = (percentileValuesForLastCol, selectedValueFlip, wealthMatrixData, profitLossData) => {
    // let endWealths = lastColumnValue(percentileValuesForLastCol, wealthMatrixData);
    let cagrData = cagrCal(percentileValuesForLastCol, wealthMatrixData, selectedValueFlip);
    const matrixWithOnesColumn = wealthMatrixData.map(row => [1, ...row]);
    let ddData = ddDataCal(percentileValuesForLastCol, matrixWithOnesColumn);
    let amData = amDataCal(percentileValuesForLastCol, profitLossData);

    let amDataAvg = avgCalc(amData);
    let gmCal = cagrData[5];
    return { am: amDataAvg, gm: gmCal }
}

const generateCartoonsReturns = (fromYear, toYear, type, binValues = [], CPI, SPXReturns, regimeBins) => {

    // let val = 1;
    let year = fromYear;
    let spx;
    const valuesGen = [];
    // valuesGen.push([year, val]);

    // year = year + 1;

    // let checkBin1 = []
    // let checkBin2 = []
    // let checkBin3 = []
    // let checkBin4 = []

    let bin1 = binValues[0]; //<-15
    let bin2 = binValues[1]; //-15 to 0
    let bin3 = binValues[2]; //0 to 15
    let bin4 = binValues[3]; //>15

    let SPXarr = []

    for (let i = 0; i <= (toYear - fromYear); i++) {
        let returns = SPXReturns.filter(function (v, i) {
            return v[0] == year;
        });
        if (returns.length > 0) {
            spx = returns[0][1];
        }
        else {
            spx = 0;
        }
        SPXarr.push(spx)
        // let selectedBin = bin4;
        // if (spx.toFixed(2) <= -0.15) {
        //     selectedBin = bin1;
        // } else if ((spx < 0) && (spx >= -0.15)) {
        //     selectedBin = bin2;
        // } else if ((spx < 0.15) && (spx >= 0)) {
        //     selectedBin = bin3;
        // }

        let selectedBin = bin4

        if (regimeBins[0][0] <= spx * 100 && spx * 100 < regimeBins[0][1]) {
            // checkBin1.push(spx)
            selectedBin = bin1
        } else if (regimeBins[1][0] <= spx * 100 && spx * 100 < regimeBins[1][1]) {
            // checkBin2.push(spx)
            selectedBin = bin2
        } else if (regimeBins[2][0] <= spx * 100 && spx * 100 < regimeBins[2][1]) {
            // checkBin3.push(spx)
            selectedBin = bin3
        } else if (regimeBins[3][0] <= spx * 100 && spx * 100 <= regimeBins[3][1]) {
            // checkBin4.push(spx)
            selectedBin = bin4
        }

        let cpivalue = 0;
        if (type == "Store-of-Value CPI") {
            let cpiValues = CPI.filter(function (v, i) {
                return v[0] == year;
            });
            if (cpiValues.length > 0) {
                cpivalue = cpiValues[0][1];
            }
            else {
                cpivalue = 0;
            }
        }

        let plusValue = selectedBin / 100 + cpivalue;

        // let combined = 1 * spx + 1 * plusValue;
        let combined = (100 - 100) / 100 * spx + 100 / 100 * plusValue;
        // let combined = spx + plusValue;

        // val = val * (1 + combined);

        valuesGen.push([year, combined]);
        year = year + 1;
    }
    return valuesGen;
}

const isEmpty = (value) => {
    if (value === null || value === undefined || value === NaN) {
        return true; // Handles null and undefined
    } else if (Array.isArray(value)) {
        return value.length === 0; // Handles empty arrays
    } else if (typeof value === 'object') {
        return Object.keys(value).length === 0; // Handles empty objects
    } else if (typeof value === 'number' && isNaN(value)) {
        return true; // Handles NaN
    } else {
        return false; // Non-empty value
    }
}

module.exports = {
    generateValuesSPX,
    OtherDropdown,
    percentileOptions0To100,
    percentileOptions5To95,
    generatePlusValuesNonCartoonsWealth,
    generatePlusValuesNonCartoonsReturns,
    getReturnFromWealthByYear,
    isObjectEmpty,
    calAssetsMix,
    generateMix,
    gernerate0toMixMatrixReturns,
    generateCagrAllocationPoints,
    binMapping,
    calAmGm,
    generateMixReturnsWealth,
    generateCartoonsReturns,
    isEmpty
}

