import React, { useEffect, useState } from 'react'

import MultiAxisComponent from '@/components/MultiAxisComponent3'
import { gernerate0toMixMatrixReturns, generateCagrAllocationPoints, gernerate0toMixMatrixReturnsForInsurance } from '@unianlx-support/ua-core/src/modules/cea-plot/safeHavenScoreCardHelper'

const Chart5 = React.memo(({ fromYear, toYear, firstAssetValues, secondAssetValues, yearsMatrix, yearsList, percentile1Dropdown, percentile2Dropdown, assetType,
    //new  logic props 
    baseAssetMatrixRetruns,
    otherAssetMatrixRetruns,
    reshuffle,
    normal,
    otherAssetMatrixReshuffleReturns,
    nocrash,
    baseAssetMatrixReturnsNoCrash,
    otherAssetMatrixReturnsNoCrash,
    ...props }) => {

    const [mixCombosObj, setMixCombosObj] = useState({});
    const [percentile1Data, setPercentile1Data] = useState([]);
    const [percentile2Data, setPercentile2Data] = useState([]);

    useEffect(() => {
        if (normal && baseAssetMatrixRetruns.length > 1 && otherAssetMatrixRetruns.length > 1) {
            assetType ?
                setMixCombosObj(prevValue => ({ ...gernerate0toMixMatrixReturnsForInsurance(baseAssetMatrixRetruns, otherAssetMatrixRetruns) })) :
                setMixCombosObj(prevValue => ({ ...gernerate0toMixMatrixReturns(baseAssetMatrixRetruns, otherAssetMatrixRetruns) }))
        }
        if (reshuffle && baseAssetMatrixRetruns.length > 1 && otherAssetMatrixReshuffleReturns.length > 1) {
            assetType ?
                setMixCombosObj(prevValue => ({ ...gernerate0toMixMatrixReturnsForInsurance(baseAssetMatrixRetruns, otherAssetMatrixReshuffleReturns) })) :
                setMixCombosObj(prevValue => ({ ...gernerate0toMixMatrixReturns(baseAssetMatrixRetruns, otherAssetMatrixReshuffleReturns) }))
        }
        if (nocrash && baseAssetMatrixReturnsNoCrash.length > 1 && otherAssetMatrixReturnsNoCrash.length > 1) {
            assetType ?
                setMixCombosObj(prevValue => ({ ...gernerate0toMixMatrixReturnsForInsurance(baseAssetMatrixReturnsNoCrash, otherAssetMatrixReturnsNoCrash) })) :
                setMixCombosObj(prevValue => ({ ...gernerate0toMixMatrixReturns(baseAssetMatrixReturnsNoCrash, otherAssetMatrixReturnsNoCrash) }))
        }
    }, [fromYear, toYear, firstAssetValues, secondAssetValues, yearsMatrix, yearsList, percentile1Dropdown, percentile2Dropdown, otherAssetMatrixRetruns, baseAssetMatrixRetruns, reshuffle, normal, otherAssetMatrixReshuffleReturns, baseAssetMatrixReturnsNoCrash, otherAssetMatrixReturnsNoCrash])

    useEffect(() => {
        let percentile1 = {}
        let percentile2 = {}

        for (let idx in mixCombosObj) {
            const { cagr: cagr1 } = generateCagrAllocationPoints(mixCombosObj[idx], percentile1Dropdown, 25, 1000)
            percentile1[idx] = cagr1
            const { cagr: cagr2 } = generateCagrAllocationPoints(mixCombosObj[idx], percentile2Dropdown, 25, 1000)
            percentile2[idx] = cagr2
        }

        setPercentile1Data(prevValue => Object.entries(percentile1).map(([key, value]) => ({
            x: parseFloat(key),
            y: parseFloat(value)
        })));

        setPercentile2Data(prevValue => Object.entries(percentile2).map(([key, value]) => ({
            x: parseFloat(key),
            y: parseFloat(value)
        })));

    }, [mixCombosObj])

    useEffect(() => {
    }, [percentile1Data, percentile2Data])

    // let labels = [
    //     ...percentile1Data.map((_, idx) => {
    //         return `${idx + 1} %`
    //     })
    // ]
    let labels = [
        ...percentile2Data.sort((a, b) => { return a.x - b.x; }).map(item => parseFloat(item.x))
    ]


    let datasets = [
        {

            "label": `${percentile1Dropdown}th Percentile`,
            // "data": [...percentile1Data.map((item, idx) => parseFloat(item.y))],
            "data": [...percentile1Data.sort((a, b) => { return a.x - b.x; }).map(item => parseFloat(item.y))],
            "borderColor": "rgba(105, 105, 105, 0.5)",
            "backgroundColor": "rgba(105, 105, 105, 0.5)",
            pointRadius: 0,
            pointHitRadius: 0,
            pointHoverRadius: 0,

        },
        {
            "label": `${percentile2Dropdown}th Percentile`,
            "data": [...percentile2Data.map((item, idx) => parseFloat(item.y))],
            "borderColor": "rgba(105, 105, 105, 0.5)",
            "backgroundColor": "rgba(105, 105, 105, 0.5)",
            pointRadius: 0,
            pointHitRadius: 0,
            pointHoverRadius: 0,
            borderDash: [2, 5]
        }
    ]

    const options = {
        "animation": {
            "duration": 0
        },
        "responsive": true,
        "interaction": {
            "mode": "index",
            "intersect": false
        },
        "plugins": {
            "legend": {
                "position": "top",
                filter: function (legendItem, chart) {
                    return
                }
            },
            "title": {
                // "display": true,
                // "text": "98% SPX + 2.0% Insurance Versus SPX"
            },
            "zoom": {
                "zoom": {
                    "wheel": {
                        "enabled": true,
                        "speed": 0.1
                    },
                    "pinch": {
                        "enabled": true
                    },
                    "mode": "x"
                },
                "pan": {
                    "enabled": true,
                    "mode": "x"
                }
            }
        },
        "scales": {
            "x": {
                type: 'linear',
                "title": {
                    "display": true,
                    "text": "Allocation (%)"
                },
                ticks: {
                    min: 0,
                    max: 100,
                    callback: function (value, index, values) {
                        // Specify the values where you want to show a tick
                        const tickValues = [0, 20, 40, 60, 80, 100];
                        if (tickValues.includes(value)) {
                            return value + "%";
                        }
                    }
                    // callback: function (value, index, values) {
                    //     if (Number.isInteger(value)) {
                    //         return value + "%";
                    //     }
                    // }
                },
            },
            "y": {
                "title": {
                    "display": true,
                    "text": "CAGR"
                },
                "grid": {}
            }
        }
    }

    return (
        <>
            <MultiAxisComponent
                labels={labels}
                datasets={datasets}
                options={options}
            />
        </>
    )
})

export default Chart5; 