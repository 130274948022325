import MultiAxisComponent from '@/components/MultiAxisComponent3'
import React, { useEffect, useState } from 'react'

const CEAplot = ({ xAxis, yAxis, isMulti = false, coordinates = [], ...props }) => {

    const initialSlopePoints = [-2.0, -1.5, -1.0, -0.5, 0, 0.5, 1.0, 1.5, 2.0]
    const [slopePoints, setSlopePoints] = useState([]);
    const [abovePoint, setAbovePoint] = useState(0);

    let pointLine = parseFloat(xAxis - yAxis).toFixed(1)
    useEffect(() => {
        setSlopePoints(prevValue => computeSlope(xAxis, yAxis))
        // setAbovePoint(aAxis - )
    }, [xAxis, yAxis])

    useEffect(() => {
        if (isMulti === true) {
            // console.log('*', coordinates)
            setSlopePoints(prevValue => findOverallMinMax(coordinates))
        }
    }, [coordinates])



    const computeSlope = (x, y) => {
        let maxAbsValue = Math.max(2, Math.abs(x), Math.abs(y));
        let rangeRounding = Math.round(maxAbsValue * 2) / 2;

        let stepList = [];
        for (let i = -1 * (rangeRounding) - 1.0; i <= rangeRounding + 1.0; i += 0.5) {
            stepList.push(i);
        }

        let filteredList = stepList.filter(value => Math.abs(value) <= rangeRounding + 1.5);

        return filteredList
    }

    const findOverallMinMax = (data) => {
        let min = Infinity, max = -Infinity;

        data.forEach(item => {
            let x = parseFloat(item.xAxis);
            let y = parseFloat(item.yAxis);

            min = Math.min(min, x, y);
            max = Math.max(max, x, y);
        });

        let maxAbsValue = Math.max(2, max);
        let rangeRounding = Math.round(maxAbsValue * 2) / 2;

        let stepList = [];
        for (let i = -1 * rangeRounding - 1.0; i <= rangeRounding + 1.0; i += 0.5) {
            if (Math.abs(i) <= rangeRounding + 1.5) {
                stepList.push(i);
            }
        }

        // return {
        //     min: min,
        //     max: max,
        //     filteredList: stepList
        // };

        return stepList
    }

    const generateCordinatesDatasets = (cordinates) => {
        let mappedData = []
        mappedData.push({
            "label": `SPX Baseline`,
            // "data": [-2.0, -1.5, -1.0, -0.5, 0, 0.5, 1.0, 1.5, 2.0],
            "data": [...slopePoints],
            "borderColor": "rgba(105, 105, 105, 0.3)",
            "backgroundColor": "rgba(105, 105, 105, 0.3)",
            pointRadius: 0,
            pointHitRadius: 0,
            showTooltip: false
        })
        cordinates.map((point, idx) => {
            mappedData.push(
                {
                    "label": `${point.asset} Cost Net`,
                    "data": [{ x: point.xAxis, y: parseFloat(point.xAxis - point.yAxis) }],
                    "borderColor": "rgba(255, 0, 0, 0.3)",
                    "backgroundColor": "rgba(255, 0, 0, 0.3)",
                    pointRadius: 5,
                },
                {
                    label: '',
                    data: [{ x: 0, y: 0 }, { x: point.xAxis, y: 0 }],
                    borderColor: 'rgba(254, 157, 82, 0.9)',
                    pointRadius: 0,
                    fill: false,
                    type: 'line',
                    borderDash: [5, 3]
                },
                {
                    label: '',
                    data: [{ x: point.xAxis, y: parseFloat(point.xAxis - point.yAxis) }, { x: point.xAxis, y: parseFloat(point.xAxis + point.yAxis) }],
                    borderColor: 'rgba(60, 151, 218, 0.8)',
                    pointRadius: 0,
                    fill: false,
                    type: 'line',
                    borderDash: [2, 1]
                },
                {
                    label: '',
                    data: [{ x: point.xAxis, y: 0 }, { x: point.xAxis, y: parseFloat(point.xAxis - point.yAxis) }],
                    // data: [{ x: xAxis , y: yAxis }, { x: xAxis , y: 0.6 }],
                    borderColor: 'rgba(128, 0, 255, 0.9)',
                    pointRadius: 0,
                    fill: false,
                    type: 'line',
                    // borderDash: [2, 1]
                }
            )
        })

        // console.log(mappedData)
        return mappedData
    }
    // const labels = [-2.0, -1.5, -1.0, -0.5, 0, 0.5, 1.0, 1.5, 2.0]
    const labels = [...slopePoints];

    // let datasets = [
    //     // {
    //     //     "label": `Cost Net`,
    //     //     "data": [{ x: xAxis, y: yAxis }],
    //     //     "borderColor": "rgba(255, 0, 0, 0.3)",
    //     //     "backgroundColor": "rgba(255, 0, 0, 0.3)",
    //     //     pointRadius: 5,
    //     // },
    //     // {
    //     //     "label": `SPX Baseline`,
    //     //     // "data": [-2.0, -1.5, -1.0, -0.5, 0, 0.5, 1.0, 1.5, 2.0],
    //     //     "data": [...slopePoints],
    //     //     "borderColor": "rgba(105, 105, 105, 0.3)",
    //     //     "backgroundColor": "rgba(105, 105, 105, 0.3)",
    //     //     pointRadius: 0,
    //     //     pointHitRadius: 0,
    //     // },
    //     // {
    //     //     label: '',
    //     //     data: [{ x: xAxis, y: yAxis }, { x: xAxis, y: xAxis }],
    //     //     borderColor: 'rgba(255, 160, 0, 0.5)',
    //     //     pointRadius: 0,
    //     //     fill: false,
    //     //     type: 'line',
    //     //     borderDash: [2, 1]
    //     // },
    //     // {
    //     //     label: '',
    //     //     data: [{ x: yAxis, y: yAxis }, { x: xAxis, y: yAxis }],
    //     //     borderColor: 'rgba(0, 0, 255, 0.3)',
    //     //     pointRadius: 0,
    //     //     fill: false,
    //     //     type: 'line',
    //     //     borderDash: [2, 1]

    //     // },


    //     {
    //         "label": `Cost Net`,
    //         "data": [{ x: xAxis, y: parseFloat(xAxis - yAxis) }],
    //         "borderColor": "rgba(255, 0, 0, 0.3)",
    //         "backgroundColor": "rgba(255, 0, 0, 0.3)",
    //         pointRadius: 5,
    //     },
    //     {
    //         "label": `SPX Baseline`,
    //         // "data": [-2.0, -1.5, -1.0, -0.5, 0, 0.5, 1.0, 1.5, 2.0],
    //         "data": [...slopePoints],
    //         "borderColor": "rgba(105, 105, 105, 0.3)",
    //         "backgroundColor": "rgba(105, 105, 105, 0.3)",
    //         pointRadius: 0,
    //         pointHitRadius: 0,
    //     },
    //     {
    //         label: '',
    //         data: [{ x: 0, y: 0 }, { x: xAxis, y: 0 }],
    //         borderColor: 'rgba(254, 157, 82, 0.9)',
    //         pointRadius: 0,
    //         fill: false,
    //         type: 'line',
    //         borderDash: [5, 3]
    //     },
    //     {
    //         label: '',
    //         data: [{ x: xAxis, y: parseFloat(xAxis - yAxis) }, { x: xAxis, y: parseFloat(xAxis + yAxis) }],
    //         borderColor: 'rgba(60, 151, 218, 0.8)',
    //         pointRadius: 0,
    //         fill: false,
    //         type: 'line',
    //         borderDash: [2, 1]
    //     },
    //     {
    //         label: '',
    //         data: [{ x: xAxis, y: 0 }, { x: xAxis, y: parseFloat(xAxis - yAxis) }],
    //         // data: [{ x: xAxis , y: yAxis }, { x: xAxis , y: 0.6 }],
    //         borderColor: 'rgba(128, 0, 255, 0.9)',
    //         pointRadius: 0,
    //         fill: false,
    //         type: 'line',
    //         // borderDash: [2, 1]
    //     },
    // ]
    const initialDatasets = [{
        "label": `Cost Net`,
        "data": [{ x: xAxis, y: parseFloat(xAxis - yAxis) }],
        "borderColor": "rgba(255, 0, 0, 0.3)",
        "backgroundColor": "rgba(255, 0, 0, 0.3)",
        pointRadius: 5,
    },
    {
        "label": `SPX Baseline`,
        // "data": [-2.0, -1.5, -1.0, -0.5, 0, 0.5, 1.0, 1.5, 2.0],
        "data": [...slopePoints],
        "borderColor": "rgba(105, 105, 105, 0.3)",
        "backgroundColor": "rgba(105, 105, 105, 0.3)",
        pointRadius: 0,
        pointHitRadius: 0,
    },
    {
        label: '',
        data: [{ x: 0, y: 0 }, { x: xAxis, y: 0 }],
        borderColor: 'rgba(254, 157, 82, 0.9)',
        pointRadius: 0,
        fill: false,
        type: 'line',
        borderDash: [5, 3]
    },
    {
        label: '',
        data: [{ x: xAxis, y: parseFloat(xAxis - yAxis) }, { x: xAxis, y: parseFloat(xAxis + yAxis) }],
        borderColor: 'rgba(60, 151, 218, 0.8)',
        pointRadius: 0,
        fill: false,
        type: 'line',
        borderDash: [2, 1]
    },
    {
        label: '',
        data: [{ x: xAxis, y: 0 }, { x: xAxis, y: parseFloat(xAxis - yAxis) }],
        // data: [{ x: xAxis , y: yAxis }, { x: xAxis , y: 0.6 }],
        borderColor: 'rgba(128, 0, 255, 0.9)',
        pointRadius: 0,
        fill: false,
        type: 'line',
        // borderDash: [2, 1]
    }]
    let datasets = isMulti ? [...generateCordinatesDatasets(coordinates)] : [...initialDatasets]

    const options = {
        "animation": {
            "duration": 0
        },
        "responsive": true,
        "interaction": {
            "mode": "nearest",
            "intersect": false
        },
        "plugins": {
            tooltip: {
                filter: function (tooltipItem) {
                    return tooltipItem.dataset.label !== '';
                },
                callbacks: {
                    // Customize the tooltip label content
                    label: function (tooltipItem) {
                        let label = tooltipItem.dataset.label || '';
                        // console.log("label: ", label);
                        // console.log("*tooltipItem: ", tooltipItem);
                        // console.log("*tooltipItem.dataset: ", tooltipItem.dataset);
                        // console.log("*coordinates: ", coordinates);
                        // console.log("*isMulti: ", isMulti);
                        let data = 0.0;
                        if (isMulti) {
                            for (let i = 0; i < coordinates.length; i++) {
                                // console.log("coordinates[i].asset: ", coordinates[i].asset);
                                // console.log("label: ", label);

                                if (label.includes(coordinates[i].asset)) {
                                    // console.log("hey");
                                    let yAxisVal = parseFloat(coordinates[i].yAxis);
                                    data = - yAxisVal;
                                    label += `: ${data}`;
                                    return label;
                                }
                            }
                        }
                        else if (!isMulti && coordinates.length == 0 && !label.includes("SPX Baseline")) {
                            // console.log("isMulti: ", isMulti);
                            // console.log("#xAxis: ", xAxis);
                            // console.log("#yAxis: ", yAxis);
                            let yAxisVal = parseFloat(yAxis);
                            data = - yAxisVal;
                            label += `: ${data}`;
                            return label;
                        }
                    }
                },
            },
            legend: {
                position: 'top',
                labels: {
                    filter: function (item, chart) {
                        return;
                    }
                }
            },
            "title": {
                // "display": true,
                // "text": "98% SPX + 2.0% Insurance Versus SPX"
            },
            "zoom": {
                "zoom": {
                    "wheel": {
                        "enabled": true,
                        "speed": 0.3
                    },
                    "pinch": {
                        "enabled": true
                    },
                    "mode": "xy"
                },
                "pan": {
                    "enabled": true,
                    "mode": "xy"
                }
            }
        },
        "scales": {
            x: {
                type: 'linear',
                suggestedMin: -2.0,
                suggestedMax: 2.0,
                // min: -2.0,
                // max: 2.0,
                stepSize: 0.5,
                "title": {
                    "display": true,
                    "text": "Arithmetric Cost (%)"
                },
                ticks: {
                    callback: function (value, index, values) {
                        return parseFloat(value).toFixed(1) + '%'; // limits to 2 decimal places
                    }
                },
                grid: {
                    color: (context) => {
                        if (context.tick.label == '0.0%') {
                            return '#878787'
                        } else {
                            return '#e1e1e1'
                        }
                    }
                }
            },
            y: {
                type: 'linear',
                "title": {
                    "display": true,
                    "text": "Geometric Cost (%)"
                },
                suggestedMin: -2.0,
                suggestedMax: 2.0,
                ticks: {
                    callback: function (value, index, values) {
                        return parseFloat(value).toFixed(1) + '%'; // limits to 2 decimal places
                    }
                },
                position: 'right',
                grid: {
                    color: (context) => {
                        if (context.tick.label == '0.0%') {
                            return '#878787'
                        } else {
                            return '#e1e1e1'
                        }
                    }
                }
            }
        }
    }

    return (
        <>
            <div>
                <MultiAxisComponent
                    labels={labels}
                    datasets={datasets}
                    options={options}
                />
            </div>
        </>
    )
}

export default CEAplot