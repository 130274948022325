import React, { useEffect, useRef } from "react";
import { Chart as ChartJS, LinearScale, CategoryScale, Tooltip } from "chart.js";
import { BarWithErrorBarsChart } from "chartjs-chart-error-bars";

const BarCandles = ({ dataset, title = false }) => {


    const { labels, datasets } = dataset;
    const canvasRef = useRef(null);

    useEffect(() => {
        ChartJS.register(BarWithErrorBarsChart, LinearScale, CategoryScale, Tooltip);

        const data = {
            labels: labels,
            datasets: datasets
            // datasets: [{
            //     label: 'Dataset',
            //     data: datasets
            // }],
        }

        const options = {
            layout: {
                padding: {
                    left: 5,
                    right: 14,
                    top: 0,
                    bottom: 0
                },
            },

            // responsive: true,
            // maintainAspectRatio: true,
            animation: {
                duration: 0
            },
            elements: {
                barWithErrorBar: {
                    errorBarColor: "black",
                    errorBarWhiskerSize: 10,
                },
            },
            plugins: {
                legend: {
                    display: false
                },
                
            },
            scales: {
                x: {
                    position: 'bottom',
                    title: {
                        display: true,
                        text: title.x,                        
                    },
                },
            }
        };

        labels.length === 2 ? options.scales.x['ticks'] = {font:{size:14}} : 
        labels.length <= 3 ? options.scales.x['ticks'] = { font: { size: 12 } } :
            labels.length === 4 ? options.scales.x['ticks'] = { font: { size: 8 } } :
                options.scales.x['ticks'] = { font: { size: 6 } }

        // if (title != false) {
        //     options.scales = {}
        //     if (title?.x != undefined) {
        //         options.scales.x = {
        //             position: 'top',
        //             title: {
        //                 display: true,
        //                 text: title.x,
        //             },
        //         }
        //     }
        //     if (title?.y != undefined) {
        //         options.scales.y = {
        //             title: {
        //                 display: false,
        //                 text: title.y
        //             }
        //         }
        //     }
        // }

        const ctx = canvasRef.current.getContext("2d");

        const chart = new BarWithErrorBarsChart(ctx, {
            data: data,
            options: options,
        });

        return () => {
            chart.destroy();
        };
    }, [labels, datasets]);

    return <canvas ref={canvasRef}></canvas>;
};

export default BarCandles;